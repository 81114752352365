// Dependencies
import React from "react"
// Core Files
import { trans } from "core/localization"
// Shared Components
import FormModal from "layout/Modal/FormModal"
import { Div } from "materialui/Styles/styled"
import ProgressBar from "materialui/ProgressBar"

function TableForm(props) {
  const formikRef = React.useRef(null) // Formik Reference
  const [lazyRecord, setLazyRecord] = React.useState({})
  const {
    open,
    action,
    record,
    service,
    onConfirm,
    formOptions,
    isSubmittingObj,
  } = props

  let modalTitle = action === "edit" ? "editItem" : "addItem"

  React.useEffect(() => {
    if (open && formOptions.lazyForm && record.id && !lazyRecord.id) {
      let request = formOptions.request || service.get.bind(service)
      request(record.id).then(({ data }) => {
        setLazyRecord(data)
      })
    }
  }, [formOptions, lazyRecord, record, service, open])

  if (!open) return ""

  const handleOnSubmit = () => {
    if (formikRef.current) {
      formikRef.current.handleSubmit() // this will trigger onSubmit inside the correct (target) form
    }
  }

  const handleOnConfirm = () => {
    onConfirm()
    setLazyRecord({})
  }

  let formContent

  if (formOptions.lazyForm) {
    if (!lazyRecord.id && record.id) {
      formContent = (
        <Div>
          <ProgressBar control="circular" />
        </Div>
      )
    } else {
      formContent = (
        <formOptions.lazyForm
          action={action}
          reference={formikRef}
          onConfirm={handleOnConfirm}
          setIsSubmitting={isSubmittingObj.setIsSubmitting}
        />
      )
    }
  } else {
    formContent = (
      <formOptions.form
        action={action}
        reference={formikRef}
        onConfirm={handleOnConfirm}
        setIsSubmitting={isSubmittingObj.setIsSubmitting}
      />
    )
  }

  return (
    <FormModal
      open={open}
      onClose={handleOnConfirm}
      onSubmit={handleOnSubmit}
      title={trans(modalTitle, trans(formOptions.modalLable))}
      type={isSubmittingObj.isSubmitting ? "waiting" : action}
    >
      {formContent}
    </FormModal>
  )
}

export default TableForm
