// Core Files
import RoleForm from "./RoleForm"
import SetUpRoleForm from "modules/Users/helpers/SetUpRoleForm"
// Shared Components
import RoleService from "modules/Users/services/RoleService"
import DateFormatter from "tables/Formatters/DateFormatter"
import CheckboxFormatter from "tables/Formatters/CheckboxFormatter"
import masterTableActions from "tables/TableComponents/TableActions/MasterActions"

export default {
  title: "roles",
  service: RoleService,
  formOptions: {
    form: RoleForm,
    modalLable: "role",
    formikLable: "role_",
    setUpForm: SetUpRoleForm,
  },
  table: {
    orderBy: "id",
    heading: "rolesList",
    columns: [
      {
        key: "checkbox",
        heading: "CheckBox",
        allowfilter: false,
        formatter: CheckboxFormatter,
      },
      {
        key: "id",
        heading: "#",
        filter: false,
      },
      {
        key: "role",
        heading: "role",
        allowfilter: false,
      },
      {
        filter: false,
        key: "display_name",
        heading: "display_name",
      },
      {
        filter: false,
        key: "description",
        heading: "description",
      },
      {
        filter: false,
        key: "created_at",
        heading: "created_at",
        formatter: DateFormatter,
      },
      {
        filter: false,
        key: "updated_at",
        heading: "updated_at",
        formatter: DateFormatter,
      },
      masterTableActions(["delete", "edit"]),
    ],
  },
}
