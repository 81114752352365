// Dependencies
import * as Yup from "yup"

export default class SetUpUserForm {
  constructor(record) {
    this.initialValues = {
      id: record.id,
      data: record.data || "",
      action: record.action || "",
      status: record.status || "",
      target_table: record.target_table || "",
    }

    this.validationSchema = Yup.object({
      data: Yup.string().required("Required"),
      action: Yup.string().required("Required"),
      status: Yup.string().required("Required"),
      target_table: Yup.string().required("Required"),
    })
  }

  getInitialValues() {
    return this.initialValues
  }
  getValidationSchema() {
    return this.validationSchema
  }
}
