import localization from "core/localization/index"

localization.extend("en", {
  appName: "Imam Company",
  add: "Add",
  save: "save",
  edit: "Edit",
  remove: "Remove",
  addItem: "Add new %s",
  editItem: "Edit %s",
  removeItem: "Remove %s",
  change: "Change %s",
  direction: "Direction",
  themeMode: "Theme Mode",
  notifications: "notifications",
  logout: "Logout",
  successfully: "successfully",
  refresh: "Refresh",
  deleteText: "Be carefull! you will delete [%s]",
  confirmation: "Are you sure?",
  notes: "Notes",
  gender: "Gender",
  country: "Country",
  city: "City",
  birthDate: "Birth Date",
  "New User": "New-User",
  loading: "Loading",
  search: "Search%s",
  selected: "Selected",
  id: "#",
  name: "Name",
  email: "Email",
  email_: "Email Address",
  actions: "Actions",
  password: "Password",
  c_password: "Confirm Password",
  information: "information",
  image: "Image",
  picture: "Picture",
  roles: "Roles",
  display_name: "Disaply Name",
  description: "Description",
  updated_at: "Updated At",
  created_at: "Created At",
  recordAdded: "%s added successfully",
  recordUpdated: "%s updated successfully",
  active: "Active",
  notactive: "Not Active",
  arabic_name: "Arabic Name",
  fax: "Fax",
  phone: "Phone",
  phone2: "Phone 2",
  contact_info: "Contact Information",
  personal_info: "Personal Information",
  others_info: "Others Information",
  code: "Code",
  details: "Details",
  active_not_active: "Active/Not Active",
  not_active: "Not Active",
  status: "Status",
  alternate_name: "Alternate Name",
  import_data: "Import data",
  import_images: "Import Images",
  barcode: "Barcode",
  price: "Price",
  weight: "Weight",
  new: "New",
  not_new: "Not New",
  error: "Error",
  warning: "Warning",
  success: "Success",
  import_file_message:
    "The file to be selected must comply with the following conditions:",
  import_file_message_c1: "1. File extension must be (.xlsx).",
  import_file_message_c2:
    "2. Table header should be the same order as the following inputs:",
  import_file_message_c2_error:
    "The table does not match the previous condition",
  import_file_message_c3_error: "Error happened while inserting data",
  import_image_message:
    "The image to be selected must comply with the following conditions:",
  import_image_message_c1:
    "1. Image extension should be the same as the following: (.png, .jpg, .jpeg).",
  import_image_message_c2:
    "2. Images name must be the same as the product code.",
  import_image_message_c3:
    "3. In order to save more than one image for each product, you should add to the name of the product the following",
  import_image_message_c4:
    "Product Code _ start from num 1. Example: 1000_1, 1000_2.",
  import_image_message_c5:
    "4. It's possible to choose images from one folder or from more than one folder.",
  import_image_message_c2_error:
    "The table does not match the previous condition",
  import_image_message_c3_error: "Error happened while uploading images",
  num_of_errors: "Number Of Errors",
  num_of_succes: "%s new records have been entered",
  print: "Print",
  printingStatus: "Printing Status",
  printingConfrimation: "Did the printing process completed successfully?",
  filter: "Filter",
  from_to: "from - to",
  date: "Date range",
  date_from_to: "%s %s %s",
  sendAway: "Send Away",
  emptyStock: "Stock is empty!!",
  confrimBuyAllStock: "Do you want to buy all the stock?",
  consumeAll: "%s stock is empty",
  productIsntExist: "Product will be removed from this order",
  submitOrder: "Order will be submited",
  submitted: "Submitted",
  submittedSuccess: "Submitted order successfully",
  connect: "Connect",
  bisan: "Bisan",
  current: "Current",
  enable: "Enable",
  disable: "Disable",
  productWillBeEdited: "Selected products will be edited.",
  confirm: "Confirm",
  cancel: "Cancel",
  successfullyUpdated: "Successfully updated",
})
