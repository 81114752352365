// Dependencies
import React from "react"
import { Field } from "formik"
import PropTypes from "prop-types"
// Core Files
import { trans } from "core/localization"
import { isNotValid, validateInput } from "materialui/Validation"
// Material Ui
import useFormStyles from "materialui/Styles"
import { FormControl, TextField } from "@material-ui/core"

export default function DatePickers({ name, label, ...rest }) {
  const classes = useFormStyles()

  return (
    <Field name={name}>
      {({ field, form }) => {
        return (
          <FormControl
            error={!isNotValid(form, name)}
            className={classes.formControl}
          >
            <TextField
              id={name}
              {...rest}
              {...field}
              label={trans(label)}
              error={isNotValid(form, name)}
              className={classes.dateTextField}
              helperText={validateInput(form, name)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
        )
      }}
    </Field>
  )
}

DatePickers.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  variant: PropTypes.string,
  label: PropTypes.string.isRequired,
}

DatePickers.defaultProps = {
  type: "date",
  required: false,
  fullWidth: true,
  variant: "outlined",
}
