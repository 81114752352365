// Dependencies
import React from "react"
// Core Files
import useRowProvider from "tables/hooks/useRowProvider"
// Shared Components
import Img from "shared/components/Image/Image"
import Avatar from "shared/components/Image/Avatar"
import ImageNotFound from "shared/components/Image/ImageNotFound"

function ImageFormatter({ column }) {
  const { record } = useRowProvider()
  let { value: imageSrc, theme = "avatar" } = column

  if (theme === "avatar")
    return <Avatar src={imageSrc} children={record.name[0]} />
  if (theme === "thumbnail") return <Img src={imageSrc} alt={ImageNotFound()} />
  else return <Img src={imageSrc} alt={ImageNotFound()} />
}

export default ImageFormatter
