// Dependencies
import React from "react"
import { useHistory } from "react-router"
// Core Files
import useOnce from "core/hooks/useOnce"
import { trans } from "core/localization"
import {
  pageParam,
  pageAction,
  loadOptions,
  filterObject,
} from "core/formik/helpers/standardFormikHelper"
// Shared Components
import Input from "materialui/Input"
import ListRData from "materialui/List"
import GridItem from "materialui/Grid/GridItem"
import ProgressBar from "materialui/ProgressBar"
import GridDivider from "materialui/Grid/GridDivider"
import MasterConfirm from "layout/Confirm/MasterConfirm"
import GridContainer from "materialui/Grid/GridContainer"
import { LightBackdrop } from "materialui/BackDrop/BackDrop"
import { SuccessAlertMessage } from "layout/Modal/AlertMessage"
// Material Ui Styles
import { Button, colors, styled } from "@material-ui/core"

const PaddingDiv = styled("div")(({ theme }) => ({
  padding: theme.spacing(2),
}))

function BisanCustomerForm() {
  const history = useHistory()
  const id = pageParam(history, 2)
  const action = pageAction(history)
  const options = loadOptions(history)
  const [loading, setLoading] = React.useState(true)
  const [bisanData, setBisanData] = React.useState([])
  const [searchText, setSearchText] = React.useState("")
  const [formRecord, setFormRecord] = React.useState({})
  const [confirming, setConfirm] = React.useState(false)
  const [listChecked, setListChecked] = React.useState(-1)
  const [fetchLoading, setFetchLoading] = React.useState(false)
  const [successAlert, setSuccessAlert] = React.useState(false)
  const [successMessage, setSuccessMessage] = React.useState([])

  const searchBtnStyle = {
    width: 120,
    height: 50,
    backgroundColor: colors.amber[200],
  }

  useOnce(() => {
    if (action === "edit") {
      options.service
        .get(id)
        .then((res) => {
          setFormRecord(res.data)
          setLoading(false)
        })
        .catch((err) => {
          navigateToTable()
        })
    } else setLoading(false)
  })

  const navigateToTable = () => {
    const path = pageParam(history, 1)
    history.push(`/${path}`)
  }

  const handleSearchBisanCustomer = () => {
    if (searchText.length >= 3 && !fetchLoading) {
      setFetchLoading(true)
      options.service
        .getBisanCustomers({ customer_name: searchText })
        .then((res) => {
          setBisanData(res.data)
          setFetchLoading(false)
        })
    }
  }
  const handleKeyPress = (e) => {
    if (e.key === "Enter") handleSearchBisanCustomer()
  }
  const handleSubmit = () => {
    if (bisanData.length && listChecked !== -1) {
      setConfirm(true)
    }
  }

  const handleOnConfirm = () => {
    if (bisanData.length && listChecked !== -1) {
      delete formRecord["salespersons"]
      let cloneValues = JSON.parse(JSON.stringify(formRecord))
      cloneValues = filterObject(cloneValues)
      cloneValues = { ...cloneValues, ...bisanData[listChecked] }

      options.service.update(formRecord.id, cloneValues).then((res) => {
        setSuccessAlert(true)
        setSuccessMessage(trans("customerUpdated"))
        setTimeout(() => navigateToTable(), 500)
      })
    }
  }

  return (
    <>
      {loading ? (
        <LightBackdrop open={loading} />
      ) : (
        <>
          <PaddingDiv>
            <GridDivider label={`${trans("customer__")} ${trans("current")}`} />
            <GridContainer>
              {/* Local Customer Details */}
              <GridItem xs={6}>
                <Input label={formRecord.code || ""} disabled />
              </GridItem>
              <GridItem xs={6}>
                <Input label={formRecord.name || ""} disabled />
              </GridItem>
            </GridContainer>
          </PaddingDiv>
          <PaddingDiv>
            <GridDivider label={`${trans("customer")} ${trans("bisan")}`} />
            <GridContainer>
              {/* Bisan Customer Details */}
              <GridItem xs={6}>
                <Input label={formRecord.bisan_customer_code || ""} disabled />
              </GridItem>
              <GridItem xs={6}>
                <Input
                  label={
                    formRecord.bisan_customer_code !== null
                      ? formRecord.name
                      : ""
                  }
                  disabled
                />
              </GridItem>
            </GridContainer>
          </PaddingDiv>
          <PaddingDiv>
            <GridDivider label="bisanCustomerSearch" />
            <GridContainer>
              <GridItem xs={6}>
                <Input
                  startIcon
                  value={searchText}
                  label={["search", "...."]}
                  onKeyPress={handleKeyPress}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </GridItem>
              <GridItem xs={6}>
                <Button
                  size="large"
                  style={searchBtnStyle}
                  onClick={handleSearchBisanCustomer}
                >
                  {trans("search")}
                </Button>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12}>
                {fetchLoading ? (
                  <ProgressBar control="circular" />
                ) : (
                  <ListRData
                    data={bisanData}
                    checked={listChecked}
                    setChecked={setListChecked}
                  />
                )}
              </GridItem>
            </GridContainer>
          </PaddingDiv>
          <PaddingDiv>
            <Button size="large" onClick={handleSubmit} style={searchBtnStyle}>
              {trans("save")}
            </Button>
          </PaddingDiv>
          <SuccessAlertMessage
            open={successAlert}
            messages={successMessage}
            onClose={() => setSuccessAlert(false)}
          />
          <MasterConfirm
            open={confirming}
            onConfirm={handleOnConfirm}
            onClose={() => setConfirm(false)}
            message={trans("bisan_customerWillBeSynced")}
          />
        </>
      )}
    </>
  )
}

export default BisanCustomerForm
