// Dependencies
import React from "react"
import PropTypes from "prop-types"
import Is from "@flk/supportive-is"
import { Obj } from "reinforcements"
import { trans } from "core/localization"
// Core Files & Hooks
import useOnce from "core/hooks/useOnce"
import useTable from "tables/hooks/useTable"
import { arabicToEnglishNumbers } from "tables/helpers/DateHelpers"
import useTableRequest from "tables/hooks/useTableRequest"
import { getDatePickerFormatte } from "core/formik/helpers/standardFormikHelper"
// Shared Components
import Input from "materialui/Input"
import DateRange from "materialui/DateRange"
import GridItem from "materialui/Grid/GridItem"
import StaticButton from "materialui/Button/Button"
import SelectInput from "materialui/Select/SelectInput"
import GridContainer from "materialui/Grid/GridContainer"
import CheckBoxGroup from "materialui/CheckBox/CheckboxGroupNF"
import { ButtonText } from "modules/Orders/components/PrintPreviewOrders/OrdersToPrint"
// Material Ui
import { makeStyles, styled } from "@material-ui/core"
import DateRangeIcon from "@material-ui/icons/DateRange"
import FindReplaceIcon from "@material-ui/icons/FindReplace"

export const ImportOptionComponent = ({ configuration }) => {
  const { buttons } = configuration
  const width = buttons.length > 1 ? "450" : "150"
  return (
    <Div width={width}>
      <GridContainer>
        {buttons.map((Button) => {
          const alignment = buttons.length > 1 ? "center" : "left"
          let gridSize = 12 / buttons.length
          if (buttons.length > 5) gridSize = 3
          return (
            <GridItem key={Button.key} xs={gridSize} alignItems={alignment}>
              <Button.component Icon={Button.icon} heading={Button.heading} />
            </GridItem>
          )
        })}
      </GridContainer>
    </Div>
  )
}

ImportOptionComponent.propTypes = { configuration: PropTypes.object.isRequired }

export const Div = styled("div")(({ width }) => {
  return {
    width: `${width}px`,
  }
})

const useStyle = makeStyles((theme) => ({
  dateWidth: {
    width: "350px",
  },
}))

export function FilterOptionComponent({ configuration }) {
  const { checkboxs, dateFromTo, list, salespersons } = configuration

  const width = dateFromTo ? "680" : checkboxs ? "450" : "250"

  const { controlRequest, setRequest } = useTableRequest()
  const [options, setOptions] = React.useState(checkboxs || [])

  let { options: settings, tableInfoObj } = useTable()
  const { updateTableInfo, pagination, setCurrentPage } = tableInfoObj
  const perPage = pagination.perPage

  // Check if request status changed, in order to reset checkboxs to default state
  useOnce(() => {
    if (controlRequest.status !== "filter") {
      setOptions((options) => {
        for (let index = 0; index < options.length; index++) {
          options[index].checked = false
        }
        return [...options]
      })
      setDateRangeValue("")
      if (dateFromTo) dateFromTo.values.empty = true
      if (list) list.checked = ""
    }
  })
  const buttonLabel = (
    <>
      <ButtonText>{trans("filter")}</ButtonText>
      <FindReplaceIcon />
    </>
  )
  const [buttonFilterText, setButtonFilterText] = React.useState(buttonLabel)
  const [buttonFilterDisabled, setButtonFilterDisabled] = React.useState(false)

  const restButtonLabel = () => {
    setButtonFilterDisabled(false)
    setButtonFilterText(buttonLabel)
  }

  const handleOnClick = () => {
    setCurrentPage(0)
    setButtonFilterDisabled(true)
    setButtonFilterText("waiting")

    let reqParams = {}

    // Checkbox Section
    options.forEach((element) => {
      if (element.checked) reqParams = { ...reqParams, ...element.reqParams }
    })

    // Date Section
    if (dateRangeValue) {
      const sDate = dateRangeValue
        .split("   -   ")
        .map((d) => arabicToEnglishNumbers(d))
      const reqDateKey = `_date_${dateFromTo.key}`
      reqParams[reqDateKey] = sDate.join(",")
    }

    // Select Section
    if (selected) reqParams[`_${list.key}`] = selected.key

    // Salespersons Section
    if (salespersons) reqParams[`_${salespersons.key}`] = salesperson.key

    // Submit Section
    if (!Is.empty(reqParams)) {
      setRequest({
        status: "filter",
        callbackfun: () => restButtonLabel(),
        request: (params) => settings.service.getList(params),
      })
      updateTableInfo({ perPage, page: 1, _get: "*", ...reqParams }, "filter")
    } else restButtonLabel()
  }

  /**
   * Date Range Section
   *
   */
  const classes = useStyle()
  const [openDateRange, setOpenDateRange] = React.useState(false)
  const [dateRange, setDateRange] = React.useState({
    startDate: null,
    endDate: null,
  })

  const [dateRangeValue, setDateRangeValue] = React.useState(
    dateFromTo
      ? dateFromTo.values.empty
        ? ""
        : `${dateFromTo.values.startDate}   -   ${dateFromTo.values.endDate}`
      : ""
  )

  const handleDateOnChange = (range) => {
    setDateRange(range)
    const startDate = getDatePickerFormatte(range.startDate)
    const endDate = getDatePickerFormatte(range.endDate)
    setDateRangeValue(`${startDate}   -   ${endDate}`)
    dateFromTo.values.startDate = startDate
    dateFromTo.values.endDate = endDate
    dateFromTo.values.empty = false
  }

  /**
   * Select Section
   *
   */
  const [selected, setSelected] = React.useState(
    controlRequest.status === "filter" && list ? list.checked : ""
  )
  const handleOnStatusChange = (selectedObj) => {
    setSelected(selectedObj)
    list.checked = selectedObj
  }

  /**
   * Salesperson Section
   *
   */
  const [salesperson, setSalesperson] = React.useState(
    controlRequest.status === "filter" && salespersons
      ? salespersons.checked
      : ""
  )
  const handleOnSalespersonsChange = (selectedObj) => {
    setSalesperson(selectedObj)
    setSalesperson.checked = selectedObj
  }

  return (
    <Div width={width}>
      <GridContainer>
        <GridContainer>
          <GridItem
            xs={options.length ? 6 : 12}
            alignItems={checkboxs && dateFromTo ? "center" : "left"}
          >
            <StaticButton
              onClick={handleOnClick}
              label={buttonFilterText}
              disabled={buttonFilterDisabled}
            ></StaticButton>
          </GridItem>
          {options.length ? (
            <GridItem xs={6}>
              <CheckBoxGroup
                row
                options={options}
                setOptions={setOptions}
                label={trans("status")}
              />
            </GridItem>
          ) : null}
        </GridContainer>

        {list ? (
          <GridContainer>
            <GridItem xs={12} alignItems={options.length ? "right" : "left"}>
              <SelectInput
                value={selected}
                label={list.label}
                options={list.options}
                className={classes.dateWidth}
                onChange={handleOnStatusChange}
              />
            </GridItem>
          </GridContainer>
        ) : null}

        {dateFromTo ? (
          <GridContainer>
            <GridItem xs={12} alignItems={options.length ? "right" : "left"}>
              <Input
                placeholder={false}
                value={dateRangeValue}
                className={classes.dateWidth}
                startIcon={<DateRangeIcon />}
                onClick={() => setOpenDateRange(true)}
                label={Obj.clone(dateFromTo.inputLabel)}
              />
              <DateRange
                {...dateFromTo.props}
                open={openDateRange}
                initialDateRange={dateRange}
                onChange={handleDateOnChange}
                closeModal={() => setOpenDateRange(false)}
              />
            </GridItem>
          </GridContainer>
        ) : null}

        {salespersons ? (
          <GridContainer>
            <GridItem xs={12} alignItems={options.length ? "right" : "left"}>
              <SelectInput
                lazyLoading
                value={salesperson}
                label="salesperson"
                name="_employee_id"
                className={classes.dateWidth}
                request={salespersons.request}
                onChange={handleOnSalespersonsChange}
              />
            </GridItem>
          </GridContainer>
        ) : null}
      </GridContainer>
    </Div>
  )
}

FilterOptionComponent.propTypes = { configuration: PropTypes.object.isRequired }
