// Dependencies
import PropTypes from "prop-types"
import { compose } from "recompose"
import React, { useState } from "react"
// Core Files
import DrawerProvider from "layout/providers/DrawerProvider"
// Shared Components
import { withRTL } from "shared/themes/withRTL"
import { withTheme } from "shared/themes/withTheme"
import MasterHeader from "layout/Headers/MasterHeader"
import MasterSideBar from "layout/SideBars/MasterSideBar"
import MasterContent from "layout/Contents/MasterContent"
//Material Ui + Styles
import { makeStyles, CssBaseline } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
}))

function MasterLayout(props) {
  const classes = useStyles()
  const [drawer, toggleDrawer] = useState(false)
  const [nestedList, setNestedList] = useState(false)

  const drawerOptions = { drawer, toggleDrawer, nestedList, setNestedList }

  return (
    <DrawerProvider.Provider value={drawerOptions}>
      <div className={classes.root}>
        <CssBaseline />
        <MasterHeader {...props} />
        <MasterSideBar>
          <MasterContent pageTab={props.pageTab}>
            {props.children}
          </MasterContent>
        </MasterSideBar>
      </div>
    </DrawerProvider.Provider>
  )
}

export default compose(withTheme, withRTL)(MasterLayout)

MasterLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
}
