// Core Files
import { trans } from "core/localization/index"
import { filterObject } from "core/formik/helpers/standardFormikHelper"

function UpdateRecord(values, FormikHandler, ContainerHandler, TableHandler) {
  const { record, options, rowIndex, tableRecordsObj } = TableHandler

  const { type, service } = options
  let cloneValues = JSON.parse(JSON.stringify(values))
  cloneValues = filterObject(cloneValues)

  return service
    .update(record.id, cloneValues)
    .then((response) => {
      FormikHandler.setFormResult({
        success: [trans("recordUpdated", trans(ContainerHandler.currentTitle))],
      })
      setTimeout(() => {
        FormikHandler.resetForm({})
        ContainerHandler.onConfirm() // Close Modal if exist or redirect to Table if page exist
        if (type !== "Page")
          tableRecordsObj.setTableRecords((records) => {
            if (response.data.id !== records[rowIndex]) {
              records.forEach(function (record, index) {
                if (record.id === response.data.id) {
                  records[index] = response.data
                }
              })
            } else records[rowIndex] = response.data
            return [...records]
          })
      }, 1000)
    })
    .catch((err) => {
      FormikHandler.setFormStatus("error") // error
      FormikHandler.setSubmitting(false) // formik submitting
      if (err.response && err.response.data) {
        FormikHandler.setFormResult(err.response.data.errors)
      }
    })
    .finally(() => {
      if (ContainerHandler.setIsSubmitting)
        ContainerHandler.setIsSubmitting(false)
    })
}

export default UpdateRecord
