// Dependencies
import React from "react"
import Is from "@flk/supportive-is"
import { useHistory } from "react-router-dom"

function Middleware(props) {
  let { route, match } = props
  let { params } = match
  const history = useHistory()
  let middlewareList = route.middleware

  if (middlewareList) {
    if (!Is.array(middlewareList)) {
      middlewareList = [middlewareList]
    }

    for (let middleware of middlewareList) {
      let output = middleware(route, history)
      if (output) return output
    }
  }

  // Scroll to the top page when navigating to new page
  window.scrollTo(0, 0)
  return <route.component params={params} history={history} />
}

export default Middleware
