// Dependencies
import * as Yup from "yup"
import Is from "@flk/supportive-is"

export default class SetUpCustomerForm {
  constructor(record, action) {
    let active
    if (Is.undefined(record.active)) active = true
    else if (Is.boolean(record.active)) active = record.active
    else if (Is.string(record.active))
      active = Number(record.active) === 1 ? true : false
    else active = record.active === 1 ? true : false

    if (record.last_visit)
      record["last_visit"] = record["last_visit"].split(" ")[0]
    if (record.last_order_date)
      record["last_order_date"] = record["last_order_date"].split(" ")[0]
    if (record.last_payment_date)
      record["last_payment_date"] = record["last_payment_date"].split(" ")[0]

    this.initialValues = {
      active,
      id: record.id,
      fax: record.fax || "",
      name: record.name || "",
      code: record.code || "",
      city: record.city || "",
      note: record.note || "",
      email: record.email || "",
      phone: record.phone || "",
      country: record.country || "",
      balance: record.balance || "0",
      target: record.target || "5000",
      last_visit: record.last_visit || "",
      credit_limit: record.credit_limit || "15000",
      last_order_date: record.last_order_date || "",
      last_payment_date: record.last_payment_date || "",
      post_dated_checks_total: record.post_dated_checks_total || "2",
    }

    this.validationSchema = Yup.object({
      fax: Yup.string(),
      city: Yup.string(),
      note: Yup.string(),
      phone: Yup.string(),
      country: Yup.string(),
      active: Yup.boolean(),
      last_visit: Yup.date().nullable(),
      last_order_date: Yup.date().nullable(),
      name: Yup.string().required("Required"),
      code: Yup.string().required("Required"),
      last_payment_date: Yup.date().nullable(),
      email: Yup.string().email("Invalid Email Format"),
      target: Yup.number().min(2500).required("Required"),
      credit_limit: Yup.number().min(15000).required("Required"),
      balance: Yup.number().min(0).required("Required"),
      post_dated_checks_total: Yup.number().min(1).max(3).required("Required"),
    })
  }

  getInitialValues() {
    return this.initialValues
  }
  getValidationSchema() {
    return this.validationSchema
  }
}
