// Dependencies
import React from "react"
import PropTypes from "prop-types"
// Material Ui
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import { List, ListItemIcon, makeStyles, Radio } from "@material-ui/core"

function ListRData({ data, checked, setChecked }) {
  const classes = listDataStyles()

  const handleCheck = (index) => () => setChecked(index)

  return (
    <List className={classes.root}>
      {data.map((value, index) => {
        const labelId = `checkbox-list-label-${index}`

        return (
          <ListItem key={index} dense button onClick={handleCheck(index)}>
            <ListItemIcon>
              <Radio
                edge="start"
                tabIndex={-1}
                disableRipple
                checked={index === checked}
                inputProps={{ "aria-labelledby": labelId }}
              />
            </ListItemIcon>
            <ListItemText id={labelId} primary={value.code} />
            <ListItemText id={labelId} primary={value.name} />
            <ListItemText id={labelId} primary={value.bisan_account_code} />
          </ListItem>
        )
      })}
    </List>
  )
}

ListRData.propTypes = {
  data: PropTypes.array.isRequired,
  checked: PropTypes.number.isRequired,
  setChecked: PropTypes.func.isRequired,
}

const listDataStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}))

export default ListRData
