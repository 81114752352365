// Dependencies
import React from "react"
import PropTypes from "prop-types"
import { Random } from "reinforcements"
// Material Ui
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import { Checkbox as MUICheckBox } from "@material-ui/core"
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"

function Checkbox({ onChange, ...rest }) {
  const handleOnChange = (e) => {
    onChange(e.target.checked)
  }
  return <MUICheckBox onChange={handleOnChange} {...rest} />
}

export default Checkbox

Checkbox.propTypes = {
  id: PropTypes.string,
  icon: PropTypes.node,
  size: PropTypes.string,
  color: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  checkedIcon: PropTypes.node,
}

Checkbox.defaultProps = {
  size: "medium", // small / medium
  id: Random.id(),
  disabled: false,
  onChange: () => {},
  color: "secondary", // default / primary / secondary (this is the default for the Radio)
  checkedIcon: <CheckBoxIcon fontSize="small" />,
  icon: <CheckBoxOutlineBlankIcon fontSize="small" />,
}
