// Dependencies
import React from "react"
import { BrowserRouter as Router, Switch } from "react-router-dom"
// Core Files
import Renderer from "./Renderer"
/**
 * Return all application routes
 *
 * @returns {Array}
 */
export default function Routes() {
  return (
    <Router>
      <Switch>
        <Renderer />
      </Switch>
    </Router>
  )
}
