// Core Files
import * as serviceWorker from "./serviceWorker"
import { initiateLocaleCode } from "core/localization/locales"
//Service Provider Container
import serviceProviderContainer from "./serviceProviderContainer"
// Internal Service Providers
import initiateNavigator from "./Routes/Navigation/navigator"
import routerServiceProviders from "./Routes/routerServiceProviders"
import configurationServiceProviders from "./configuration/configurationServiceProvider"

initiateLocaleCode()
initiateNavigator()

export default class Reactor {
  internalServiceProviders = [
    configurationServiceProviders,
    routerServiceProviders,
  ]

  constructor() {
    serviceProviderContainer.registerInternalServiceProviders(
      this.internalServiceProviders
    )
  }

  /**
   * Allow the application to work offline
   */
  workOffline() {
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister()
    return this
  }

  /**
   * Register the entire application service providers to the service providers container
   *
   * @param {array} serviceProviders
   */
  registerServiceProviders(serviceProviders) {
    serviceProviderContainer.register(serviceProviders)
  }

  /**
   * Start the application
   */
  react() {
    // Start calling all service providers
    serviceProviderContainer.dispatch()

    // Start scanning all routes
    routerServiceProviders.scan()
  }
}
