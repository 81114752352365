// Dependencies
import React from "react"
// Core Files
import useTable from "tables/hooks/useTable"
import useRowProvider from "tables/hooks/useRowProvider"
import { isSelected } from "tables/helpers/TableRowHelpers"
import { handleRowClick } from "tables/helpers/TableRowHelpers"
// Shared Components
import Checkbox from "materialui/CheckBox"

function CheckboxFormatter({ column }) {
  const [isItemSelected, setIsItemSelected] = React.useState(false)
  const { record } = useRowProvider()
  const { tableSelectedRow } = useTable()
  const { selected } = tableSelectedRow
  React.useEffect(() => {
    setIsItemSelected(isSelected(record.id, selected))
  }, [record, selected])
  const handleOnChange = (checked) => {
    handleRowClick(record.id, tableSelectedRow)
  }
  return <Checkbox onChange={handleOnChange} checked={isItemSelected} />
}

export default CheckboxFormatter
