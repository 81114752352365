// We need a container to store our service providers
let serviceProviders = [] // Private

// resolved|created serviceProviders objects
const resolvedServiceProviders = []

// all services that will be provided to any module
const reactorServiceProviderContainer = {}

/**
 * Regiseter the given service providers
 *
 * @param {array} serviceProviderList
 */
function register(serviceProviderList) {
  serviceProviders = serviceProviderList
}

function registerInternalServiceProviders(serviceProviders) {
  for (let serviceProvider of serviceProviders) {
    let { name, call } = serviceProvider.provider

    reactorServiceProviderContainer[name] = call
  }
}

/**
 * Call all service providers
 *
 */
function dispatch() {
  for (let serviceProvider of serviceProviders) {
    const provider = new serviceProvider(reactorServiceProviderContainer) // function or class
    resolvedServiceProviders.push(provider)
  }
}

export default {
  register,
  dispatch,
  registerInternalServiceProviders,
}
