// Dependencies
import clsx from "clsx"
import React from "react"
import { useHistory } from "react-router-dom"
// Core Files & Hooks
import cache from "core/cache"
import Globals from "core/globals"
import { trans } from "core/localization"
import useDrawer from "layout/hooks/useDrawer"
import { setMetaData } from "core/configuration/metaData"
import { refresh } from "core/Routes/routerServiceProviders"
// Helper functions
import userLogout from "modules/Users/helpers/userLogout"
// Shared Components
import Tooltip from "materialui/ToolTip/MasterToolTip"
// Material Ui + Styles + Icons
import {
  AppBar,
  Toolbar,
  Typography,
  Badge,
  IconButton,
} from "@material-ui/core"
import MenuIcon from "@material-ui/icons/Menu"
import RefreshIcon from "@material-ui/icons/Refresh"
import Brightness7Icon from "@material-ui/icons/Brightness7"
import Brightness4Icon from "@material-ui/icons/Brightness4"
import NotificationsIcon from "@material-ui/icons/Notifications"
import useMasterLayoutStyles from "shared/styles/MasterLayoutStyles"
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew"
import FormatTextdirectionRToLIcon from "@material-ui/icons/FormatTextdirectionRToL"
import FormatTextdirectionLToRIcon from "@material-ui/icons/FormatTextdirectionLToR"

function MasterHeader(props) {
  const history = useHistory()
  const classes = useMasterLayoutStyles()
  const { drawer, toggleDrawer, setNestedList } = useDrawer()
  const [openDirectionTooltip, setOpenDirectionTooltip] = React.useState(false)
  const {
    label,
    darkMode,
    setDarkMode,
    rightToLeft,
    setRightToLeft,
    setDirectionMode,
  } = props

  const handleToggleRotation = () => {
    setRightToLeft(!rightToLeft)
    if (Globals.localeCode === "en") {
      Globals.localeCode = "ar"
    } else {
      Globals.localeCode = "en"
    }
    setDirectionMode(rightToLeft ? "ltr" : "rtl")
    setMetaData("title", label)
  }

  const handleToggleThemeMode = () => {
    if (!darkMode) cache.set("themeMode", "dark")
    else cache.set("themeMode", "light")
    setDarkMode(!darkMode)
  }

  const handleToggleDrawer = () => {
    setNestedList(drawer ? false : true)
    toggleDrawer(!drawer)
  }

  return (
    <AppBar
      position="absolute"
      className={clsx(
        classes.appBar,
        {
          [classes.appBarShiftLeft]: drawer && !rightToLeft,
        },
        { [classes.appBarShiftRight]: drawer && rightToLeft }
      )}
    >
      <Toolbar className={classes.toolbar}>
        {/* SideBar Toggle Icon */}
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleToggleDrawer}
          aria-label="open drawer"
          className={clsx(
            {
              [classes.menuButtonHidden]: drawer,
            },
            { [classes.menuButtonLeft]: !rightToLeft },
            { [classes.menuButtonRight]: rightToLeft }
          )}
        >
          <MenuIcon />
        </IconButton>
        {/* Application Name */}
        <Typography noWrap variant="h6" className={classes.title}>
          {trans("appName")}
        </Typography>

        {/* Divider */}
        <div className={classes.grow} />
        {/* Middle Side Bar */}
        {/* <p>Here we can put the company icon</p> */}
        {/* <p>Imam Icon</p> */}
        <p>Imam</p>
        <div className={classes.grow} />
        {/* Left Buttons */}
        {/* Notifcication Button */}
        <IconButton color="inherit">
          <Tooltip placement="bottom" title={trans("notifications")}>
            <Badge badgeContent={4} color="secondary">
              <NotificationsIcon />
            </Badge>
          </Tooltip>
        </IconButton>
        {/* ThemeMode Button */}
        <IconButton color="inherit" onClick={handleToggleThemeMode}>
          <Tooltip
            placement="bottom"
            title={trans("change", trans("themeMode"))}
          >
            {darkMode ? <Brightness4Icon /> : <Brightness7Icon />}
          </Tooltip>
        </IconButton>
        {/* Direction Button */}
        <IconButton
          color="inherit"
          onClick={() => {
            setOpenDirectionTooltip(false)
            handleToggleRotation()
          }}
          onMouseEnter={(e) => {
            setOpenDirectionTooltip(true)
          }}
          onMouseLeave={(e) => {
            setOpenDirectionTooltip(false)
          }}
        >
          <Tooltip
            open={openDirectionTooltip}
            placement="bottom"
            title={trans("change", trans("direction"))}
          >
            {rightToLeft ? (
              <FormatTextdirectionLToRIcon />
            ) : (
              <FormatTextdirectionRToLIcon />
            )}
          </Tooltip>
        </IconButton>
        {/* Refresh Button */}
        <IconButton
          color="inherit"
          component="span"
          onClick={() => refresh(history)}
        >
          <Tooltip placement="bottom" title={trans("refresh")}>
            <RefreshIcon />
          </Tooltip>
        </IconButton>
        {/* Logout Button */}
        <IconButton
          color="inherit"
          component="span"
          onClick={() => userLogout(history)}
        >
          <Tooltip placement="bottom" title={trans("logout")}>
            <PowerSettingsNewIcon />
          </Tooltip>
        </IconButton>
      </Toolbar>
    </AppBar>
  )
}

export default MasterHeader
