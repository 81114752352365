// Dependencies
import React from "react"
import PropTypes from "prop-types"
import { Random } from "reinforcements"
// Material Ui
import { Radio } from "@material-ui/core"

function RadioButton(props) {
  let { field, id, size, disabled, color, ...rest } = props
  return (
    <Radio id={id} size={size} color={color} disabled={disabled} {...rest} />
  )
}

export default RadioButton

RadioButton.propTypes = {
  id: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
}

RadioButton.defaultProps = {
  size: "medium", // small / medium
  id: Random.id(),
  disabled: false,
  color: "secondary", // default / primary / secondary (this is the default for the Radio)
}
