// Dependencies
import React from "react"
import Is from "@flk/supportive-is"
// Core Files & Hooks
import FormikControl from "core/formik/FormikControl"
import StandardFormik from "core/formik/StandardFormik"
import useRowProvider from "tables/hooks/useRowProvider"
import RoleService from "modules/Users/services/RoleService"
// Shared Components
import GridItem from "materialui/Grid/GridItem"
import GridContainer from "materialui/Grid/GridContainer"

function UserForm(props) {
  const formRequired = props.action === "add" ? true : false
  const { record } = useRowProvider()
  let defaultValue
  if (Is.undefined(record) || record.roles.length === 0) defaultValue = null
  else defaultValue = record.roles[0]

  return (
    <StandardFormik {...props}>
      <GridContainer>
        <GridItem xs={8}>
          <FormikControl
            required
            label="roles"
            name="_role_id"
            backendV="role"
            control="lazyautocomplete"
            defaultValue={defaultValue}
            request={() => RoleService.list({ _all: true })}
          />
        </GridItem>
        <GridItem alignItems="right">
          <FormikControl name="active" label="active" control="switchbutton" />
        </GridItem>
      </GridContainer>
      <FormikControl name="name" label="name" control="input" required />
      <FormikControl
        required
        name="email"
        type="email"
        label="email_"
        control="input"
      />
      <FormikControl
        name="password"
        label="password"
        control="password"
        required={formRequired}
      />
      <FormikControl
        name="c_password"
        control="password"
        label="c_password"
        required={formRequired}
      />
    </StandardFormik>
  )
}

export default UserForm
