// Dependencise
import React from "react"
import PropTypes from "prop-types"
// Core Files
import { trans } from "core/localization"
// Shared Components
import {
  Card,
  Button,
  CardMedia,
  makeStyles,
  CardActions,
  CardActionArea,
} from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.spacing(17),
    marginBottom: theme.spacing(2),
  },
  media: {
    width: theme.spacing(17),
    height: theme.spacing(17),
  },
  actions: {
    justifyContent: "center",
    padding: 0,
  },
}))

function ProductCard({ imageObj, index, setImages }) {
  const { image } = imageObj
  const classes = useStyles()

  const handleOnClick = () => {
    setImages((images) => {
      images.splice(index, 1)
      return [...images]
    })
  }
  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia image={image} className={classes.media} />
      </CardActionArea>
      <CardActions className={classes.actions}>
        <Button size="small" color="secondary" onClick={handleOnClick}>
          {trans("remove")}
        </Button>
      </CardActions>
    </Card>
  )
}

export default ProductCard

ProductCard.propTypes = {
  index: PropTypes.number,
  setImages: PropTypes.func,
  imageObj: PropTypes.object.isRequired,
}
