// Dependencies
import React from "react"
//Core Files & Hooks
import { trans } from "core/localization"
import useTable from "tables/hooks/useTable"
import Tooltip from "materialui/ToolTip/MasterToolTip"
// Material Ui + Styles
import Menu from "materialui/Menu"
import useFormStyles from "materialui/Styles"
import { IconButton } from "@material-ui/core"
import ListIcon from "@material-ui/icons/List"

const TableMenuButton = () => {
  const classes = useFormStyles()
  const { tableColumnsObj } = useTable()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const { tableColumns, setTableColumns } = tableColumnsObj

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOnChange = (selected) => {
    setTableColumns((records) => {
      records.forEach((option, index) => {
        if (option.key === selected.key) {
          records[index].filter = !records[index].filter
          return
        }
      })
      return [...records]
    })
  }

  return (
    <>
      <IconButton
        color="default"
        className={classes.toolBarPadding}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <Tooltip title={trans("filterList")}>
          <ListIcon fontSize="large" />
        </Tooltip>
      </IconButton>

      <Menu
        checkbox
        anchorEl={anchorEl}
        onClose={handleClose}
        onChange={handleOnChange}
        options={tableColumns.filter((option) => option.allowfilter !== false)}
      />
    </>
  )
}

export default React.memo(TableMenuButton)
