// Dependencies
import React from "react"
// Core Files & Hooks
import useFormik from "core/formik/hooks/useFormik"
import FormikControl from "core/formik/FormikControl"
import StandardFormik from "core/formik/StandardFormik"
import useRowProvider from "tables/hooks/useRowProvider"
import AutoComplete from "materialui/AutoComplete/AutoComplete"

function PositionForm(props) {
  const { setFieldValue } = useFormik()
  let { record = {} } = useRowProvider()

  const defaultValue = record
    ? record.hasOwnProperty("importance")
      ? record.importance
      : ""
    : ""

  function genCharArray(charA, charZ) {
    var a = [],
      i = charA.charCodeAt(0),
      j = charZ.charCodeAt(0)
    for (; i <= j; ++i) {
      a.push(String.fromCharCode(i))
    }
    return a
  }
  return (
    <StandardFormik {...props}>
      <FormikControl
        required
        control="input"
        name="position"
        label="position"
      />
      <AutoComplete
        required
        name="importance"
        label="importance"
        setFieldValue={setFieldValue}
        options={genCharArray("A", "Z")}
        defaultValues={defaultValue || "C"}
      />
    </StandardFormik>
  )
}

export default PositionForm
