// Core Files
import { trans } from "core/localization/index"
import { filterObject } from "core/formik/helpers/standardFormikHelper"

function AddRecord(data, FormikHandler, ContainerHandler, TableHandler) {
  const { options, tableRecordsObj } = TableHandler
  const { type, service } = options
  let cloneValues = JSON.parse(JSON.stringify(data))
  cloneValues = filterObject(cloneValues)

  service
    .create(cloneValues)
    .then((response) => {
      FormikHandler.setFormResult({
        success: [trans("recordAdded", trans(ContainerHandler.currentTitle))],
      })
      setTimeout(() => {
        FormikHandler.resetForm({})
        ContainerHandler.onConfirm() // Close Modal if exist or redirect to Table if page exist
        if (type !== "Page")
          tableRecordsObj.setTableRecords((records) => {
            records.push(response.data)
            return [...records]
          })
      }, 1000)
    })
    .catch((err) => {
      FormikHandler.setFormStatus("error") // error
      FormikHandler.setSubmitting(false) // formik submitting
      if (err.response && err.response.data) {
        FormikHandler.setFormResult(err.response.data.errors)
      }
    })
    .finally(() => {
      if (ContainerHandler.setIsSubmitting)
        ContainerHandler.setIsSubmitting(false)
    })
}

export default AddRecord
