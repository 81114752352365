import localization from "core/localization/index"

localization.extend("ar", {
  root: "جذر",
  value: "ألقيمة",
  category_: "فئة",
  categories: "ألفئات",
  category: "بيانات فئة",
  tree_lvl: "مستوى الفئات",
  category_lvl: "مستوى الفئة",
  category_value: "قيمة الفئة",
  category_parent: "جذر الفئة",
  categoriesList: "قائمة ألفئات",
  products_count: "عدد ألاصناف",
  categoryAdded: "تم تعديل ألفئة بنجاح",
  categoryUpdated: "تم إضافة فئة جديد بنجاح",
})
