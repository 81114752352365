export const getElementRes = async (
  keys,
  values,
  request,
  increasingValue,
  setWaitingAlertValue
) => {
  const valuesWithKeys = values.items.map((value, i) =>
    value.reduce((obj, val, j) => ({ ...obj, [keys[j]]: val }), {})
  )
  const res = await request.storeArr(valuesWithKeys).catch((err) => {
    if (err.response && err.response.data) return err.response.data
    return err
  })
  setWaitingAlertValue((prevValues) => prevValues + increasingValue)
  return res
}

export function plusE_ToNormal(num) {
  return Number(num)
    .toFixed(num.length)
    .replace(/\.?0+$/, "")
}

// function readFileAsync(file) {
//   return new Promise((resolve, reject) => {
//     let reader = new FileReader()

//     reader.onloadend = () => {
//       resolve(reader.result)
//     }

//     reader.onerror = reject

//     reader.readAsDataURL(file)
//   })
// }

export const getImageRes = async (
  request,
  file,
  setWaitingAlertValue,
  increasingValue
) => {
  try {
    // let base64data = await readFileAsync(file)
    let imageName = file.name.split(".")[0]
    let imageCode = imageName.split("_")[0]
    const newImage = { file, image_name: imageName, product_code: imageCode }
    const res = await request.create(newImage).catch((err) => {
      if (err.response && err.response.data) return err.response.data
      return err
    })
    setWaitingAlertValue((prevValues) => prevValues + increasingValue)
    return res
  } catch (err) {
    console.log("Get Image Res Err", err)
  }
}
