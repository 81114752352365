// Dependencies
import PropTypes from "prop-types"
import React from "react"

// Shared Components
import Modal from "./Modal"
import FormModalTitle from "./FormModalTitle"
import { DialogContent } from "@material-ui/core"

function FormModal(props) {
  const { title, onClose, onSubmit, ...rest } = props

  const modalTitle = (
    <FormModalTitle
      title={title}
      onClose={onClose}
      onSubmit={onSubmit}
      {...rest}
    />
  )

  return (
    <div>
      <Modal plain onClose={onClose} {...rest}>
        {modalTitle}
        <DialogContent>{props.children}</DialogContent>
      </Modal>
    </div>
  )
}

export default FormModal

FormModal.propTypes = {
  esc: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired, // same attribute name in the model
  size: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
  backdrop: PropTypes.bool.isRequired,
  // onSubmit: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool.isRequired, // same attribute name in the model
}

FormModal.defaultProps = {
  size: "sm",
  esc: false,
  backdrop: false,
  fullScreen: false,
}
