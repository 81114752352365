// Core Files
import CheckboxFormatter from "tables/Formatters/CheckboxFormatter"

const tableCheckBoxAll = {
  key: "checkbox",
  allowfilter: false,
  heading: "CheckBox",
  formatter: CheckboxFormatter,
}

export default tableCheckBoxAll
