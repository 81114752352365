// Dependencies
import React from "react"
// Core Files
import FormikControl from "core/formik/FormikControl"
import JobTitleService from "modules/Employees/services/JobTitleService"
import { getDefautlValue } from "core/formik/helpers/standardFormikHelper"
// Shared Components
import GridItem from "materialui/Grid/GridItem"
import GridDivider from "materialui/Grid/GridDivider"
import GridContainer from "materialui/Grid/GridContainer"

function BodyEmployee({ record }) {
  let defaultValue = getDefautlValue(record, "job_title")

  return (
    <GridContainer spacing={0}>
      {/* Row 1 */}
      <GridDivider label="personal_info" />
      <GridContainer>
        <GridItem xs={6}>
          <FormikControl required name="name" label="name" control="input" />
        </GridItem>
        <GridItem xs={6}>
          <FormikControl label="birthDate" control="date" name="birth_date" />
        </GridItem>
      </GridContainer>

      {/* Row 2 */}
      <GridDivider label="contact_info" />
      <GridContainer>
        <GridItem xs={3}>
          <FormikControl name="phone" label="phone" control="number" />
        </GridItem>
        <GridItem xs={3}>
          <FormikControl name="fax" label="phone2" control="number" />
        </GridItem>

        <GridItem xs={3}>
          <FormikControl name="country" label="country" control="input" />
        </GridItem>
        <GridItem xs={3}>
          <FormikControl name="city" label="city" control="input" />
        </GridItem>
      </GridContainer>

      {/* Row 3 */}
      <GridDivider label="others_info" />
      <GridContainer>
        <GridItem>
          <FormikControl
            required
            backendV="name"
            label="job_title"
            name="_job_title_id"
            control="lazyautocomplete"
            defaultValue={defaultValue}
            request={() => JobTitleService.list({ _all: true })}
          />
        </GridItem>
        <GridItem>
          <FormikControl name="salary" label="salary" control="number" />
        </GridItem>
        <GridItem>
          <FormikControl name="debit" label="debit" control="number" />
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem>
          <FormikControl
            control="number"
            name="vacation_days"
            label="vacation_days"
          />
        </GridItem>
        <GridItem>
          <FormikControl name="target" label="target" control="number" />
        </GridItem>
        <GridItem>
          <FormikControl label="hireDate" control="date" name="hire_date" />
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12}>
          <FormikControl name="note" label="notes" control="textarea" />
        </GridItem>
      </GridContainer>
    </GridContainer>
  )
}

export default BodyEmployee
