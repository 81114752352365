import localization from "core/localization/index"

localization.extend("ar", {
  product_: "صنف",
  position_: "موقع",
  products: "ألأصناف",
  importance: "أهمية",
  positions: "ألمواقع",
  product: "بيانات صنف",
  position: "بيانات موقع",
  productDetail: "بيانات صنف",
  productsList: "قائمة ألأصناف",
  expiry_date: "تاريخ ألإنتهاء",
  expiry_date_: "تاريخ إنتهاء",
  positionsList: "قائمة ألمواقع",
  box_quantity: "ألكمية بالكرتون",
  productAdded: "تم تعديل ألصنف بنجاح",
  available_quantity: "ألكمية المتوفرة",
  positionAdded: "تم تعديل ألموقع بنجاح",
  productUpdated: "تم إضافة صنف جديد بنجاح",
  positionUpdated: "تم إضافة موقع جديد بنجاح",
})
