export default {
  /**
   * Set the storage engine
   */
  storage: localStorage,

  /**
   * Set data into the storage engine
   *
   * @param {string} key
   * @param {any} value
   */
  set(key, value) {
    this.storage.setItem(
      key,
      JSON.stringify({
        data: value,
      })
    )
  },

  /**
   * Get the value from the storage engine
   *
   * @param {string} key
   * @return {any}
   *
   */
  get(key) {
    let value = this.storage.getItem(key)
    return value ? JSON.parse(value).data : null
  },

  /**
   * Remove key from the storage
   *
   * @param {string} key
   */
  remove(key) {
    this.storage.removeItem(key)
  },
}
