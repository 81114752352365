// Dependencies
import React from "react"
// Core Files & Hooks
import { trans } from "core/localization"
import useTable from "tables/hooks/useTable"
// Material Ui
import { TablePagination as MaterialTablePagination } from "@material-ui/core"

function TablePagination() {
  const { tableInfoObj } = useTable()
  const displayedItemPerPage = [5, 10, 15, 20, 30, 50, 100]
  const {
    pagination,
    currentPage,
    setPageNumber,
    setCurrentPage,
    setItemsPerPage,
  } = tableInfoObj

  const handleChangePage = (e, pageNumber) => {
    setCurrentPage(pageNumber)
    // page has been updated and we need to send again another request to backend
    setPageNumber(pageNumber + 1)
  }

  const handleChangeRowsPerPage = (e) => {
    setItemsPerPage(e.target.value)
    setCurrentPage(0) // reset again current page
  }

  const displayedRows = ({ from, to, count }) => {
    return trans("table.displayedRows", from, to, count)
  }

  return (
    <MaterialTablePagination
      component="div"
      page={currentPage}
      count={pagination.total}
      onChangePage={handleChangePage}
      labelDisplayedRows={displayedRows}
      rowsPerPage={Number(pagination.per_page)}
      rowsPerPageOptions={displayedItemPerPage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      labelRowsPerPage={trans("table.labelRowsPerPage")}
    />
  )
}

export default TablePagination
