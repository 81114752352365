// Dependencies
import React from "react"
// Core Files
import FormikControl from "core/formik/FormikControl"
import StandardFormik from "core/formik/StandardFormik"

function JobTitleForm(props) {
  return (
    <StandardFormik {...props}>
      <FormikControl required name="name" control="input" label="job_title" />
      <FormikControl
        required
        control="input"
        name="arabic_name"
        label="job_title_arabic"
      />
    </StandardFormik>
  )
}

export default JobTitleForm
