// Dependencies
import React from "react"
//Core Files & Hooks
import useTable from "tables/hooks/useTable"
import { trans } from "core/localization/index"
// Shared Components
import Tooltip from "materialui/ToolTip/MasterToolTip"
import MasterConfirm from "layout/Confirm/MasterConfirm"
// Material Ui + Styles
import Star from "@material-ui/icons/Star"
import { IconButton } from "@material-ui/core"
import CheckBox from "@material-ui/icons/CheckBox"
import StarBorder from "@material-ui/icons/StarBorder"
import IndeterminateCheckBox from "@material-ui/icons/IndeterminateCheckBox"

function ToolBarEditProductButton() {
  const [type, setType] = React.useState("")
  const { options, tableRecordsObj, tableSelectedRow } = useTable()
  const [confirming, setConfirm] = React.useState(false)

  const { tableRecords } = tableRecordsObj
  const { selected, setSelected } = tableSelectedRow

  const handleOnConfrim = () => {
    tableRecords.map((record) => {
      if (selected.includes(record.id))
        if (type === "enable") record["active"] = true
        else if (type === "disable") record["active"] = false
        else if (type === "as_new") record["new"] = true
        else if (type === "as_normal") record["new"] = false
      return record
    })

    type && options.service.editBy({ ids: selected, type_: type })
    setSelected([])
  }

  const displayConfirm = (status, target) => {
    setType(target)
    setConfirm(status)
  }

  const TBIconBtn = ({ callback, tooltip, children }) => (
    <IconButton color="primary" onClick={callback}>
      <Tooltip title={trans(tooltip)}>{children}</Tooltip>
    </IconButton>
  )

  return (
    <>
      <MasterConfirm
        open={confirming}
        onConfirm={handleOnConfrim}
        onClose={() => setConfirm(false)}
        title={trans("confirmation")}
        message={trans("productWillBeEdited")}
      />

      <TBIconBtn
        tooltip="enable"
        children={<CheckBox />}
        callback={() => displayConfirm(true, "enable")}
      />
      <TBIconBtn
        tooltip="disable"
        children={<IndeterminateCheckBox />}
        callback={() => displayConfirm(true, "disable")}
      />
      <div style={{ marginRight: 25 }}></div>
      <TBIconBtn
        tooltip="new"
        children={<Star />}
        callback={() => displayConfirm(true, "as_new")}
      />
      <TBIconBtn
        tooltip="not_new"
        children={<StarBorder />}
        callback={() => displayConfirm(true, "as_normal")}
      />
    </>
  )
}

export default ToolBarEditProductButton
