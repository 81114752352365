// Dependencies
import React from "react"
import { useHistory } from "react-router-dom"
// Core Files & Hooks
import {
  pageParam,
  pageAction,
  loadOptions,
  upperCaseFirst,
} from "core/formik/helpers/standardFormikHelper"
import useOnce from "core/hooks/useOnce"
import StandardFormik from "core/formik/StandardFormik"
import useRowProvider from "tables/hooks/useRowProvider"
import MainPageProvider from "core/formik/providers/MainPageProvider"
// Shared Components
import useFormStyles from "materialui/Styles"
import GridContainer from "materialui/Grid/GridContainer"
import { LightBackdrop } from "materialui/BackDrop/BackDrop"

function MainPageForm(props) {
  let { action, pageRecord, ...rest } = props

  const history = useHistory()
  const classes = useFormStyles()
  const { record } = useRowProvider()
  const options = loadOptions(history)
  const component = upperCaseFirst(pageParam(history, 1))
  const singleComponent = component.substring(0, component.length - 1)

  const Head =
    require(`modules/${component}/components/FormComponents/head`).default
  const Body =
    require(`modules/${component}/components/FormComponents/body`).default

  const id = pageParam(history, 2)
  const [loading, setLoading] = React.useState(true)
  const [nextCode, setNextCode] = React.useState("")
  const [formRecord, setFormRecord] = React.useState(record)

  if (action === undefined) action = pageAction(history)

  useOnce(() => {
    if (action === "edit") {
      options.service
        .get(id)
        .then((res) => {
          setFormRecord(res.data)
          setLoading(false)
        })
        .catch((err) => {
          navigateToTable()
          const msg = `${singleComponent} not found`
          if (err && err.response.data.message === msg) action = "create"
        })
    } else if (
      action === "create" &&
      (options.title === "products" || options.title === "customers")
    ) {
      options.service
        .getNextAvailableCode()
        .then((res) => {
          setNextCode(res.data.code)
          setLoading(false)
        })
        .catch((err) => {
          navigateToTable()
          const msg = `${singleComponent} - Error`
          if (err && err.response.data.message === msg) action = "create"
        })
    } else setLoading(false)
  })

  const navigateToTable = () => {
    const path = pageParam(history, 1)
    history.push(`/${path}`)
  }

  const [mainPageState, setMainPageState] = React.useState({})

  return (
    <>
      {loading ? (
        <LightBackdrop open={loading} />
      ) : (
        <MainPageProvider.Provider value={{ mainPageState, setMainPageState }}>
          <StandardFormik
            {...rest}
            action={action}
            nextCode={nextCode}
            fRecord={formRecord}
            onConfirm={navigateToTable}
            className={classes.marginLeftRight}
          >
            <GridContainer spacing={0}>
              <Head options={options} action={action} record={formRecord} />
              <Body action={action} record={formRecord} />
            </GridContainer>
          </StandardFormik>
        </MainPageProvider.Provider>
      )}
    </>
  )
}

export default MainPageForm
