// Dependencies
import React from "react"
import PropTypes from "prop-types"
// Shared Components
import ProgressBar from "."
// Material Ui
import { Box, Typography } from "@material-ui/core"

function ProgressBarWithLabel({ value, ...rest }) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <ProgressBar value={value} {...rest} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          value
        )}%`}</Typography>
      </Box>
    </Box>
  )
}

export default ProgressBarWithLabel

ProgressBarWithLabel.propTypes = {
  control: PropTypes.string,
  variant: PropTypes.string,
  value: PropTypes.number.isRequired,
}

ProgressBarWithLabel.defaultProps = {
  control: "linear",
  variant: "determinate",
}
