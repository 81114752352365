import localization from "core/localization/index"

localization.extend("en", {
  product: "product",
  product_: "Product",
  products: "Products",
  position: "Position",
  position_: "Position",
  positions: "Positions",
  importance: "Importance",
  expiry_date: "Expiry Date",
  expiry_date_: "Expiry Date",
  box_quantity: "Box Quantity",
  productsList: "Products List",
  productDetail: "product detail",
  positionsList: "Positions List",
  available_quantity: "Available Quantity",
  productAdded: "Product added successfully",
  positionAdded: "Position added successfully",
  productUpdated: "Product updated successfully",
  positionUpdated: "Position updated successfully",
})
