// Core Files
import MainPageForm from "core/formik/MainPageForm"
import SetUpSyncForm from "modules/Updates/helpers/SetUpSyncForm"
// Shared Components
import DateFormatter from "tables/Formatters/DateFormatter"
import SyncService from "modules/Updates/services/SyncService"
import { updatedAt } from "tables/TableComponents/TableDefaultItems/TimeStamps"
import masterTableActions from "tables/TableComponents/TableActions/MasterActions"
import tableCheckBoxAll from "tables/TableComponents/TableDefaultItems/CheckBoxAll"

export default {
  type: "Page",
  title: "_sync",
  service: SyncService,
  tabOptions: {
    tabs: [{ label: "details", value: MainPageForm }],
  },
  formOptions: {
    modalLable: "sync",
    formikLable: "sync_",
    setUpForm: SetUpSyncForm,
  },
  table: {
    orderBy: "created_at",
    heading: "syncList",
    columns: [
      tableCheckBoxAll,
      // { key: "id", heading: "#", filter: false },
      { key: "target_table", heading: "target_table", allowfilter: false },
      { key: "action", heading: "action", allowfilter: false },
      { key: "status", heading: "status", allowfilter: false },
      {
        center: true,
        filter: false,
        key: "salespersons_count",
        heading: "salespersons_count",
      },
      {
        filter: false,
        key: "created_at",
        heading: "created_at",
        formatter: DateFormatter,
      },
      updatedAt,
      masterTableActions(["delete", "view", "edit"]),
    ],
  },
}
