import localization from "core/localization/index"

localization.extend("en", {
  balance: "Balance",
  customer: "customer",
  customer_: "Customer",
  customer__: "Customer",
  customers: "Customers",
  last_visit: "Last Visit",
  credit_limit: "Credit Limit",
  customersList: "Customers List",
  last_order_date: "Last order date",
  last_payment_date: "Last payment date",
  customerAdded: "Customer added successfully",
  customerUpdated: "Customer updated successfully",
  bisanCustomerSearch: "Looking for bisan customer",
  post_dated_checks_total: "Post dated checks total",
  bisan_customerWillBeSynced: "Customer Will Be Sync With Bisan Data",
})
