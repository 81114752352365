// Dependencies
import React from "react"
// Core Files & Hooks
import useOnce from "./useOnce"
import { lastRequest } from "core/RestfulApi/endpoint"

export default function useRequest(promiseFunction) {
  const [state, setState] = React.useState({
    value: null,
    error: null,
    isLoaded: false,
    isLoading: false,
  })
  useOnce(() => {
    setState({
      ...state,
      isLoading: true,
    })

    promiseFunction()
      .then((response) => {
        setState({
          value: response,
          error: null,
          isLoaded: true,
          isLoading: false,
        })
      })
      .catch((response) => {
        if (response.__CANCEL__ === true) return

        setState({
          value: null,
          error: response,
          isLoaded: true,
          isLoading: false,
        })
      })
    let request
    setTimeout(() => {
      request = lastRequest()
    }, 0)
    return () => {
      request.abort()
    }
  })
  return [state.value, state.error, state.isLoading]
}
