const { makeStyles } = require("@material-ui/core")
const useInputStyles = makeStyles((theme) => ({
  root: {
    width: "25rem",
  },
  margin: {
    margin: theme.spacing(1),
  },
  adornmentRight: {
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
      height: "45px",
    },
  },
  startAdornmentIcon: {
    marginRight: theme.spacing(1),
  },
  endAdornmentIcon: {
    marginLeft: theme.spacing(1),
  },
}))

export default useInputStyles
