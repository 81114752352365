// Dependencies
import React from "react"
//Core Files & Hooks
import useTable from "tables/hooks/useTable"
import Link from "core/Routes/components/Link"
import { trans } from "core/localization/index"
import useRowProvider from "tables/hooks/useRowProvider"
// Shared Components
import Tooltip from "materialui/ToolTip/MasterToolTip"
// Material Ui + Styles
import { IconButton } from "@material-ui/core"
import PrintIcon from "@material-ui/icons/Print"

function ToolBarPrinterButton() {
  const { record = {} } = useRowProvider()
  const { options, tableRecordsObj, tableSelectedRow } = useTable()

  const { selected } = tableSelectedRow
  const { tableRecords } = tableRecordsObj

  const href = options.hasOwnProperty("printerOption")
    ? options.printerOption.route
    : null
  const orders = tableRecords.filter((record) => selected.includes(record.id))

  const handleClick = () => {
    const req = orders.length > 0 ? orders : [record]
    return { pathname: href, orders: req }
  }
  // { pathname: href, orders }
  return (
    <>
      <Link to={handleClick}>
        <IconButton color="primary">
          <Tooltip title={trans("print")}>
            <PrintIcon />
          </Tooltip>
        </IconButton>
      </Link>
      {/* <ComponentToPrint ref={componentRef} /> */}
    </>
  )
}

export default ToolBarPrinterButton
