import { trans } from "core/localization/index"

/**
 * Set meta data of the page
 *
 * @param {string} metaData
 */
export function setMetaData(key, value) {
  switch (key) {
    case "title":
      document.title = trans(value)
      break
    case "description":
      document.getElementById("meta-description").content = value
      break
    default:
      break
  }
}
