import localization from "core/localization/index"

localization.extend("ar", {
  debit: "ألدين",
  target: "ألهدف",
  salary: "ألراتب",
  company: "ألشركة",
  employee_: "موظف",
  employee__: "ألموظف",
  salesperson_: "مندوب",
  employees: "ألموظفين",
  employee: "بيانات موظف",
  job_title_: "مسمى وظيفي",
  hireDate: "تاريخ ألتوظيف",
  job_title: "ألمسمى ألوظيفي",
  salesperson: "مندوب ألمبيعات",
  vacation_days: "أيام الإجازات",
  employeesList: "قائمة ألموظفين",
  job_titles: "ألمسميات ألوظيفية",
  employeeAdded: "تم تعديل الموظف بنجاح",
  job_titlesList: "قائمة ألمسميات ألوظيفية",
  job_title_arabic: "ألمسمى ألوظيفي بالعربي",
  employeeUpdated: "تم إضافة موظف جديد بنجاح",
  job_titleAdded: "تم تعديل المسمى وظيفي بنجاح",
  job_titleUpdated: "تم إضافة مسمى وظيفي جديد بنجاح",
})
