// Dependencies
import React from "react"
import * as Yup from "yup"
import Is from "@flk/supportive-is"
import { Form, Formik } from "formik"
// Core Files
import FormikControl from "core/formik/FormikControl"
import FormikProvider from "core/formik/providers/FormikProvider"
import ProductService from "modules/Products/services/ProductService"
import { getDefautlValue } from "core/formik/helpers/standardFormikHelper"
// Material Ui
import { Typography } from "@material-ui/core"
import AlertMessage from "shared/components/Alert"
import { trans } from "core/localization"

function OrderDetailForm(props) {
  const {
    action,
    setOpen,
    reference,
    recordObj = {},
    setTableRecords,
    setIsSubmitting,
    alreadyExistRecords,
  } = props

  const { record = {}, index } = recordObj
  let defaultValue = getDefautlValue(record, "product_id")

  const [total, setTotal] = React.useState(!Is.empty(record) ? record.total : 0)
  const [quantity, setQuantity] = React.useState(
    !Is.empty(record) ? record.quantity : "1"
  )
  const [discount, setDiscount] = React.useState(
    !Is.empty(record) ? `${record.product_discount_percent}` : "0"
  )
  const [price, setPrice] = React.useState(
    !Is.empty(record)
      ? record.product_id !== null
        ? record.product_id.price
        : 0
      : 0
  )

  const [formResult, setFormResult] = React.useState({})
  const [formStatus, setFormStatus] = React.useState("success")

  const initialValues = {
    quantity,
    note: record.note || "",
    bonus: record.bonus || "0",
    product_discount_percent: discount,
    product_id: record.product_id || "",
  }

  const validationSchema = Yup.object({
    note: Yup.string(),
    bonus: Yup.number().min(0),
    quantity: Yup.number().min(0),
    product_discount_percent: Yup.number().min(0).max(100),
    product_id: Yup.string().required("Required").nullable(),
  })

  const onSubmit = (values) => {
    setFormResult([])
    setIsSubmitting(true)
    setFormStatus("success")
    values["total"] = total

    let flag = 0
    if (alreadyExistRecords.includes(values.product_id.id)) {
      if (
        action === "add" ||
        (record.product_id !== null &&
          values.product_id.id !== record.product_id.id)
      ) {
        flag = 1
      }
    } else if (action === "add") {
      setTableRecords((records) => {
        records.push(values)
        return [...records]
      })
      setOpen(false)
    }
    if (flag === 1) {
      // error
      setIsSubmitting(false)
      setFormStatus("error")
      setFormResult({ error: [trans("piac")] })
    } else if (action === "edit") {
      setTableRecords((records) => {
        records[index] = values
        return [...records]
      })
      setOpen(false)
    }
  }

  const calculateTotal = () => {
    const currTotal = quantity * price
    const discountAmount = (currTotal * discount) / 100
    setTotal(currTotal - discountAmount)
  }

  React.useEffect(() => {
    calculateTotal()
    // eslint-disable-next-line
  }, [quantity, discount, price])

  const updateTotal_FPrice = (formik) => {
    const formikPrice =
      formik.values.hasOwnProperty("product_id") &&
      formik.values.product_id !== null
        ? formik.values.product_id.price
        : price
    setPrice(formikPrice)
  }
  const updateTotal_FQuantity = (quantityValue) => {
    setQuantity(quantityValue)
  }
  const updateTotal_FDiscount = (discountValue) => {
    setDiscount(discountValue)
  }

  return (
    <Formik
      onSubmit={onSubmit}
      innerRef={reference}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {(formik) => (
        <FormikProvider.Provider value={formik}>
          <Form noValidate>
            {Object.keys(formResult).length > 0 &&
              Object.keys(formResult).map((state) => {
                return formResult[state].map((result, index) => {
                  return (
                    <AlertMessage
                      key={index}
                      label={result}
                      type={formStatus}
                    />
                  )
                })
              })}
            <FormikControl
              required
              backendV="name"
              label="products"
              name="product_id"
              appenedValues={["price"]}
              control="lazyautocomplete"
              defaultValue={defaultValue}
              onFieldBlur={updateTotal_FPrice}
              request={() =>
                ProductService.getList({
                  _all: true,
                  // _q: 1, // active = 1
                  _get: "id,name,price",
                })
              }
            />
            <FormikControl
              name="quantity"
              control="number"
              label="quantity"
              onFieldBlur={(quantityV) => updateTotal_FQuantity(quantityV)}
            />
            <FormikControl name="bonus" label="bonus" control="number" />
            <FormikControl
              control="number"
              label="discount"
              name="product_discount_percent"
              onFieldBlur={(discountV) => updateTotal_FDiscount(discountV)}
            />
            <FormikControl name="note" label="notes" control="textarea" />
            <Typography noWrap variant="h6" component="div">
              {`Total: ${Number(total).toLocaleString()}`}
            </Typography>
          </Form>
        </FormikProvider.Provider>
      )}
    </Formik>
  )
}

export default OrderDetailForm
