// Dependencies
import React from "react"
import Is from "@flk/supportive-is"
import { Obj } from "reinforcements"
// Core Files & Hooks
import { trans } from "core/localization"
import useTable from "tables/hooks/useTable"
import RowProvider from "tables/providers/RowProvider"
import useMasterTableStyles from "tables/CSS/MasterTableStyle"
import { sortRecords, getComparator } from "tables/helpers/SortHelpers"
// Material Ui
import {
  TableRow,
  TableCell,
  TableBody as MaterialTableBody,
} from "@material-ui/core"

function TableBody({ isLoading }) {
  let tableRows
  const classes = useMasterTableStyles()
  let { tableRecordsObj, tableOrderByObj, tableColumnsObj } = useTable()
  const { tableColumns } = tableColumnsObj
  const { order, orderBy } = tableOrderByObj
  let records = tableRecordsObj.tableRecords

  if (isLoading) {
    tableRows = (
      <TableRow>
        <TableCell className={classes.textCenter} colSpan={tableColumns.length}>
          <h3>{`${trans("loading")}...`}</h3>
        </TableCell>
      </TableRow>
    )
  } else {
    tableRows = sortRecords(records, getComparator(order, orderBy)).map(
      (record, rowIndex) => {
        // if (!record.columnList) {
        // }
        //   // To make sure that each record has it's unique column from the userItem.js for i.e
        record.columnList = Obj.clone(tableColumns)

        const filteredRecrods = record.columnList.filter(
          (column) => !column.filter
        )
        return (
          <TableRow key={record.id}>
            {filteredRecrods.map((column, columnIndex) => {
              // if (column.cell) return column.cell

              column.value = Obj.get(
                column,
                "value",
                Obj.get(record, column.key)
              )

              if (Is.array(column.value) && !column["firstValue"]) {
                let arrResult = column.value.map(
                  (item) => item[column["arrAttribute"]] || item.id
                )
                column.value = arrResult.toString()
              } else if (Is.array(column.value) && column["firstValue"]) {
                if (
                  !Is.empty(column.value) &&
                  column.value[0].hasOwnProperty("image")
                )
                  column.value = column.value[0].image
                else {
                  column.value = null
                }
              } else if (Is.object(column.value)) {
                column.value = column.value[column["arrAttribute"]]
              }

              if (!column.value && column.defaultValue) {
                column.originalValue = column.value
                column.value = column.defaultValue
              }

              const buttonOptions = { record, rowIndex }
              const columnValue = column.formatter ? (
                <RowProvider.Provider value={buttonOptions}>
                  <column.formatter column={column} />
                </RowProvider.Provider>
              ) : (
                column.value
              )
              // <TableCell key={column.heading}>{record[column.key]}</TableCell>
              // Or use Obj in order to get nested objects
              column.cell = (
                <TableCell
                  key={column.heading}
                  className={column.center ? classes.center : null}
                  padding={`${column.key === "checkbox" ? "checkbox" : "none"}`}
                >
                  {columnValue}
                </TableCell>
              )
              return column.cell
            })}
          </TableRow>
        )
      }
    )
  }

  return <MaterialTableBody>{tableRows}</MaterialTableBody>
}

export default TableBody
