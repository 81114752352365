// Core Files
import UserForm from "./UserForm"
import SetUpUserForm from "modules/Users/helpers/SetUpUserForm"
// Shared Components
import {
  createdAt,
  updatedAt,
} from "tables/TableComponents/TableDefaultItems/TimeStamps"
import UserService from "modules/Users/services/UserService"
import EmailFormatter from "tables/Formatters/EmailFormatter"
import masterTableActions from "tables/TableComponents/TableActions/MasterActions"
import tableCheckBoxAll from "tables/TableComponents/TableDefaultItems/CheckBoxAll"
// import BooleanFormatter from "tables/Formatters/BooleanFormatter"

export default {
  type: "Modal",
  title: "users",
  service: UserService,
  formOptions: {
    form: UserForm,
    modalLable: "user",
    formikLable: "user_",
    setUpForm: SetUpUserForm,
  },
  table: {
    orderBy: "id",
    heading: "usersList",
    columns: [
      tableCheckBoxAll,
      { key: "id", heading: "#", filter: false },
      { key: "name", heading: "name", allowfilter: false },
      {
        key: "email",
        filter: false,
        heading: "email",
        formatter: EmailFormatter,
      },
      {
        key: "roles",
        filter: false,
        heading: "roles",
        arrAttribute: "role",
        // formatter: Link,
      },
      // {
      //   filter: false,
      //   key: "active",
      //   heading: "active",
      //   formatter: BooleanFormatter,
      // },
      createdAt,
      updatedAt,
      masterTableActions(["delete", "edit"]),
    ],
  },
}
