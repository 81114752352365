// Dependencies
import React from "react"
// Core Files
import { trans } from "core/localization"
// Material Ui
import EditIcon from "@material-ui/icons/Edit"
import { IconButton, Tooltip } from "@material-ui/core"

export default function EditableTableEditButton(props) {
  const { handleOpenForm, record, recordIndex } = props
  const handleClick = (e) => {
    handleOpenForm(e, "edit", record, recordIndex)
  }
  return (
    <IconButton onClick={handleClick}>
      <Tooltip title={trans("edit")}>
        <EditIcon />
      </Tooltip>
    </IconButton>
  )
}
