// Dependencies
import React from "react"
import * as Yup from "yup"
import { Form, Formik } from "formik"
// Core Files
import FormikControl from "core/formik/FormikControl"
import FormikProvider from "core/formik/providers/FormikProvider"
import PositionService from "modules/Products/services/PositionService"
import {
  getDefautlValue,
  getDatePickerFormatte,
} from "core/formik/helpers/standardFormikHelper"

function ProductDetailForm(props) {
  const {
    action,
    setOpen,
    reference,
    recordObj = {},
    setTableRecords,
    setIsSubmitting,
  } = props

  const { record = {}, index } = recordObj
  let defaultValue = getDefautlValue(record, "position_id")

  let expiry_date = record.expiry_date || ""
  if (
    record.hasOwnProperty("expiry_date") &&
    record.expiry_date.split(" ").length > 1
  ) {
    expiry_date = getDatePickerFormatte(record.expiry_date)
  }

  const initialValues = {
    position_id: record.position_id || "",
    expiry_date: expiry_date,
    available_quantity: record.available_quantity || "",
  }

  const validationSchema = Yup.object({
    position_id: Yup.string().required("Required"),
    expiry_date: Yup.string().required("Required"),
    available_quantity: Yup.string().required("Required"),
  })

  const onSubmit = (values) => {
    setIsSubmitting(true)
    if (action === "add") {
      setTableRecords((records) => {
        records.push(values)
        return [...records]
      })
    } else if (action === "edit") {
      setTableRecords((records) => {
        records[index] = values
        return [...records]
      })
    }
    setOpen(false)
  }

  return (
    <Formik
      onSubmit={onSubmit}
      innerRef={reference}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {(formik) => (
        <FormikProvider.Provider value={formik}>
          <Form noValidate>
            <FormikControl
              required
              control="date"
              name="expiry_date"
              label="expiry_date"
            />
            <FormikControl
              required
              label="positions"
              name="position_id"
              backendV="position"
              control="lazyautocomplete"
              defaultValue={defaultValue}
              request={() => PositionService.list({ _all: true })}
            />
            <FormikControl
              required
              control="number"
              name="available_quantity"
              label="available_quantity"
            />
          </Form>
        </FormikProvider.Provider>
      )}
    </Formik>
  )
}

export default ProductDetailForm
