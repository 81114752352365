// Dependencies
import React from "react"
import { Field } from "formik"
import PropTypes from "prop-types"
// Core Files
import { trans } from "core/localization/index"
import useFormik from "core/formik/hooks/useFormik"
import { isPhoneNumber } from "core/formik/helpers/standardFormikHelper"
import { isNotValid, validateInput } from "materialui/Validation/index"
// Material Ui + Custom Style
import useFormStyles from "materialui/Styles"
import { TextField, FormControl } from "@material-ui/core"

function Number(props) {
  const { values } = useFormik()
  const classes = useFormStyles()
  const { label, name, onFieldBlur, ...rest } = props
  const [value, setValue] = React.useState(values[name] || "")

  return (
    <Field name={name}>
      {({ field, form }) => {
        return (
          <FormControl
            error={isNotValid(form, name)}
            className={classes.formControl}
          >
            <TextField
              id={name}
              {...rest}
              {...field}
              value={value}
              label={trans(label)}
              error={isNotValid(form, name)}
              helperText={validateInput(form, name)}
              onChange={(e) => {
                let cvalue = e.target.value
                if (isPhoneNumber(cvalue) || cvalue === "") setValue(cvalue)
              }}
              onBlur={() => {
                form.setFieldValue(name, value)
                onFieldBlur && onFieldBlur(value)
              }}
            />
          </FormControl>
        )
      }}
    </Field>
  )
}

export default Number

Number.propTypes = {
  type: PropTypes.string,
  value: PropTypes.number,
  onClick: PropTypes.func,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  fullWidth: PropTypes.bool,
  variant: PropTypes.string,
  onFieldBlur: PropTypes.func,
  label: PropTypes.string.isRequired,
}

Number.defaultProps = {
  type: "text",
  readOnly: false,
  required: false,
  fullWidth: true,
  variant: "outlined",
  onFocus: (e) => e.target.select(),
}
