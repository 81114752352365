// Dependencies
import * as Yup from "yup"
import Is from "@flk/supportive-is"

export default class SetUpProductForm {
  constructor(record) {
    let active, newF
    if (Is.undefined(record.active)) active = true
    else if (Is.boolean(record.active)) active = record.active
    else if (Is.string(record.active))
      active = Number(record.active) === 1 ? true : false
    else active = record.active === 1 ? true : false

    if (Is.undefined(record.new)) newF = true
    else if (Is.boolean(record.new)) newF = record.new
    else if (Is.string(record.new))
      newF = Number(record.new) === 1 ? true : false
    else newF = record.new === 1 ? true : false

    this.initialValues = {
      active,
      new: newF,
      id: record.id,
      name: record.name || "",
      code: record.code || "",
      note: record.note || "",
      price: record.price || "",
      weight: record.weight || "",
      barcode: record.barcode || "",
      arabic_name: record.arabic_name || "",
      _categories_id: record.categories || "",
      box_quantity: record.box_quantity || "12",
      alternate_name: record.alternate_name || "",
      product_images: record.product_images || [],
      product_details: record.product_details || [],
    }

    this.validationSchema = Yup.object({
      new: Yup.boolean(),
      note: Yup.string(),
      active: Yup.boolean(),
      arabic_name: Yup.string(),
      alternate_name: Yup.string(),
      name: Yup.string().required("Required"),
      code: Yup.string().required("Required"),
      price: Yup.number().required("Required"),
      weight: Yup.number().required("Required"),
      barcode: Yup.string().required("Required"),
      box_quantity: Yup.number().required("Required"),
      _categories_id: Yup.string().required("Required").nullable(),
    })
  }

  getInitialValues() {
    return this.initialValues
  }
  getValidationSchema() {
    return this.validationSchema
  }
}
