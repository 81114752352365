// Dependencies
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
// Core Files
import { loadData } from "./helpers/LazyTableHelpers"
import { setMetaData } from "core/configuration/metaData"
import TableRequestProvider from "./providers/TableRequestProvider"
// Shared Components
import MasterTable from "./MasterTable"
import MasterLayout from "layout/MasterLayout"

function LazyTable(props) {
  const [tableBody, setTableBody] = useState({})
  const [tableInfo, setTableInfo] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  let { request, options, label, ...rest } = props

  const [controlRequest, setRequest] = React.useState({
    request: (params) => request(params),
    status: "all",
  })

  useEffect(() => {
    setMetaData("title", label)
    controlRequest.request(tableInfo).then((response) => {
      loadData(response.data, setTableBody, setIsLoading)
      if (controlRequest.callbackfun) controlRequest.callbackfun()
    })
    // eslint-disable-next-line
  }, [label, tableInfo])

  const updateTableInfo = (newTableInfo) => {
    setTableInfo(newTableInfo)
    setIsLoading(true)
  }

  const displayContent = (
    <TableRequestProvider.Provider value={{ controlRequest, setRequest }}>
      <MasterTable
        {...rest}
        options={options}
        isLoading={isLoading}
        onChange={updateTableInfo}
        records={tableBody.records}
        pagination={tableBody.pagination}
      />
    </TableRequestProvider.Provider>
  )

  return <MasterLayout label={label}>{displayContent}</MasterLayout>
}

export default LazyTable

LazyTable.propTypes = {
  options: PropTypes.any.isRequired,
}
