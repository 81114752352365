import localization from "core/localization/index"

localization.extend("ar", {
  role_: "صلاحية",
  user_: "مستخدم",
  users: "الأعضاء",
  roles: "ألصلاحيات",
  user__: "المستخدم",
  user: "بيانات عضو",
  login: "تسجيل الدخول",
  role: "بيانات ألصلاحية",
  usersList: "قائمة الأعضاء",
  rolesList: "قائمة ألصلاحيات",
})
