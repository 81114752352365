const { makeStyles } = require("@material-ui/core")

const useMasterTableStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  paper: {
    width: "100%",
    position: "relative",
    marginBottom: theme.spacing(2),
  },
  textCenter: {
    textAlign: "center",
  },
  container: {
    maxHeight: 540,
  },
  center: {
    // textAlign: "center",
    paddingLeft: "40px",
  },
}))

export default useMasterTableStyles
