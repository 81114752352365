import localization from "core/localization/index"

localization.extend("en", {
  debit: "Debit",
  target: "Target",
  salary: "Salary",
  company: "Company",
  employee: "employee",
  employee_: "Employee",
  hireDate: "Hire Date",
  employee__: "Employee",
  employees: "Employees",
  job_title: "job title",
  job_title_: "Job Title",
  job_titles: "Job Titles",
  salesperson: "Salesperson",
  salesperson_: "Salesperson",
  vacation_days: "Vacation Days",
  employeesList: "Employees List",
  job_titlesList: "Job Titles List",
  job_title_arabic: "Job Title In Arabic",
  employeeAdded: "Employee added successfully",
  job_titleAdded: "Job Title added successfully",
  employeeUpdated: "Employee updated successfully",
  job_titleUpdated: "Job Title updated successfully",
})
