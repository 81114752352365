// Dependencies
import React from "react"
import Is from "@flk/supportive-is"
import { useHistory } from "react-router-dom"
// Core Files & Hooks
import FormikControl from "core/formik/FormikControl"
import {
  pageParam,
  getDefautlValue,
} from "core/formik/helpers/standardFormikHelper"
import EmployeeService from "modules/Employees/services/EmployeeService"
import CustomerService from "modules/Customers/services/CustomerService"
import OrderDetailItems from "modules/Orders/components/FormComponents/OrderDetails/OrderDetailItems"
// Shared Components
import SimpleTable from "tables/SimpleTable"
import OrderDetailForm from "./OrderDetails/OrderDetailForm"
import GridItem from "materialui/Grid/GridItem"
import GridContainer from "materialui/Grid/GridContainer"

function BodyOrder({ record }) {
  const history = useHistory()
  let recordId = pageParam(history, 2)
  if (!Is.numeric(recordId)) recordId = 0
  let defaultValueCustomer = getDefautlValue(record, "customer")
  let defaultValueEmployee = getDefautlValue(record, "employee")

  let orderDetialsArr = []
  let authorizedKeys = [
    "note",
    "bonus",
    "total",
    "quantity",
    "product_id",
    "product_discount_amount",
    "product_discount_percent",
  ]

  record &&
    record.order_details.forEach((element) => {
      let newDetail = {}
      for (const [key] of Object.entries(element)) {
        if (authorizedKeys.includes(key)) {
          if (key === "product_id" && element.product != null) {
            const productObj = {
              id: element["product"].id,
              key: element["product"].name,
              price: element["product"].price,
            }
            newDetail[key] = productObj
          } else newDetail[key] = element[key]
        }
      }
      orderDetialsArr.push(newDetail)
    })

  return (
    <GridContainer spacing={0}>
      {/* Row 1 */}
      <GridContainer>
        <GridItem xs={3}>
          <FormikControl required label="code" control="number" name="code" />
        </GridItem>
        <GridItem xs={2}>
          <FormikControl
            control="number"
            name="order_discount"
            label="order_discount"
          />
        </GridItem>
        <GridItem xs={3}>
          <FormikControl
            required
            backendV="name"
            label="salesperson"
            name="_employee_id"
            control="lazyautocomplete"
            defaultValue={defaultValueEmployee}
            request={() => EmployeeService.salespersons()}
          />
        </GridItem>
        <GridItem>
          <FormikControl
            required
            backendV="name"
            label="customer_"
            name="_customer_id"
            control="lazyautocomplete"
            defaultValue={defaultValueCustomer}
            request={() =>
              CustomerService.getList({ _all: true, _q: 1, _get: "id,name" })
            }
          />
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12}>
          <SimpleTable
            options={OrderDetailItems}
            modalTComponent="orderDetail"
            FormContent={OrderDetailForm}
            records={record ? orderDetialsArr : []}
          />
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12}>
          <FormikControl name="note" label="notes" control="textarea" />
        </GridItem>
      </GridContainer>
    </GridContainer>
  )
}

export default BodyOrder
