import React from "react"
import BaseTooltip from "@material-ui/core/Tooltip"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
  tooltip: {
    backgroundColor: "#000",
  },
})

function Tooltip(props) {
  const classes = useStyles()
  return (
    <BaseTooltip
      {...props}
      placement={props.placement || "top"}
      classes={{ tooltip: classes.tooltip }}
    />
  )
}

export default Tooltip
