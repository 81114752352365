import Is from "@flk/supportive-is"

function descendingComparator(a, b, orderBy) {
  let a1 = a[orderBy]
  let b1 = b[orderBy]
  if (Is.string(a1)) a1 = a1.toLowerCase()
  if (Is.string(b1)) b1 = b1.toLowerCase()

  if (b1 < a1) return -1
  if (b1 > a1) return 1
  return 0
}

export function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

export function sortRecords(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}
