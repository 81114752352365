// Dependencies
import clsx from "clsx"
import React from "react"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"
// Core Files
import { trans } from "core/localization"
import { routeOnly } from "core/Routes/Navigation/navigator"
// Shared Components
import SideBarListItem from "./ListItem"
// Material Ui & Styles
import {
  List,
  ListItem,
  Collapse,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core"
import ExpandMore from "@material-ui/icons/ExpandMore"
import ExpandLess from "@material-ui/icons/ExpandLess"
import useMasterLayoutStyles from "shared/styles/MasterLayoutStyles"

function SideBarListItemGroup(props) {
  const classes = useMasterLayoutStyles()
  let { text, icon, items, nested } = props

  let active
  let expandGroup = false
  const currentRoute = routeOnly(useHistory())
  const itemsList = items.map((item, index) => {
    active = currentRoute === item.route
    if (active) expandGroup = true
    return (
      <SideBarListItem
        key={index}
        nested={nested}
        text={item.text}
        icon={item.icon}
        route={item.route}
      />
    )
  })

  const activeText = clsx({
    [classes.sideBarActive]: active === true,
  })

  const [open, setOpen] = React.useState(expandGroup)
  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemIcon className={classes.sideBarIcon}>{icon}</ListItemIcon>
        <ListItemText primary={trans(text)} classes={{ root: activeText }} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {itemsList}
        </List>
      </Collapse>
    </>
  )
}

export default SideBarListItemGroup

SideBarListItemGroup.propTypes = {
  items: PropTypes.array,
  icon: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
}

SideBarListItemGroup.defaultProps = {
  items: [],
}
