// Core Files
import MainPageForm from "core/formik/MainPageForm"
import SetUpOrderForm from "modules/Orders/helpers/SetUpOrderForm"
// Shared Components
import DateFormatter from "tables/Formatters/DateFormatter"
import NumberFormatter from "tables/Formatters/NumberFormatter"
import StatusFormatter from "tables/Formatters/StatusFormatter"
import OrderService from "modules/Orders/services/OrderService"
import EmployeeService from "modules/Employees/services/EmployeeService"
import { updatedAt } from "tables/TableComponents/TableDefaultItems/TimeStamps"
import masterTableActions from "tables/TableComponents/TableActions/MasterActions"
import tableCheckBoxAll from "tables/TableComponents/TableDefaultItems/CheckBoxAll"
import { FilterOptionComponent } from "tables/TableComponents/TableActions/Helpers/OptionsContent"

export default {
  type: "Page", // 'Modal' Or 'Page' for adding & editing
  title: "orders",
  service: OrderService,
  tabOptions: {
    tabs: [{ label: "details", value: MainPageForm }],
  },
  formOptions: {
    modalLable: "order",
    formikLable: "order_",
    setUpForm: SetUpOrderForm,
  },
  table: {
    // orderBy: "created_at",
    heading: "ordersList",
    columns: [
      tableCheckBoxAll,
      // { key: "id", heading: "#", filter: false },
      { key: "code", heading: "code", filter: true },
      { key: "employee.name", heading: "employee__", filter: false },
      { key: "customer.name", heading: "customer__", filter: false },
      {
        center: true,
        filter: false,
        key: "products_count",
        heading: "products_count",
      },
      {
        filter: false,
        heading: "price",
        key: "total_price",
        formatter: NumberFormatter,
      },
      {
        filter: true,
        center: false,
        heading: "discount",
        key: "order_discount",
        formatter: NumberFormatter,
      },
      {
        filter: false,
        key: "status",
        heading: "status",
        formatter: StatusFormatter,
        status: ["not_ready", "ready", "canceled", "submitted"],
      },
      { key: "note", heading: "notes", filter: true },
      {
        filter: false,
        key: "created_at",
        heading: "created_at",
        formatter: DateFormatter,
      },
      updatedAt,
      masterTableActions(["delete", "edit", "print"]),
    ],
  },
  printerOption: {
    route: "/ordersPrintPreview",
  },
  advancedOptions: [
    {
      component: FilterOptionComponent,
      configuration: {
        list: {
          checked: "",
          key: "status",
          label: "status",
          options: [
            { key: "ready", value: "ready" },
            { key: "not_ready", value: "not_ready" },
            { key: "canceled", value: "canceled" },
            { key: "submitted", value: "submitted" },
          ],
        },
        dateFromTo: {
          key: "created_at",
          props: { minDate: null, maxDate: null },
          values: { empty: true, startDate: "", endDate: "" },
          inputLabel: ["date_from_to", "date", "order_", "from_to"],
        },
        salespersons: {
          checked: "",
          key: "salesperson",
          label: "salespersons",
          request: () => EmployeeService.salespersons(),
        },
      },
    },
  ],
}
