// Dependencies
import * as Yup from "yup"

export default class SetUpPositionForm {
  constructor(record) {
    this.initialValues = {
      id: record.id,
      position: record.position || "",
      importance: record.importance || "C",
    }

    this.validationSchema = Yup.object({
      position: Yup.string().required("Required"),
      importance: Yup.string().required("Required").nullable(),
    })
  }

  getInitialValues() {
    return this.initialValues
  }
  getValidationSchema() {
    return this.validationSchema
  }
}
