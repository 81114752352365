// Dependencies
import React from "react"
// Core Files & Hooks
import { trans } from "core/localization"
import useFormik from "core/formik/hooks/useFormik"
import FormikControl from "core/formik/FormikControl"
import useMainPage from "core/formik/hooks/useMainPage"
import {
  pageInfo,
  getDatePickerFormatte,
} from "core/formik/helpers/standardFormikHelper"
// Shared Components
import GridItem from "materialui/Grid/GridItem"
import GridContainer from "materialui/Grid/GridContainer"
import AutoComplete from "materialui/AutoComplete/AutoComplete"
// Material Ui
import { Typography } from "@material-ui/core"

export default function HeadOrder({ options, action, record }) {
  const pageTitleFull = pageInfo(options, action)
  const { mainPageState } = useMainPage()
  const { isSubmitting, handleSubmit, setFieldValue } = useFormik()

  const orderStatus = ["ready", "not_ready", "canceled", "submitted"]
  const defaultValue = record
    ? record.hasOwnProperty("status")
      ? record.status
      : ""
    : ""

  const handleOnSubmit = () => {
    let total_price = 0
    let { tableRecords } = mainPageState
    let enhancedTableRecords = []
    tableRecords.forEach((element) => {
      let newEnhancedObj = {}
      total_price += element.total
      for (const [key] of Object.entries(element)) {
        if (key === "columnList" || key === "total") continue
        if (key === "product_id" && element[key] !== null)
          newEnhancedObj[key] = element[key].id
        else newEnhancedObj[key] = element[key]
      }
      enhancedTableRecords.push(newEnhancedObj)
    })

    setFieldValue("total_price", total_price)
    setFieldValue("date", getDatePickerFormatte())
    setFieldValue("order_details", enhancedTableRecords)
    handleSubmit()
  }

  return (
    <GridContainer spacing={0}>
      <GridItem xs={7}>
        <Typography noWrap variant="h6" component="div">
          {pageTitleFull}
        </Typography>
      </GridItem>
      <GridItem xs={3}>
        <AutoComplete
          required
          name="status"
          label="status"
          options={orderStatus}
          setFieldValue={setFieldValue}
          defaultValues={defaultValue || "not_ready"}
        />
      </GridItem>
      <GridItem xs={2} alignItems="right">
        <FormikControl
          control="button"
          name="buttonlogin"
          disabled={isSubmitting}
          onClick={handleOnSubmit}
          label={isSubmitting ? "waiting" : trans("save")}
        />
      </GridItem>
    </GridContainer>
  )
}
