// Dependencies
import React from "react"
import PropTypes from "prop-types"
// Core Files
import { trans } from "core/localization"
// Shared Components
import StaticButton from "materialui/Button/Button"
import ImportImages from "tables/TableComponents/FileComponents/ImportImages"
import { ButtonText } from "modules/Orders/components/PrintPreviewOrders/OrdersToPrint"

function ImportImagesButton({ heading, Icon }) {
  const [imageConfirm, setImageConfirm] = React.useState(false)
  return (
    <>
      <StaticButton onClick={() => setImageConfirm(true)}>
        <ButtonText>{trans(heading)}</ButtonText>
        {Icon && <Icon />}
      </StaticButton>
      <ImportImages confirming={imageConfirm} setConfirm={setImageConfirm} />
    </>
  )
}

ImportImagesButton.propTypes = {
  Icon: PropTypes.object,
  heading: PropTypes.string.isRequired,
}

export default ImportImagesButton
