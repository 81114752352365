// Dependencies
import React from "react"
import Is from "@flk/supportive-is"
import { useHistory } from "react-router-dom"
// Core Files
import FormikControl from "core/formik/FormikControl"
import CategoryService from "modules/Categories/services/CategoryService"
import {
  getDefautlValue,
  pageParam,
} from "core/formik/helpers/standardFormikHelper"
// Shared Components
import GridItem from "materialui/Grid/GridItem"
import GridContainer from "materialui/Grid/GridContainer"

function BodyCustomer({ record }) {
  const history = useHistory()
  let defaultValue = getDefautlValue(record, "parent")
  let recordId = pageParam(history, 2)
  if (!Is.numeric(recordId)) recordId = 0

  return (
    <GridContainer spacing={0}>
      {/* Row 1 */}
      <GridContainer>
        <GridItem>
          <FormikControl required label="code" control="number" name="code" />
        </GridItem>
        <GridItem>
          <FormikControl
            required
            name="value"
            control="number"
            label="category_value"
          />
        </GridItem>
        <GridItem>
          <FormikControl
            name="_parent"
            backendV="name"
            label="category_parent"
            control="lazyautocomplete"
            defaultValue={defaultValue}
            defaultOption={{ id: 0, key: "Root" }}
            request={() => CategoryService.upperCategories({ id: recordId })}
          />
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12}>
          <FormikControl required name="name" label="name" control="input" />
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={6}>
          <FormikControl
            control="input"
            name="arabic_name"
            label="arabic_name"
          />
        </GridItem>
        <GridItem xs={6}>
          <FormikControl
            control="input"
            name="alternate_name"
            label="alternate_name"
          />
        </GridItem>
      </GridContainer>
    </GridContainer>
  )
}

export default BodyCustomer
