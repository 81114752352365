// Dependencies
import React from "react"
import PropTypes from "prop-types"
// Material Ui
import { Grid } from "@material-ui/core"

function GridContainer(props) {
  const { spacing, justify, direction, alignItems } = props
  return (
    <Grid
      container
      spacing={spacing}
      justify={justify}
      direction={direction}
      alignItems={alignItems}
    >
      {props.children}
    </Grid>
  )
}

export default GridContainer

GridContainer.propTypes = {
  spacing: PropTypes.number,
  justify: PropTypes.string,
  direction: PropTypes.string,
  alignItems: PropTypes.string,
}

GridContainer.defaultProps = {
  spacing: 2,
  direction: "row",
  justify: "center",
  alignItems: "center",
}
