// Dependencies
import React from "react"
import { Field } from "formik"
import PropTypes from "prop-types"
// Shared Component
import StaticButton from "materialui/Button/Button"
import {
  FileInputWrapper,
  FileButtonWrapper,
  FileButtonText,
} from "shared/components/File/FileInputHelper"
import { validateInput, isNotValid } from "materialui/Validation/index"
// Material Ui
import useFormStyles from "materialui/Styles"
import CloudUploadIcon from "@material-ui/icons/CloudUpload"
import { FormControl, FormLabel, FormHelperText } from "@material-ui/core"

function FileInputFormik(props) {
  const classes = useFormStyles()
  const fileInputRef = React.useRef()
  const { label, buttonText, buttonIcon, name, required } = props
  const [fielSelected, setFileSelected] = React.useState(buttonText)
  const buttonStyle = { height: "42px" }

  const openFileDialog = (e) => {
    fileInputRef.current.click()
  }

  const onFileSelection = (e) => {
    const selectedFile = e.target.files[0].name
    setFileSelected(selectedFile)
  }

  return (
    <Field name={name}>
      {({ field, form }) => {
        return (
          <FileInputWrapper>
            <FormControl
              required={required}
              error={isNotValid(form, name)}
              className={classes.formControl}
            >
              <FormLabel htmlFor="fileinput">{label}</FormLabel>
              <FileButtonWrapper>
                <StaticButton
                  name="fileinput"
                  onClick={openFileDialog}
                  classNameStyle={buttonStyle}
                >
                  {buttonIcon}
                  <FileButtonText>{fielSelected}</FileButtonText>
                </StaticButton>
              </FileButtonWrapper>
              <input
                name={name}
                type="file"
                ref={fileInputRef}
                onChange={(e) => {
                  onFileSelection(e)
                  form.setFieldValue(name, e.target.files[0].name)
                }}
                style={{ display: "none" }}
              />
              <FormHelperText>{validateInput(form, name)}</FormHelperText>
            </FormControl>
          </FileInputWrapper>
        )
      }}
    </Field>
  )
}

export default FileInputFormik

FileInputFormik.propTypes = {
  required: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonIcon: PropTypes.node.isRequired,
}

FileInputFormik.defaultProps = {
  required: false,
  buttonText: "Select File",
  buttonIcon: <CloudUploadIcon />,
}
