// import React, { useEffect } from "react"
// import { setMetaData } from "core/configuration/metaData"
// import * as Yup from "yup"
// import { Formik, Form } from "formik"
// import FormikControl from "core/formik/FormikControl"
// import Favorite from "@material-ui/icons/Favorite"
// import FavoriteBorder from "@material-ui/icons/FavoriteBorder"
// import {
//   RedBadge,
//   OrangeBadge,
//   GreenBadge,
//   BlueBadge,
//   WhiteBadge,
//   GreyBadge,
//   YellowBadge,
//   BlackBadge,
//   DarkBadge,
// } from "materialui/Badge/MasterBadge"
// import SwitchButton from "materialui/Button/SwitchButton"
// // import FileInput from "shared/components/File/FileInput"
// import AcUnitIcon from "@material-ui/icons/AcUnit"
// import { FormGroup, makeStyles } from "@material-ui/core"
// import ColoredIcon from "shared/components/Icon/ColoredIcon"
// import { green } from "@material-ui/core/colors"
// // import AutoComplete from "materialui/AutoComplete/AutoComplete"
// // import CustomIcon from "shared/components/Icon/CustomIcon"
// import { ReactComponent as Roles } from "shared/components/Icon/Roles/roles.svg"
// import ImageInput from "shared/components/Image/ImageInput"
// // import Carosuel from "materialui/Carousel"
// // import LazyAutoCompleteFormik from "materialui/AutoComplete/LazyAutoCompleteFormik"
// // import RoleService from "../Users/services/RoleService"
// // import UserService from "../Users/services/UserService"
// // import LazyAutoComplete from "materialui/AutoComplete/LazyAutoComplete"

// const useStyles = makeStyles((theme) => ({
//   gTag: {
//     "& g": {
//       fill: "blue",
//     },
//     width: "24px",
//     height: "24px",
//   },
// }))

// function TestForm() {
//   const classes = useStyles()
//   // const dropdownOptions = [
//   //   { key: "Select an option", value: "" },
//   //   { key: "Option 1", value: "option1" },
//   //   { key: "Option 2", value: "option2" },
//   //   { key: "Option 3", value: "option3" },
//   //   { key: "Option 4", value: "option4" },
//   //   { key: "Option 5", value: "option5" },
//   //   { key: "Option 6", value: "option6" },
//   //   { key: "Option 7", value: "option7" },
//   //   { key: "Option 8", value: "option8" },
//   //   { key: "Option 9", value: "option9" },
//   //   { key: "Option 10", value: "option10" },
//   //   { key: "Option 11", value: "option11" },
//   //   { key: "Option 12", value: "option12" },
//   // ]

//   // size => meduim/small
//   // color => default/primary/secondary
//   const radioOptions = [
//     { key: "Option 1", value: "rOption1", color: "primary" },
//     { key: "Option 2", value: "rOption2", color: "default", size: "small" },
//     { key: "Option 3", value: "roption3", disabled: true },
//     { key: "Option 4", value: "rOption4" },
//   ]

//   const checkboxOptions = [
//     {
//       key: "Option 1",
//       value: "cOption1",
//       icon: <FavoriteBorder />,
//       checkedIcon: <Favorite />,
//       color: "primary",
//     },
//     { key: "Option 2", value: "cOption2", checkedIcon: <FavoriteBorder /> },
//     { key: "Option 3", value: "cOption3", color: "default" },
//     { key: "Option 4", value: "coption4", size: "small" },
//   ]

//   useEffect(() => {
//     setMetaData("title", "dashboard")
//   }, [])

//   const initialValues = {
//     comments: "",
//     selectOption: [""],
//     radioOption: "",
//     checkboxOption: [],
//     // birthDate: null,
//     fileinput: "",
//   }

//   const validationSchema = Yup.object({
//     comments: Yup.string().required("Required"),
//     selectOption: Yup.string().required("Required"),
//     radioOption: Yup.string().required("Required"),
//     checkboxOption: Yup.array().required("Required"),
//     // birthDate: Yup.date().required("Required").nullable(),
//     fileinput: Yup.string().required("Required"),
//   })
//   return (
//     <Formik
//       initialValues={initialValues}
//       validationSchema={validationSchema}
//       onSubmit={(values) => console.log(values)}
//     >
//       {(formik) => {
//         return (
//           <Form>
//             <FormikControl name="comments" label={"notes"} control="textarea" />
//             {/* <FormikControl
//             multiple
//             label="gender"
//             name="selectOption"
//             options={dropdownOptions}
//             control="select"
//           /> */}
//             <FormikControl
//               row
//               label="country"
//               name="radioOption"
//               options={radioOptions}
//               control="radiogroupbuttons"
//             />
//             <FormikControl
//               row
//               label="country"
//               name="checkboxOption"
//               options={checkboxOptions}
//               control="checkboxgroupbuttons"
//             />
//             {/* <FormikControl label="birthDate" control="date" name="datepicker" /> */}
//             <FormikControl
//               required
//               control="file"
//               name="fileinput"
//               label="Attatchment"
//             />
//             {/* <LazyAutoCompleteFormik
//               label="roles"
//               name="selectOption"
//               request={() => RoleService.list()}
//             /> */}
//             {/* <LazyAutoComplete
//               multiple
//               label="Lazy AutoComplete Demo"
//               request={() => UserService.list()}
//             /> */}
//             {/* <AutoComplete
//               multiple
//               label="country"
//               name="selectOption"
//               options={dropdownOptions}
//               // values={[{ key: "option1", value: "option1" }]}
//               setFieldValue={formik.setFieldValue}
//             /> */}
//             <FormikControl
//               fullWidth
//               type="submit"
//               label="Submit"
//               color="primary"
//               control="button"
//               variant="contained"
//               name="testbuttonSubmit"
//             />
//             <ColoredIcon color={green[200]} icon={AcUnitIcon} />
//             {/* <FileInput label="Attatchment" /> */}
//             <FormGroup row>
//               <RedBadge children="Red Badge" />
//               <OrangeBadge children="Red Badge" />
//               <GreenBadge children="Red Badge" />
//               <BlueBadge children="Red Badge" />
//               <WhiteBadge children="Red Badge" />
//               <BlackBadge children="Red Badge" />
//               <GreyBadge children="Red Badge" />
//               <YellowBadge children="Red Badge" />
//               <DarkBadge children="Red Badge" />
//             </FormGroup>
//             <SwitchButton
//               checked
//               readOnly
//               label="Site Statues"
//               onChange={(value) => console.log(value)}
//             />
//             {/* <CustomIcon iconPath={roles} /> */}
//             <Roles className={classes.gTag} />
//             {/* <Carosuel /> */}
//             <ImageInput />
//           </Form>
//         )
//       }}
//     </Formik>
//   )
// }

// export default TestForm

import React from "react"
import DateRange from "materialui/DateRange"

function TestForm() {
  const [open, setOpen] = React.useState(false)
  const [dateRange, setDateRange] = React.useState({
    startDate: null,
    endDate: null,
  })

  const handleOnChange = (range) => {
    setDateRange(range)
  }
  return (
    <>
      <button onClick={() => setOpen(true)}>Toggle</button>
      <DateRange
        open={open}
        onChange={handleOnChange}
        initialDateRange={dateRange}
        closeModal={() => setOpen(false)}
      />
    </>
  )
}

export default TestForm
