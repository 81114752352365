// Dependencies
import React from "react"
import PropTypes from "prop-types"
import Is from "@flk/supportive-is"
// Material Ui
import {
  makeStyles,
  Typography,
  AccordionDetails,
  AccordionSummary,
  Accordion as MUAccordion,
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}))

function Accordion({ title, messages }) {
  const classes = useStyles()
  if (Is.string(messages)) messages = [messages]

  return (
    <MUAccordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.heading}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {Is.array(messages)
          ? messages.map((msg, index) => (
              <Typography key={index}>{msg}</Typography>
            ))
          : Object.keys(messages).map((key, index) => (
              <Typography key={index}>{`${key}: ${messages[key]}`}</Typography>
            ))}
      </AccordionDetails>
    </MUAccordion>
  )
}

export default Accordion

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  messages: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
}
