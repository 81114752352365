// Dependencies
import * as Yup from "yup"
import Is from "@flk/supportive-is"

export default class SetUpEmployeeForm {
  constructor(record, action) {
    let active
    if (Is.undefined(record.active)) active = true
    else if (Is.boolean(record.active)) active = record.active
    else if (Is.string(record.active))
      active = Number(record.active) === 1 ? true : false
    else active = record.active === 1 ? true : false

    if (record.hire_date)
      record["hire_date"] = record["hire_date"].split(" ")[0]
    if (record.birth_date)
      record["birth_date"] = record["birth_date"].split(" ")[0]

    this.initialValues = {
      active,
      fax: record.fax || "",
      name: record.name || "",
      city: record.city || "",
      note: record.note || "",
      phone: record.phone || "",
      debit: record.debit || "",
      target: record.target || "",
      country: record.country || "",
      salary: record.salary || "2500",
      hire_date: record.hire_date || "",
      birth_date: record.birth_date || "",
      _job_title_id: record.job_title_id || "",
      vacation_days: record.vacation_days || "11",
    }

    this.validationSchema = Yup.object({
      fax: Yup.string(),
      city: Yup.string(),
      note: Yup.string(),
      phone: Yup.string(),
      debit: Yup.string(),
      target: Yup.string(),
      country: Yup.string(),
      active: Yup.boolean(),
      hire_date: Yup.date().nullable(),
      birth_date: Yup.date().nullable(),
      name: Yup.string().required("Required"),
      salary: Yup.number().min(2500).required("Required"),
      vacation_days: Yup.number().min(11).required("Required"),
      _job_title_id: Yup.string().required("Required").nullable(),
    })
  }

  getInitialValues() {
    return this.initialValues
  }
  getValidationSchema() {
    return this.validationSchema
  }
}
