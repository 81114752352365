// Dependencies
import React from "react"
// Shared Components
import Helmet from "shared/components/Helmet"
// Layout & Forms
import TestForm from "./testForm"
import AdminMasterLayout from "layout/AdminMasterLayout"

function App(props) {
  return (
    <AdminMasterLayout label="dashboard">
      <Helmet title="dashboard" />
      <TestForm />
    </AdminMasterLayout>
  )
}

export default App
