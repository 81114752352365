import { makeStyles } from "@material-ui/core"

const useMasterContentStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  tabContainer: {
    padding: theme.spacing(0, 0, 3, 0),
  },
}))

export default useMasterContentStyles
