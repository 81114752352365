// Shared Components
import OrderDetailForm from "./OrderDetailForm"
import NumberFormatter from "tables/Formatters/NumberFormatter"
import ButtonsFormatter from "tables/Formatters/ButtonsFormatter"
import Edit from "tables/EditableTableComponents/TableActions/Edit"
import Delete from "tables/EditableTableComponents/TableActions/Delete"
import SetUpOrderDetailForm from "modules/Orders/helpers/SetUpOrderDetailForm"

export default {
  type: "Modal",
  title: "orderDetail",
  formOptions: {
    form: OrderDetailForm,
    exclude: "product_id.id",
    modalLable: "orderDetail",
    formikLable: "orderDetail_",
    deleteKey: "product_id.key",
    setUpForm: SetUpOrderDetailForm,
  },
  table: {
    orderBy: "product",
    heading: "orderDetailsList",
    columns: [
      {
        key: "actions",
        heading: "actions",
        buttons: [Delete, Edit],
        formatter: ButtonsFormatter,
      },
      {
        key: "product_id",
        heading: "product_",
      },
      {
        key: "quantity",
        heading: "quantity",
        formatter: NumberFormatter,
      },
      {
        key: "bonus",
        heading: "bonus",
        formatter: NumberFormatter,
      },
      {
        key: "discount",
        heading: "discount",
        formatter: NumberFormatter,
      },
      {
        key: "total",
        heading: "total",
        formatter: NumberFormatter,
      },
    ],
  },
}
