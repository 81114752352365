// Dependencies
import React from "react"
import { Field } from "formik"
import PropTypes from "prop-types"
// Core Files
import { trans } from "core/localization/index"
import { validateInput, isNotValid } from "../Validation/index"
// Shared Components
import CheckBoxButton from "./CheckBoxButtonFormik"
// Material Ui + Custom Style
import useFormStyles from "../Styles"
import {
  FormLabel,
  FormGroup,
  FormControl,
  FormHelperText,
  FormControlLabel,
} from "@material-ui/core"

function CheckBoxGroup(props) {
  const classes = useFormStyles()
  const { label, name, value, row, required, options, ...rest } = props

  const displayCheckBoxButtons = (field) => {
    return options.map((option) => {
      let { key, value, ...rest } = option
      return (
        <FormControlLabel
          key={key}
          label={key}
          control={<CheckBoxButton {...rest} field={field} value={value} />}
        />
      )
    })
  }

  return (
    <Field name={name} {...rest}>
      {({ field, form }) => {
        return (
          <FormControl
            required={required}
            component="fieldset"
            error={isNotValid(form, name)}
            className={classes.formControl}
          >
            <FormLabel component="legend">{trans(label)}</FormLabel>
            <FormGroup row={row}>{displayCheckBoxButtons(field)}</FormGroup>
            <FormHelperText>{validateInput(form, name)}</FormHelperText>
          </FormControl>
        )
      }}
    </Field>
  )
}

export default CheckBoxGroup

CheckBoxGroup.propTypes = {
  row: PropTypes.bool,
  value: PropTypes.string,
  required: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
}

CheckBoxGroup.defaultProps = {
  value: "",
  row: false,
  required: false,
}
