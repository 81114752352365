// Dependencies
import React from "react"
// Core Files
import Link from "core/Routes/components/Link"

function EmailFormatter(props) {
  const { column } = props
  let email = column.value || column.dafaultValue
  if (!email) return ""
  return <Link relative={false} to={`mailto:${email}`} children={email} />
}

export default EmailFormatter
