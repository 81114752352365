// Dependencies
import React from "react"
import PropTypes from "prop-types"
import Is from "@flk/supportive-is"
// Core Files
import { trans } from "core/localization"
// Shared Components
import Alert from "shared/components/Alert"
import ColoredIcon from "shared/components/Icon/ColoredIcon"
import ProgressBarWithLabel from "materialui/ProgressBar/ProgressBarWithLabel"
// Material Ui
import {
  Dialog,
  makeStyles,
  Typography,
  DialogTitle,
  DialogContent,
} from "@material-ui/core"
import TimerIcon from "@material-ui/icons/Timer"
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline"
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined"
import { blue, green, orange, red } from "@material-ui/core/colors"
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline"
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined"
import Accordion from "materialui/Accordion"

const useStyle = makeStyles((theme) => ({
  center: {
    textAlign: "center",
  },
  standardWidth: {
    width: "450px",
  },
  dialogConentPadding: {
    padding: "0px",
  },
  updatedAlertMsg: {
    padding: "15px",
    marginTop: "0px",
    borderRadius: "0px",
  },
}))

export function AlertMessageDialog({ type, messages, icon, title, ...rest }) {
  const classes = useStyle()
  if (Is.string(messages)) messages = [messages]

  return (
    <Dialog {...rest} maxWidth="sm" className={classes.center}>
      <DialogTitle className={classes.standardWidth}>
        {icon}
        <Typography variant="h5" component="div" gutterBottom>
          {title ? title : trans(type)}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogConentPadding}>
        {messages.map((msg, index) =>
          Is.string(msg) ? (
            <Alert
              label={msg}
              key={index}
              type={type}
              icon={false}
              className={classes.updatedAlertMsg}
            />
          ) : (
            <Accordion key={index} title={msg.title} messages={msg.messages} />
          )
        )}
      </DialogContent>
    </Dialog>
  )
}

AlertMessageDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClose: PropTypes.func.isRequired,
  type: PropTypes.oneOf(["success", "warning", "error", "info"]),
  messages: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
}

AlertMessageDialog.defaultProps = {
  open: false,
  type: "error",
  icon: (
    <ColoredIcon
      icon={ErrorOutlineIcon}
      style={{ fontSize: 80, color: red[500] }}
    />
  ),
}

export function SuccessAlertMessage(props) {
  const successIcon = (
    <ColoredIcon
      icon={CheckCircleOutlineIcon}
      style={{ fontSize: 80, color: green[500] }}
    />
  )
  return <AlertMessageDialog type="success" icon={successIcon} {...props} />
}

export function errorAlertMessage(props) {
  const errorIcon = (
    <ColoredIcon
      icon={ErrorOutlineIcon}
      style={{ fontSize: 80, color: red[500] }}
    />
  )
  return <AlertMessageDialog type="error" icon={errorIcon} {...props} />
}

export function WarningAlertMessage(props) {
  const warningIcon = (
    <ColoredIcon
      icon={ReportProblemOutlinedIcon}
      style={{ fontSize: 80, color: orange[500] }}
    />
  )
  return <AlertMessageDialog type="warning" icon={warningIcon} {...props} />
}

export function InfoAlertMessage(props) {
  const infoIcon = (
    <ColoredIcon
      icon={InfoOutlinedIcon}
      style={{ fontSize: 80, color: blue[500] }}
    />
  )
  return <AlertMessageDialog type="info" icon={infoIcon} {...props} />
}

export function WaitingAlertMessage(props) {
  const waitingIcon = (
    <ColoredIcon icon={TimerIcon} style={{ fontSize: 80, color: blue[500] }} />
  )
  const title = <ProgressBarWithLabel {...props} />
  return (
    <InfoAlertMessage
      title={title}
      icon={waitingIcon}
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
      messages={props.messages || []}
      {...props}
    />
  )
}
