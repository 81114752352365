// Core Files
import DateFormatter from "tables/Formatters/DateFormatter"

export const createdAt = {
  filter: true,
  key: "created_at",
  heading: "created_at",
  formatter: DateFormatter,
}

export const updatedAt = {
  filter: true,
  key: "updated_at",
  heading: "updated_at",
  formatter: DateFormatter,
}
