var direction = document.documentElement.dir || "ltr"

var left = direction === "ltr" ? "left" : "right"
var right = direction === "ltr" ? "right" : "left"

var Globals = {
  left: left,
  right: right,
  drawerWidth: 190,
  direction: direction,
  smallDrawerWidth: 140,
  requestArrayLength: 50,
  marginLeft: `margin-${left}`,
  marginRight: `margin-${right}`,
  paddingLeft: `padding-${left}`,
  paddingRight: `padding-${right}`,
  localeCode: document.documentElement.lang,
}

export default Globals
