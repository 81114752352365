// Core Files
import JobTitleForm from "./JobTitleForm"
import SetUpJobTitleForm from "modules/Employees/helpers/SetUpJobTitleForm"
// Shared Components
import DateFormatter from "tables/Formatters/DateFormatter"
import JobTitleService from "modules/Employees/services/JobTitleService"
import masterTableActions from "tables/TableComponents/TableActions/MasterActions"
import tableCheckBoxAll from "tables/TableComponents/TableDefaultItems/CheckBoxAll"

export default {
  title: "job_titles",
  service: JobTitleService,
  formOptions: {
    form: JobTitleForm,
    modalLable: "job_title",
    formikLable: "job_title_",
    setUpForm: SetUpJobTitleForm,
  },
  table: {
    orderBy: "id",
    heading: "job_titlesList",
    columns: [
      tableCheckBoxAll,
      { key: "id", heading: "#", filter: false },
      { key: "name", heading: "name", allowfilter: false },
      { allowfilter: false, key: "arabic_name", heading: "arabic_name" },
      {
        filter: false,
        key: "created_at",
        heading: "created_at",
        formatter: DateFormatter,
      },
      {
        filter: false,
        key: "updated_at",
        heading: "updated_at",
        formatter: DateFormatter,
      },
      masterTableActions(["delete", "edit"]),
    ],
  },
}
