// Dependencies
import { Field } from "formik"
import PropTypes from "prop-types"
import Is from "@flk/supportive-is"
import React, { useState } from "react"
import { Random } from "reinforcements"
// Core Files
import { trans } from "core/localization/index"
import { validateInput, isNotValid } from "../Validation/index"
// Material Ui + Custom Style
import useFormStyles from "../Styles"
import BaseSelect from "@material-ui/core/Select"
import {
  Chip,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@material-ui/core"

function Select(props) {
  const classes = useFormStyles()
  const { id, label, labelId, name, value, options, multiple, ...rest } = props
  const [selectValue, setSelectValue] = useState(
    multiple && !value ? [] : value
  )
  const setSelectedValue = (e) => {
    let index = e.target.value.indexOf("")
    if (Is.array(e.target.value) && index !== -1)
      e.target.value.splice(index, 1)
    setSelectValue(e.target.value)
  }
  const renderSelected = (selected) => {
    if (Is.array(selected)) {
      return (
        <div className={classes.chips}>
          {selected.map((value) => (
            <Chip key={value} label={value} className={classes.chip} />
          ))}
        </div>
      )
    }
    return selected
  }
  return (
    <Field name={name} style={{ width: "100%" }}>
      {({ field, form }) => {
        return (
          <FormControl
            {...rest}
            error={isNotValid(form, name)}
            className={classes.formControl}
          >
            <InputLabel id={labelId}>{trans(label)}</InputLabel>
            <BaseSelect
              id={name}
              {...field}
              labelId={labelId}
              multiple={multiple}
              value={selectValue}
              labelWidth={label.length * 11.5}
              onChange={(e) => setSelectedValue(e)}
              onBlur={(e) => {
                form.setFieldValue(name, e.target.value)
              }}
              renderValue={(values) => renderSelected(values)}
            >
              {options.map((option) => {
                return (
                  <MenuItem key={option.value} value={option.value}>
                    {option.key}
                  </MenuItem>
                )
              })}
            </BaseSelect>
            <FormHelperText>{validateInput(form, name)}</FormHelperText>
          </FormControl>
        )
      }}
    </Field>
  )
}

export default Select

Select.propTypes = {
  required: PropTypes.bool,
  variant: PropTypes.string,
  fullWidth: PropTypes.bool,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
}

Select.defaultProps = {
  value: "",
  id: Random.id(),
  required: false,
  variant: "outlined",
  labelId: Random.id(),
}
