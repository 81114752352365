import { logout } from "../services/auth"
import user from "user"

export default function userLogout(history) {
  // Logout user from the server
  logout()
    .then((res) => {
      // Clear user from cache
      user.logUserOut()
      // navigate to login page
      history.push("/login")
    })
    .catch((err) => {
      console.log(err)
    })
}
