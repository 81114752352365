// Core Files
import User from "./components"
import LoginForm from "./components/Login"
import Roles from "./components/Roles/index"
import Gardian from "core/middlewares/gardian"
import ReveseGardian from "core/middlewares/reverseGardian"

export default function (route) {
  route("/users", User, [Gardian])
  route("/roles", Roles, [Gardian])
  route("/login", LoginForm, [ReveseGardian])
}
