// Core Files
import RestfullEndpoint from "core/RestfulApi/RestfulApiEndpoint"

export class ProductService extends RestfullEndpoint {
  route = "/products"

  /**
   * Fetch records from endpoint api
   *
   * @param   {object} params
   * @returns {Promise}
   */
  getList(params) {
    return this.endpoint.get(`${this.route}/getList`, {
      params,
    })
  }

  /**
   * Fetch records from endpoint api
   *
   * @param   {object} params
   * @returns {Promise}
   */
  storeArr(params) {
    return this.endpoint.post(`${this.route}/store_products`, {
      items: params,
    })
  }

  /**
   * Edit products based on codes
   *
   * @param   {object} params
   * @returns {Promise}
   */
  editBy(params) {
    return this.endpoint.post(`${this.route}/edit_products_by_type`, params)
  }

  /**
   * Get next available product code
   *
   * @param   {object} params
   * @returns {Promise}
   */
  getNextAvailableCode() {
    return this.endpoint.get(`${this.route}/getNextAvailableCode`)
  }
}

export default new ProductService()
