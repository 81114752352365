// Dependencies
import React from "react"
// Shared Components
import Copyright from "shared/components/Copyright"
//Material Ui + Styles
import { Container, Box } from "@material-ui/core"
import useMasterContentStyles from "./MasterContentStyle"

function MasterContent(props) {
  const { pageTab } = props
  const classes = useMasterContentStyles()
  const classContainer = pageTab ? classes.tabContainer : classes.container

  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      <Container maxWidth="lg" className={classContainer}>
        {props.children}
        <Box pt={4}>
          <Copyright />
        </Box>
      </Container>
    </main>
  )
}

export default MasterContent
