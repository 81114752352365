// Dependencies
import React from "react"
// Core Files
import FormikControl from "core/formik/FormikControl"
import StandardFormik from "core/formik/StandardFormik"

function RoleForm(props) {
  return (
    <StandardFormik {...props}>
      <FormikControl required name="role" label="role" control="input" />
      <FormikControl control="input" name="display_name" label="display_name" />
      <FormikControl control="input" name="description" label="description" />
    </StandardFormik>
  )
}

export default RoleForm
