// Dependencies
import cls from "clsx"
import React from "react"
import PropTypes from "prop-types"
// Material Ui
import { makeStyles } from "@material-ui/core"
import {
  red,
  green,
  orange,
  blue,
  yellow,
  grey,
} from "@material-ui/core/colors"

const usebadgeStyle = makeStyles((theme) => ({
  root: {
    fontWeight: "bold",
    fontSize: theme.spacing(1),
    margin: theme.spacing(0.3),
    padding: theme.spacing(0.4),
    borderRadius: theme.spacing(0.4),
  },
}))
function MasterBadge(props) {
  const classes = usebadgeStyle()
  const { backgroundColor, color, className, style = {}, ...rest } = props
  style.color = color
  style.backgroundColor = backgroundColor
  style.fontSize = "11px"
  const classesList = cls(classes.root, className)
  return <span className={classesList} style={style} {...rest} />
}

export default MasterBadge

MasterBadge.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.object,
  backgroundColor: PropTypes.string,
}

export const RedBadge = (props) => (
  <MasterBadge backgroundColor={red[400]} color="white" {...props} />
)
export const OrangeBadge = (props) => (
  <MasterBadge backgroundColor={orange[400]} color="white" {...props} />
)
export const GreenBadge = (props) => (
  <MasterBadge backgroundColor={green[400]} color="white" {...props} />
)
export const BlueBadge = (props) => (
  <MasterBadge backgroundColor={blue[400]} color="white" {...props} />
)
export const YellowBadge = (props) => (
  <MasterBadge backgroundColor={yellow[600]} color="white" {...props} />
)
export const GreyBadge = (props) => (
  <MasterBadge backgroundColor={grey[400]} color="#000" {...props} />
)
export const DarkBadge = (props) => (
  <MasterBadge backgroundColor="#333" color="#FFF" {...props} />
)
export const BlackBadge = (props) => (
  <MasterBadge backgroundColor="#000" color="#FFF" {...props} />
)
export const WhiteBadge = (props) => (
  <MasterBadge backgroundColor="#FFF" color="#000" {...props} />
)
