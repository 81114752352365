/**
 * Set all routes that maybe requested in our application
 *
 * @const {Array}
 */
export const routesList = []

/**
 * Add new route to the router list
 *
 * @param {string} path
 * @param {React.Component} component
 * @param {Function|Array|null} middleware
 */
export function addRouter(path, component, middleware = null) {
  routesList.push({ path, component, middleware })
}
