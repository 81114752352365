// Core Files
import RestfullEndpoint from "core/RestfulApi/RestfulApiEndpoint"

export class CustomerService extends RestfullEndpoint {
  route = "/customers"

  /**
   * Fetch records from endpoint api
   *
   * @param   {object} params
   * @returns {Promise}
   */
  getList(params) {
    return this.endpoint.get(`${this.route}/getList`, {
      params,
    })
  }

  /**
   * Fetch records from endpoint api
   *
   * @param   {object} params
   * @returns {Promise}
   */
  storeArr(params) {
    return this.endpoint.post(`${this.route}/store_customers`, {
      items: params,
    })
  }

  /**
   * Fetch Bisan customers by customer_name
   *
   * @param {object} params
   * @returns {Promise}
   */
  getBisanCustomers(params) {
    return this.endpoint.get(`${this.route}/bisan_customers`, { params })
  }

  /**
   * Get next available customer code
   *
   * @param   {object} params
   * @returns {Promise}
   */
  getNextAvailableCode() {
    return this.endpoint.get(`${this.route}/getNextAvailableCode`)
  }
}

export default new CustomerService()
