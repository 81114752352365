// Core Files
import OrderService from "modules/Orders/services/OrderService"
import NumberFormatter from "tables/Formatters/NumberFormatter"
// Material Ui Icons
import LocationOnIcon from "@material-ui/icons/LocationOn"
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter"
import TextRotationNoneIcon from "@material-ui/icons/TextRotationNone"

export default {
  service: OrderService,
  table: {
    // orderBy: "created_at",
    columns: [
      { key: "note", heading: "notes" },
      {
        key: "bonus",
        width: "75px",
        heading: "bonus",
        formatter: NumberFormatter,
      },
      { width: "70px", key: "quantityInUnit", heading: "quantityInUnit" },
      { width: "85px", key: "quantityInBox", heading: "quantityInBox" },
      // {
      //   width: "100px",
      //   key: "quantity",
      //   heading: "quantity",
      //   formatter: NumberFormatter,
      // },
      // {
      //   width: "75px",
      //   key: "box_quantity",
      //   heading: "box_quantity",
      //   formatter: NumberFormatter,
      // },
      { width: "250px", heading: "name", key: "name" },
      { width: "20px", heading: " ", key: "empty" },
      //   { width: "150px", heading: "barcode", key: "barcode" },
    ],
    allowProperties: [
      { key: "note", name: "note" },
      { key: "bonus", name: "bonus" },
      { key: "quantity", name: "quantity" },
      { key: "product.name", name: "name" },
      { key: "product.weight", name: "weight" },
      { key: "product.barcode", name: "barcode" },
      { key: "product.box_quantity", name: "box_quantity" },
      {
        key: "product.product_details[0].position.importance",
        name: "importance",
      },
      {
        key: "product.products_categories[length-1].code",
        name: "category",
      },
    ],
  },
  sortOrder: [
    { key: "importance", icon: LocationOnIcon },
    { key: "category" },
    { key: "name", icon: TextRotationNoneIcon },
    { key: "weight", icon: FitnessCenterIcon },
  ],
}
