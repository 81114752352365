// Dependencies
import React from "react"
import PropTypes from "prop-types"
// Material Ui + Styles
import { LinearProgress, CircularProgress } from "@material-ui/core"

function ProgressBar(props) {
  const { control, ...rest } = props
  switch (control) {
    case "linear":
      return <LinearProgress {...rest} />
    case "circular":
      return <CircularProgress {...rest} />
    default:
      return <LinearProgress {...rest} />
  }
}

export default ProgressBar

ProgressBar.propTypes = {
  control: PropTypes.string,
}
