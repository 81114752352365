// Dependencies
import React from "react"
import PropTypes from "prop-types"
import Is from "@flk/supportive-is"
// Core Files
import { trans } from "core/localization/index"
// Material Ui + Style
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core"

export default function MasterConfirm(props) {
  let {
    title,
    onClose,
    message,
    closeText,
    onConfirm,
    confirmText,
    ...dialogProps
  } = props

  if (Is.string(message)) message = [message]

  const handleConfirm = () => {
    onConfirm()
    onClose()
  }

  return (
    <Dialog onClose={onClose} {...dialogProps}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {message.map((msg, index) => (
          <DialogContentText key={index}>{msg}</DialogContentText>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm} color="primary" autoFocus>
          {trans(confirmText.toLowerCase())}
        </Button>
        <Button onClick={onClose} color="primary">
          {trans(closeText.toLowerCase())}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

MasterConfirm.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  closeText: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
}

MasterConfirm.defaultProps = {
  closeText: "Cancel",
  confirmText: "Confirm",
  title: trans("confirmation"),
}
