// Dependencies
import * as Yup from "yup"

export default class SetUpUserForm {
  constructor(record) {
    this.initialValues = {
      id: record.id,
      role: record.role || "",
      description: record.description || "",
      display_name: record.display_name || "",
    }

    this.validationSchema = Yup.object({
      role: Yup.string().required("Required"),
      description: Yup.string(),
      display_name: Yup.string(),
    })
  }

  getInitialValues() {
    return this.initialValues
  }
  getValidationSchema() {
    return this.validationSchema
  }
}
