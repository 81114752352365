// Core Files
import RestfullEndpoint from "core/RestfulApi/RestfulApiEndpoint"

export class UserService extends RestfullEndpoint {
  route = "/users"

  /**
   * Fetch Active Users
   *
   * @param   {bool} params
   * @returns {Promise}
   */
  getList(params) {
    return this.endpoint.get(`${this.route}/getList`, {
      params,
    })
  }
}

export default new UserService()
