// Dependencies
import rtl from "jss-rtl"
import { create } from "jss"
import React, { useState } from "react"

// Core Files
import Globals from "core/globals"

// Material Ui + Styles
import { StylesProvider, jssPreset, useTheme } from "@material-ui/core/styles"

export const handleDirection = (direction, theme) => {
  let left = direction === "ltr" ? "left" : "right"
  let right = direction === "ltr" ? "right" : "left"
  theme.direction = direction
  Globals.direction = direction
  Globals.marginLeft = `margin-${left}`
  Globals.marginRight = `margin-${right}`
  Globals.paddingLeft = `padding-${left}`
  Globals.paddingRight = `padding-${right}`
  document.documentElement.dir = direction
}

const WithRTL = (props) => {
  const { children } = props
  // Configure JSS
  const jss = create({ plugins: [...jssPreset().plugins, rtl()] })
  return <StylesProvider jss={jss}>{children}</StylesProvider>
}

export const withRTL = (WrappedComponent) => {
  return (props) => {
    let initialDirection = Globals.localeCode === "ar" ? true : false
    const [rightToLeft, setRightToLeft] = useState(initialDirection)
    const theme = useTheme()
    let direction
    if (rightToLeft) {
      direction = "rtl"
      handleDirection(direction, theme)
    } else {
      direction = "ltr"
      handleDirection(direction, theme)
    }

    return (
      <WithRTL>
        <WrappedComponent
          {...props}
          rightToLeft={rightToLeft}
          setRightToLeft={setRightToLeft}
        />
      </WithRTL>
    )
  }
}
