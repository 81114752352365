import localization from "core/localization/index"

localization.extend("en", {
  user: "user",
  role: "role",
  role_: "Role",
  user_: "User",
  user__: "User",
  users: "Users",
  roles: "Roles",
  login: "Log In",
  usersList: "Users List",
  rolesList: "Roles List",
})
