// Core Files
import MainPageForm from "core/formik/MainPageForm"
import SetUpEmployeeForm from "modules/Employees/helpers/SetUpEmployeeForm"
// Shared Components
import {
  createdAt,
  updatedAt,
} from "tables/TableComponents/TableDefaultItems/TimeStamps"
import DateFormatter from "tables/Formatters/DateFormatter"
import ImageFormatter from "tables/Formatters/ImageFormatter"
import NumberFormatter from "tables/Formatters/NumberFormatter"
import BooleanFormatter from "tables/Formatters/BooleanFormatter"
import EmployeeService from "modules/Employees/services/EmployeeService"
import masterTableActions from "tables/TableComponents/TableActions/MasterActions"
import tableCheckBoxAll from "tables/TableComponents/TableDefaultItems/CheckBoxAll"
import masterFilterCheckboxs from "tables/TableComponents/TableActions/MasterCheckboxs"
import { FilterOptionComponent } from "tables/TableComponents/TableActions/Helpers/OptionsContent"

export default {
  type: "Page", // 'Modal' Or 'Page' for adding & editing
  title: "employees",
  service: EmployeeService,
  tabOptions: {
    tabs: [
      { label: "details", value: MainPageForm },
      // { label: "customers", value: "customers" },
      // { label: "orders", value: "orders" },
      // { label: "receipt_vouchers", value: "receipt_vouchers" },
      // { label: "receipt_checks", value: "receipt_checks" },
    ],
  },
  formOptions: {
    // form: EmployeeForm,
    modalLable: "employee",
    formikLable: "employee_",
    setUpForm: SetUpEmployeeForm,
  },
  table: {
    orderBy: "id",
    heading: "employeesList",
    columns: [
      tableCheckBoxAll,
      // { key: "id", heading: "#", filter: false },
      {
        filter: false,
        key: "picture",
        theme: "avatar",
        heading: "picture",
        formatter: ImageFormatter,
      },
      { key: "name", heading: "name", allowfilter: false },
      {
        filter: false,
        key: "salary",
        heading: "salary",
        formatter: NumberFormatter,
      },
      {
        filter: false,
        key: "job_title",
        heading: "job_title_",
        arrAttribute: "name",
      },
      {
        filter: false,
        key: "active",
        heading: "active",
        formatter: BooleanFormatter,
      },
      {
        filter: false,
        key: "hire_date",
        heading: "hireDate",
        formatter: DateFormatter,
      },
      {
        filter: true,
        key: "birth_date",
        heading: "birthDate",
        formatter: DateFormatter,
      },
      { filter: true, key: "phone", heading: "phone" },
      { filter: true, key: "fax", heading: "fax" },
      { filter: true, key: "country", heading: "country" },
      { filter: true, key: "city", heading: "city" },
      {
        filter: true,
        key: "debit",
        heading: "debit",
        formatter: NumberFormatter,
      },
      {
        filter: true,
        key: "target",
        heading: "target",
        formatter: NumberFormatter,
      },
      {
        filter: true,
        key: "vacation_days",
        heading: "vacation_days",
        formatter: NumberFormatter,
      },
      {
        filter: true,
        key: "note",
        heading: "notes",
      },
      createdAt,
      updatedAt,
      masterTableActions(["delete", "edit"]),
    ],
  },
  advancedOptions: [
    {
      component: FilterOptionComponent,
      configuration: { checkboxs: masterFilterCheckboxs(["active"]) },
    },
  ],
}

// {
//   heading: "#2",
//   key: "id2",
//   defaultValue: 2,
//   formatter: LinkFormatter,
//   href: (record) => `/users/${record.id}`,
// },
// {
//   heading: "Number",
//   key: null,
//   value: 343242362,
//   formatter: NumberFormatter,
// },
// {
//   key: "image2",
//   heading: "image2",
//   theme: "thumbnail", // or avatar
//   formatter: ImageFormatter,
//   defaultValue: "https://scx2.b-cdn.net/gfx/news/hires/2019/2-nature.jpg",
// },
// {
//   key: "image",
//   heading: "image",
//   theme: "avatar", // or thumbnail
//   formatter: ImageLinkFormatter,
//   href: (record) => `/users/${record.id}`,
//   defaultValue: "https://scx2.b-cdn.net/gfx/news/hires/2019/2-nature.jpg",
// },
// {
//   heading: "country",
//   key: "country",
//   defaultValue: {
//     en: "Al Quds",
//     ar: "القدس",
//   },
//   formatter: MultiLingualFormatter,
// },
// {
//   heading: "User Name",
//   key: "username",
//   defaultValue: "New User", // This will fill any empty string with new user value
//   formatter: TranslatorFormatter,
// },
// {
//   heading: "Status",
//   key: "status",
//   defaultValue: true,
//   formatter: SwitchFormmater,
//   onChange: (record, checked, column) => {
//     // Send request to api
//     console.log(checked, record)
//   },
// },
// {
//   heading: "Color",
//   key: "color",
//   // defaultValue: "white",
//   formatter: DropDownFormatter,
//   label: "color",
//   items: items,
//   onChange: (record, selectedItem) => {
//     console.log(selectedItem, record)
//   },
// },
