// Dependencies
import React from "react"
// Core Files
import { trans } from "core/localization/index"
// Material Ui + Styles
import HomeIcon from "@material-ui/icons/Home"
import FaceIcon from "@material-ui/icons/Face"
import WorkIcon from "@material-ui/icons/Work"
import SyncIcon from "@material-ui/icons/Sync"
import PersonIcon from "@material-ui/icons/Person"
import ListAltIcon from "@material-ui/icons/ListAlt"
import BusinessIcon from "@material-ui/icons/Business"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import StorefrontIcon from "@material-ui/icons/Storefront"
import AccountTreeIcon from "@material-ui/icons/AccountTree"
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser"
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket"
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle"
// Custom Icons
import RolesIcon from "shared/components/Icon/Roles/RolesIcon"

export default [
  {
    route: "/",
    icon: <HomeIcon />,
    text: trans("home"),
  },
  {
    text: trans("users"),
    icon: <VerifiedUserIcon />,
    items: [
      {
        route: "/users",
        text: trans("usersList"),
        icon: <SupervisedUserCircleIcon />,
      },
      {
        text: "roles",
        route: "/roles",
        icon: <RolesIcon />,
      },
    ],
  },
  {
    text: trans("company"),
    icon: <BusinessIcon />,
    items: [
      {
        route: "/employees",
        icon: <PersonIcon />,
        text: trans("employees"),
      },
      {
        icon: <WorkIcon />,
        route: "/job_titles",
        text: trans("job_titles"),
      },
    ],
  },
  {
    icon: <FaceIcon />,
    route: "/customers",
    text: trans("customers"),
  },
  {
    route: "/categories",
    text: trans("categories"),
    icon: <AccountTreeIcon />,
  },
  {
    text: trans("products"),
    icon: <StorefrontIcon />,
    items: [
      {
        route: "/products",
        text: trans("productsList"),
        icon: <ShoppingBasketIcon />,
      },
      {
        route: "/positions",
        text: trans("positions"),
        icon: <LocationOnIcon />,
      },
    ],
  },
  {
    route: "/orders",
    text: trans("orders"),
    icon: <ListAltIcon />,
  },
  {
    route: "/updates",
    icon: <SyncIcon />,
    text: trans("sync_"),
  },
]
