// Dependencies
import React from "react"
import { Route, useHistory } from "react-router-dom"
// Core Files
import history from "./routerHistory"
import Middleware from "./Middleware"
import modulesList from "./modulesList"
import { routesList } from "./routesList"
import config from "core/configuration/config"
import { firstSegmentOfRoute, isPartOfLazyModules } from "./renderersHelpers"
// Shared Components
import ProgressBar from "materialui/ProgressBar"
import { updateCurrentRoute } from "./Navigation/navigator"

const localeCodes = config.get("locales")
const forceRefresh = config.get("router.forceRefresh", true) // if true, it will force middleware to rerender the page every time
// else it will not refresh the page

export default function Renderer(props) {
  const { location } = props
  let firstSegment = firstSegmentOfRoute(location)
  const [loadedModule, loadModule] = React.useState([])

  // Check if module is loaded
  const moduleIsLoaded = loadedModule.includes(firstSegment)
  const histroy2 = useHistory()

  React.useEffect(() => {
    const moduleInfo = modulesList[firstSegment]
    if (!moduleIsLoaded && moduleInfo) {
      moduleInfo.load().then((e) => {
        loadModule(loadedModule.concat(moduleInfo.entry))
      })
    }

    // history listener
    const unlisten = histroy2.listen((location) => {
      // console.log(location)
      updateCurrentRoute(location.pathname)
    })
    return () => {
      unlisten()
    }
  }, [firstSegment, moduleIsLoaded, loadedModule, histroy2])

  // Display the progress bar if the firstsegment is not in the LoadedModules
  // And the firstSegment is part of module list that will be loaded
  if (!moduleIsLoaded && isPartOfLazyModules(firstSegment)) {
    return <ProgressBar />
  }
  // console.log(routesList)
  // each route contains:
  // path: path to page
  // middleware: middleware to be applied before accessing the component page
  // component: component class that will render the page
  const routes = routesList.map((route, index) => {
    const renderRoute = (routeData) => {
      // When forceRefresh flag is set to true then the route component will re-rendered every time
      // the user clicks on the same route, otherwise the user will stay in the same page without re-rendering
      const middlewareKey = forceRefresh ? Date.now() : null
      // /* Middleware for check if user is loggedIn or not */
      return (
        <Middleware
          route={route}
          history={history}
          key={middlewareKey}
          match={routeData.match}
          location={routeData.location}
        />
      )
    }
    return (
      // Route Path
      // i.e /users
      // Or /ar/users
      // Or /en/users
      <Route
        exact
        key={index}
        render={renderRoute}
        path={`/:localeCode(${Object.keys(localeCodes).join("|")})?${
          route.path
        }`}
      />
    )
  })
  return routes
}
