// Dependencies
import clsx from "clsx"
import PropTypes from "prop-types"
import Is from "@flk/supportive-is"
import { Random } from "reinforcements"
import React, { useState, useEffect } from "react"
// Core Files
import { getItem } from "./SelectItemUtils"
import { trans } from "core/localization/index"
// Material Ui + Custom Style
import useFormStyles from "../Styles"
import BaseSelect from "@material-ui/core/Select"
import { Chip, MenuItem, InputLabel, FormControl } from "@material-ui/core"

function SelectInput(props) {
  const classes = useFormStyles()
  let {
    id,
    name,
    value,
    label,
    labelId,
    options,
    multiple,
    onChange,
    lazyLoading,
    request,
    backendV,
    appenedValues,
    defaultOption,
    className,
    ...rest
  } = props

  if (!value) value = ""
  const [selectValue, setSelectValue] = useState(
    multiple && !value ? [] : Is.object(value) ? value.value : value
  )

  const InputClass = clsx(className)

  const [loaded, requestIsLoaded] = useState(false)
  const [isLoading, setLoading] = useState(lazyLoading)
  const [currentItems, setItems] = useState(options || [])

  const mapResponse = (response) => {
    let result = response.data.map((record) => {
      let updatedRecord = {
        id: record.id,
        key: record[backendV],
        value: record[backendV],
      }
      if (appenedValues.length) {
        appenedValues.forEach((element) => {
          updatedRecord[element] = record[element]
        })
      }
      return updatedRecord
    })
    if (defaultOption) result.unshift(defaultOption)
    return result
  }

  useEffect(() => {
    if (!lazyLoading || loaded) return

    request().then((response) => {
      const items = mapResponse(response)
      setItems(items)
      requestIsLoaded(true)
      setLoading(false)
    })
    // eslint-disable-next-line
  }, [lazyLoading, loaded, request])

  const setSelectedValue = (e) => {
    let value = e.target.value
    setSelectValue(value)
    if (Is.array(value)) {
      let index = value.indexOf("")
      if (index !== -1) value.splice(index, 1)
    }
    let item = getItem(currentItems, value)
    onChange && onChange(item)
  }

  const renderSelected = (selected) => {
    if (Is.array(selected)) {
      return (
        <div className={classes.chips}>
          {selected.map((value) => (
            <Chip key={value} label={value} className={classes.chip} />
          ))}
        </div>
      )
    }
    return trans(selected)
  }

  const selectItems = (items) => {
    if (isLoading) {
      items = [
        {
          key: `${trans("loading")}...`,
          value: "",
          disabled: true,
        },
      ]
    }

    return items.map((option) => {
      return (
        <MenuItem
          key={option.key}
          value={option.value}
          disabled={option.disabled === true}
        >
          {trans(option.key)}
        </MenuItem>
      )
    })
  }

  return (
    <FormControl {...rest} className={classes.formControl}>
      <InputLabel id={labelId}>{trans(label)}</InputLabel>
      <BaseSelect
        id={name}
        labelId={labelId}
        multiple={multiple}
        value={selectValue}
        className={InputClass}
        labelWidth={label.length * 11.5}
        onChange={(e) => setSelectedValue(e)}
        renderValue={(values) => renderSelected(values)}
      >
        {selectItems(currentItems)}
      </BaseSelect>
    </FormControl>
  )
}

export default SelectInput

SelectInput.propTypes = {
  required: PropTypes.bool,
  options: PropTypes.array,
  multiple: PropTypes.bool,
  backendV: PropTypes.string,
  variant: PropTypes.string,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  appenedValues: PropTypes.array,
  defaultOption: PropTypes.object,
  label: PropTypes.string.isRequired,
}

SelectInput.defaultProps = {
  value: "",
  id: Random.id(),
  required: false,
  multiple: false,
  backendV: "name",
  appenedValues: [],
  variant: "outlined",
  labelId: Random.id(),
}
