// Dependencies
import React from "react"
import PropTypes from "prop-types"
import { Link as RouterLink } from "react-router-dom"
// Core Files
import Globals from "core/globals"
import { hasInitialLocaleCode } from "core/Routes/Navigation/navigator"
// Styles
import "./styles/link.css"
// Material Ui
import { Link as MaterialLink } from "@material-ui/core"

const Link = React.forwardRef(function (props, forwardedRef) {
  let { to, localeCode, relative, ...rest } = props

  // If not relative, then use normal anchor tag
  if (!relative) {
    return <MaterialLink href={to} ref={forwardedRef} {...rest} />
  }

  if (localeCode) {
    to = `/${localeCode}${to === "/" ? "" : to}`
  }
  rest.to = to
  return <RouterLink {...rest} ref={forwardedRef} />
})

export default Link

Link.propTypes = {
  relative: PropTypes.bool,
}

Link.defaultProps = {
  relative: true,
  localeCode: hasInitialLocaleCode() ? Globals.localeCode : null,
}

// If initialLocalCode (navigator) is true, then add the current locale code as locale code props
// for the link

// /users ? false >> no locale code
// /en/users ? true >> get current locale code
