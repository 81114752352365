// Dependencies
import React from "react"
import PropTypes from "prop-types"
// Core Files
import { trans } from "core/localization/index"
// Shared Components
import ProgressBar from "materialui/ProgressBar"
// Material UI + Styles
import { Button } from "@material-ui/core"
import useFormStyles from "materialui/Styles"

function StaticButton(props) {
  const classes = useFormStyles()
  const { type, label, onClick, children, classNameStyle, ...rest } = props

  const handleClick = () => {
    if (type === "submit") return
    onClick()
  }
  return (
    <Button
      {...rest}
      type={type}
      onClick={handleClick}
      style={classNameStyle}
      className={!classNameStyle ? classes.button : null}
    >
      {label === "waiting" ? (
        <ProgressBar className={classes.progressBar} control="circular" />
      ) : (
        trans(label) || children
      )}
    </Button>
  )
}

export default StaticButton

StaticButton.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  fullWidth: PropTypes.bool,
  classNameStyle: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

StaticButton.defaultProps = {
  type: "button",
  disabled: false,
  fullWidth: false,
  onClick: () => {},
  color: "secondary",
  variant: "contained",
}

export const TextButton = (props) => {
  const { classNameStyle, ...rest } = props
  return <Button color="default" variant="text" {...rest} />
}
export const DefaultButton = (props) => (
  <StaticButton color="default" variant="contained" {...props} />
)
export const PrimaryButton = (props) => (
  <StaticButton color="primary" variant="contained" {...props} />
)
