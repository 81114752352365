// Dependencies
import clsx from "clsx"
import React from "react"
// Core Files & Hooks
import { trans } from "core/localization"
import { MainContent, SubContent } from "./ToolBarContent"
import useTable from "tables/hooks/useTable"
import useTableRequest from "tables/hooks/useTableRequest"
import { searchRecords } from "tables/helpers/SearchHelpers"
// Material Ui + Styles
import useToolbarStyles from "tables/CSS/TableToolBar"
import { Paper, Toolbar } from "@material-ui/core"

function EnhancedToolBar(props) {
  const { label } = props
  const classes = useToolbarStyles()
  const [searchText, setSearchText] = React.useState("")

  const { setRequest } = useTableRequest()
  const { options, tableInfoObj, tableSelectedRow } = useTable()

  const { selected } = tableSelectedRow
  const numSelected = selected.length

  const handleKeyPress = (e, type) => {
    const defReqObj = {
      request: (params) => options.service.list(params),
      status: "search",
    }
    searchRecords(e, type, searchText, tableInfoObj, setRequest, defReqObj)
  }

  const MainToolBar = (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <SubContent
          classes={classes}
          label={`${numSelected} ${trans("selected")}`}
        />
      ) : (
        <MainContent
          label={label}
          classes={classes}
          searchText={searchText}
          setSearchText={setSearchText}
          handleKeyPress={handleKeyPress}
        />
      )}
    </Toolbar>
  )
  return (
    <Paper className={classes.paper} elevation={3}>
      {MainToolBar}
    </Paper>
  )
}

export default EnhancedToolBar
