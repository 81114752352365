// Dependencies
import React from "react"
import PropTypes from "prop-types"
import Is from "@flk/supportive-is"
// Shared Components
import ModalTitle from "./ModalTitle"
// Material Ui + Styles
import { Dialog, DialogContent } from "@material-ui/core"

function Modal(props) {
  const {
    esc,
    size,
    plain,
    title,
    backdrop,
    onClose,
    onSubmit,
    ...rest
  } = props

  const modelTile = Is.string(title) ? (
    <ModalTitle title={title} onClose={onClose} />
  ) : (
    title
  )

  const modelContent = plain ? (
    props.children
  ) : (
    <DialogContent dividers children={props.children} />
  )
  return (
    <div>
      <Dialog
        maxWidth={size}
        onClose={onClose}
        disableEscapeKeyDown={!esc}
        disableBackdropClick={!backdrop}
        {...rest}
      >
        {modelTile}
        {modelContent}
      </Dialog>
    </div>
  )
}

export default Modal

Modal.propTypes = {
  esc: PropTypes.bool,
  plain: PropTypes.bool,
  size: PropTypes.string,
  onClose: PropTypes.func,
  backdrop: PropTypes.bool,
  fullWidth: PropTypes.bool,
  fullScreen: PropTypes.bool, // same attribute name in the model
  open: PropTypes.bool.isRequired, // same attribute name in the model
  children: PropTypes.any.isRequired,
  // onSubmit: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

Modal.defaultProps = {
  size: "sm",
  esc: false,
  plain: false, // if set to true then the dialog content will not be userd
  backdrop: false,
  fullWidth: true,
  fullScreen: false,
}
