// Dependencies
import React from "react"
//Core Files & Hooks
import useTable from "tables/hooks/useTable"
import { trans } from "core/localization/index"
import useRowProvider from "tables/hooks/useRowProvider"
// Shared Components
import Tooltip from "materialui/ToolTip/MasterToolTip"
import MasterConfirm from "layout/Confirm/MasterConfirm"
// Material Ui + Styles
import { IconButton } from "@material-ui/core"
import DeleteSweep from "@material-ui/icons/DeleteSweep"

export default function TableDeleteButton() {
  const { record, rowIndex } = useRowProvider()
  const [confirming, setConfirm] = React.useState(false)
  const { options, tableRecordsObj, tableSelectedRow } = useTable()

  let { setTableRecords } = tableRecordsObj
  const { selected, setSelected } = tableSelectedRow

  const handleOnConfrim = () => {
    if (selected) setSelected([])
    setTableRecords((records) => {
      records.splice(rowIndex, 1)
      return [...records]
    })
    options.service.delete(record.id) // delete record from database
  }

  return (
    <>
      <MasterConfirm
        open={confirming}
        onConfirm={handleOnConfrim}
        onClose={() => setConfirm(false)}
        title={trans("confirmation")}
        message={trans("deleteText", record.name || record.id)}
      />

      <IconButton onClick={() => setConfirm(true)} color="primary">
        <Tooltip title={trans("remove")}>
          <DeleteSweep />
        </Tooltip>
      </IconButton>
    </>
  )
}
