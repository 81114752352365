import localization from "core/localization/index"

localization.extend("ar", {
  ready: "جاهز",
  bonus: "بونص",
  order_: "طلبية",
  total: "ألمجموع",
  oneUnit: "حبة",
  orders: "ألطلبيات",
  discount: "خصم",
  canceled: "ملغي",
  quantity: "ألكمية",
  order: "بيانات طلبية",
  not_ready: "غير جاهز",
  orderDetail: "بيانات طلبية",
  ordersList: "قائمة ألطلبيات",
  quantityInUnit: "ألكمية بالحبة",
  quantityInBox: "ألكمية بالكرتون",
  piac: "تم إختيار هذا الصنف من قبل",
  orderAdded: "تم تعديل ألطلبية بنجاح",
  orderDetailsList: "قائمة بيانات الطلبية",
  orderUpdated: "تم إضافة طلبية جديدة بنجاح",
})
