// Dependencies
import React, { useRef } from "react"
import { useHistory } from "react-router-dom"
import { useReactToPrint } from "react-to-print"
// Core Files
import tableItems from "./PrintItems"
import { trans } from "core/localization"
import PreviewOrder from "./PreviewOrder"
// Shared Components
import { PrimaryButton } from "materialui/Button/Button"
import MasterConfirm from "layout/Confirm/MasterConfirm"
// Material Ui
import { styled } from "@material-ui/core"
import PrintIcon from "@material-ui/icons/Print"

const ButtonWrapper = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(1),
}))

export const ButtonText = styled("div")(({ theme }) => ({
  marginRight: theme.spacing(3),
}))

function OrdersToPrint(props) {
  const history = useHistory()
  const componentRef = useRef()
  const routeState = props.history.location.orders || []
  const orders = routeState !== undefined ? routeState : []
  const [confirming, setConfirm] = React.useState(false)
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => setConfirm(true),
  })

  const handleOnConfirm = () => {
    const ordersIds = orders.map((order) => order.id)
    tableItems.service
      .updateList({ ids: ordersIds })
      .then((res) => {
        if (res.data.hasOwnProperty("success")) history.goBack()
      })
      .catch((err) => console.log(err))
  }
  return (
    <>
      <ButtonWrapper>
        <PrimaryButton onClick={handlePrint}>
          <PrintIcon />
          <ButtonText>{trans("print")}</ButtonText>
        </PrimaryButton>
      </ButtonWrapper>

      {/* <button onClick={handlePrint}>Print this out!</button> */}
      <PreviewOrder
        orders={orders}
        ref={componentRef}
        columns={tableItems.table.columns}
      />
      {/* {orders.map((order, index) => {
        const sortedRecords = getSortedOrder(order)
        return (
          <PreviewOrder
            key={index}
            order={order}
            ref={componentRef}
            sortedRecords={sortedRecords}
            columns={tableItems.table.columns}
          />
        )
      })} */}
      <MasterConfirm
        open={confirming}
        onConfirm={handleOnConfirm}
        title={trans("printingStatus")}
        onClose={() => setConfirm(false)}
        message={trans("printingConfrimation")}
      />
    </>
  )
}

export default OrdersToPrint
