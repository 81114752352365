// Dependencies
import React from "react"
// Shared Components
import Number from "materialui/Input/Number"
import Select from "materialui/Select/Select"
import TextArea from "materialui/Input/TextArea"
import Password from "materialui/Input/Password"
import StaticButton from "materialui/Button/Button"
import InputFormik from "materialui/Input/InputFormik"
import CheckBoxGroup from "materialui/CheckBox/CheckBoxGroup"
import DatePicker from "materialui/DateTimePicker/DatePicker"
import FileInputFormik from "materialui/Input/FileInputFormik"
import SwitchButtonFormik from "materialui/Button/SwitchButtonFormik"
import RadioGroupButtons from "materialui/RadioButton/RadioGroupButtons"
import LazyAutoCompleteFormik from "materialui/AutoComplete/LazyAutoCompleteFormik"
import LazyMAutoCompleteFormik from "materialui/AutoComplete/LazyMultipleAutoCompleteFormik"

function FormikControl(props) {
  const { control, ...rest } = props
  switch (control) {
    case "number":
      return <Number {...rest} />
    case "select":
      return <Select {...rest} />
    case "password":
      return <Password {...rest} />
    case "textarea":
      return <TextArea {...rest} />
    case "date":
      return <DatePicker {...rest} />
    case "input":
      return <InputFormik {...rest} />
    case "button":
      return <StaticButton {...rest} />
    case "checkboxgroupbuttons":
      return <CheckBoxGroup {...rest} />
    case "file":
      return <FileInputFormik {...rest} />
    case "radiogroupbuttons":
      return <RadioGroupButtons {...rest} />
    case "switchbutton":
      return <SwitchButtonFormik {...rest} />
    case "lazyautocomplete":
      return <LazyAutoCompleteFormik {...rest} />
    case "lazymautocomplete":
      return <LazyMAutoCompleteFormik {...rest} />
    default:
      return null
  }
}

export default FormikControl
