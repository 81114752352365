// Dependencies
import React from "react"
import PropTypes from "prop-types"
import { Random } from "reinforcements"
// Material Ui
import { Checkbox } from "@material-ui/core"

function CheckBoxButtonNF(props) {
  const { heading, onChange, color, ...rest } = props
  return (
    <Checkbox
      style={{ color }}
      onChange={(e) => onChange(e, heading)}
      {...rest}
    />
  )
}

export default CheckBoxButtonNF

CheckBoxButtonNF.propTypes = {
  id: PropTypes.string,
  icon: PropTypes.node,
  size: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  checkedIcon: PropTypes.node,
  checked: PropTypes.bool.isRequired,
}

CheckBoxButtonNF.defaultProps = {
  size: "medium", // small / medium
  id: Random.id(),
  disabled: false,
  color: "secondary", // default / primary / secondary (this is the default for the Radio)
}
