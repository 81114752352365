const mapResponse = (response) => {
  const { records, paginationInfo } = response
  return {
    records,
    pagination: paginationInfo,
  }
}

export function loadData(response, setTableBody, setIsLoading) {
  let newResponse = {}
  newResponse["records"] = response.data
  delete response["data"]
  newResponse["paginationInfo"] = { ...response }
  const { records, pagination } = mapResponse(newResponse)
  setTableBody({
    records,
    pagination,
  })
  setIsLoading(false)
}
