// Dependenceis
import React from "react"
// Material Ui
import MaterialAvatar from "@material-ui/core/Avatar"

function Avatar(props) {
  return <MaterialAvatar {...props} />
}

export default Avatar
