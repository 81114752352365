// Dependencies
import React from "react"
import { Field } from "formik"
import PropTypes from "prop-types"
import { Random } from "reinforcements"
// Core Files
import Is from "@flk/supportive-is"
import { trans } from "core/localization"
import useRequest from "core/hooks/useRequest"
// Shared Components
import { isNotValid, validateInput } from "materialui/Validation"
import ProgressBar from "materialui/ProgressBar"
// Material Ui & Styles
import useFormStyles from "materialui/Styles"
import { FormControl } from "@material-ui/core"
import TextField from "@material-ui/core/TextField"
import MaterialAutocomplete from "@material-ui/lab/Autocomplete"

function LazyAutoCompleteFormik(props) {
  let {
    name,
    label,
    options,
    request,
    required,
    backendV,
    onFieldBlur,
    defaultValue,
    appenedValues,
    defaultOption,
    ...rest
  } = props

  const classes = useFormStyles()
  const [response] = useRequest(() => request())
  const [loading, setLoading] = React.useState(true)
  const [value, setValue] = React.useState(defaultValue)
  const [items, setItems] = React.useState(options || [])

  const mapResponse = (response) => {
    let result = response.data.map((record) => {
      let updatedRecord = {
        id: record.id,
        key: record[backendV],
      }
      if (appenedValues.length) {
        appenedValues.forEach((element) => {
          updatedRecord[element] = record[element]
        })
      }
      return updatedRecord
    })
    if (defaultOption) result.unshift(defaultOption)
    return result
  }

  if (response && loading) {
    setLoading(false)
    setItems(mapResponse(response))
    if (Is.object(defaultValue) && !defaultValue.hasOwnProperty("key")) {
      setValue({
        id: defaultValue.id,
        key: defaultValue[backendV],
      })
    }
  }

  const getSelected = (option, value) => {
    if (Is.object(value)) return option.key === value.key
    return option.key === value
  }

  const getOptionLabel = (option) => {
    if (Is.object(defaultValue)) {
      return option[backendV] || option.key
    }
    return option.key
  }

  return (
    <Field name={name}>
      {({ field, form }) => {
        return (
          <FormControl
            error={isNotValid(form, name)}
            className={classes.formControl}
          >
            <MaterialAutocomplete
              {...rest}
              value={value}
              options={items}
              loading={loading}
              getOptionLabel={(option) => getOptionLabel(option)}
              getOptionSelected={(option, value) => getSelected(option, value)}
              onChange={(e, value) => {
                setValue(value)
                form.setFieldValue(name, value)
              }}
              onBlur={() => {
                onFieldBlur && onFieldBlur(form)
              }}
              renderInput={(params) => (
                <TextField
                  {...field}
                  {...params}
                  variant="outlined"
                  required={required}
                  label={trans(label)}
                  error={isNotValid(form, name)}
                  helperText={validateInput(form, name)}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? <ProgressBar control="circular" /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </FormControl>
        )
      }}
    </Field>
  )
}

export default LazyAutoCompleteFormik

LazyAutoCompleteFormik.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  backendV: PropTypes.string,
  limitTags: PropTypes.number,
  onFieldBlur: PropTypes.func,
  appenedValues: PropTypes.array,
  defaultOption: PropTypes.object,
  label: PropTypes.string.isRequired,
  disableCloseOnSelect: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

LazyAutoCompleteFormik.defaultProps = {
  options: [],
  limitTags: 3,
  required: false,
  id: Random.id(),
  fullWidth: true,
  backendV: "name",
  appenedValues: [],
  disableCloseOnSelect: false,
}
