// Dependencies
import React from "react"
//Core Files & Hooks
import { trans } from "core/localization"
import useTable from "tables/hooks/useTable"
import Tooltip from "materialui/ToolTip/MasterToolTip"
// Material Ui + Styles
import Popover from "materialui/Popover"
import useFormStyles from "materialui/Styles"
import { IconButton } from "@material-ui/core"
import MoreVertIcon from "@material-ui/icons/MoreVert"

export default function TableOptionsButton() {
  const { options } = useTable()
  const classes = useFormStyles()

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleOnClick = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const advancedOptions = options.advancedOptions
  const moreOptionsConent = advancedOptions.map((option, index) => {
    return <option.component key={index} configuration={option.configuration} />
  })

  return (
    <>
      <IconButton
        color="default"
        onClick={handleOnClick}
        className={classes.toolBarPadding}
      >
        <Tooltip title={trans("moreOptions")}>
          <MoreVertIcon />
        </Tooltip>
      </IconButton>

      <Popover
        anchorEl={anchorEl}
        onClose={handleClose}
        content={moreOptionsConent}
      />
    </>
  )
}
