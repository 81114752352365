// Dependencies
import clsx from "clsx"
import PropTypes from "prop-types"
import Is from "@flk/supportive-is"
import { Formik, Form } from "formik"
import React, { useState } from "react"
import { useHistory } from "react-router-dom"
// Core Files & Hooks
import AddRecord from "./services/AddRecord"
import useTable from "tables/hooks/useTable"
import UpdateRecord from "./services/UpdateRecord"
import useRowProvider from "tables/hooks/useRowProvider"
import FormikProvider from "core/formik/providers/FormikProvider"
import { loadOptions, processValues } from "./helpers/standardFormikHelper"
// Shared Components
import useFormStyles from "materialui/Styles"
import AlertMessage from "shared/components/Alert"

function StandardFormik(props) {
  const history = useHistory()
  const classes = useFormStyles()
  const classForm = clsx(props.className)
  const [formResult, setFormResult] = useState({})
  const [formStatus, setFormStatus] = useState({})
  let { record = {}, rowIndex = -1 } = useRowProvider()
  let { options = {}, tableRecordsObj = {} } = useTable()
  let {
    action,
    fRecord,
    nextCode,
    onConfirm,
    setIsSubmitting,
    reference = null,
  } = props

  // fRecord = Form Record, received from MainPageForm
  if (Is.empty(record) && fRecord) record = fRecord
  if (nextCode) record = { code: nextCode }

  /**
   * Detect soruce type if we need to deal with modal or page
   * if formik need to present in page, we need to bring options, dynamicaly
   *
   */
  if (Is.empty(options)) {
    options = loadOptions(history)
  }
  const currentTitle = options.formOptions.formikLable
  const ContainerHandler = { onConfirm, setIsSubmitting, currentTitle }
  const TableHandler = { action, options, rowIndex, record, tableRecordsObj }

  // FormikObj is a helper class that handle Formik operations
  let FormikObj = new options.formOptions.setUpForm(record, action)
  const initialValues = FormikObj.getInitialValues()
  const validationSchema = FormikObj.getValidationSchema()
  const onSubmit = (values, { setSubmitting, resetForm }) => {
    const FormikHandler = {
      resetForm,
      setSubmitting,
      setFormResult,
      setFormStatus,
    }
    setFormResult([]) // clear form state before getting the response
    setFormStatus("success") // default - success
    setIsSubmitting && setIsSubmitting(true)
    let cloneValues = JSON.parse(JSON.stringify(values))
    cloneValues = processValues(cloneValues)

    if (action === "add" || action === "create") {
      AddRecord(cloneValues, FormikHandler, ContainerHandler, TableHandler)
    } else if (action === "edit") {
      UpdateRecord(cloneValues, FormikHandler, ContainerHandler, TableHandler)
    }
  }

  return (
    <>
      {Object.keys(formResult).length > 0 &&
        Object.keys(formResult).map((state) => {
          return formResult[state].map((result, index) => {
            return <AlertMessage key={index} type={formStatus} label={result} />
          })
        })}
      <Formik
        onSubmit={onSubmit}
        innerRef={reference}
        className={classes.form}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <FormikProvider.Provider value={formik}>
            <Form className={classForm} noValidate>
              {props.children}
            </Form>
          </FormikProvider.Provider>
        )}
      </Formik>
    </>
  )
}

export default StandardFormik

StandardFormik.propTypes = {
  action: PropTypes.string,
  onConfirm: PropTypes.func,
  nextCode: PropTypes.string,
  reference: PropTypes.object,
  setIsSubmitting: PropTypes.func,
  children: PropTypes.node.isRequired,
}
