// Dependencies
import clsx from "clsx"
import React from "react"
// Core Files & Hooks
import items from "./SideBarsItems"
import useDrawer from "layout/hooks/useDrawer"
// Material Ui + Styles
import {
  List,
  Drawer,
  Divider,
  IconButton,
  ListSubheader,
} from "@material-ui/core"
import SideBarListItem from "./ListItem"
import SideBarListItemGroup from "./ListItemGroup"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import useMasterLayoutStyles from "shared/styles/MasterLayoutStyles"

function MasterSideBar(props) {
  const classes = useMasterLayoutStyles()
  const { drawer, toggleDrawer, nestedList, setNestedList } = useDrawer()

  const handleToggleDrawer = () => {
    setNestedList(drawer ? false : true)
    toggleDrawer(!drawer)
  }

  let itemList = items.map((item, index) => {
    if (item.items) {
      return (
        <SideBarListItemGroup
          key={index}
          text={item.text}
          icon={item.icon}
          items={item.items}
          nested={nestedList}
        />
      )
    }
    return (
      <SideBarListItem
        key={index}
        text={item.text}
        icon={item.icon}
        route={item.route}
      />
    )
  })
  return (
    <>
      <Drawer
        open={drawer}
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !drawer && classes.drawerPaperClose),
        }}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleToggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List component="nav" className={classes.sideBarListRoot}>
          {itemList}
        </List>
        <Divider />
        <List>
          <ListSubheader
            className={clsx(
              classes.listSubheader,
              !drawer && classes.listSubheaderClose
            )}
            inset
          >
            Saved reports
          </ListSubheader>
          {/* {secondaryListItems} */}
        </List>
      </Drawer>
      {props.children}
    </>
  )
}

export default MasterSideBar
