// Core Files
import Globals from "core/globals"

// Material Ui + Styles
import { makeStyles } from "@material-ui/core"
import { blue } from "@material-ui/core/colors"

const drawerWidth = Globals.drawerWidth
const smallDrawerWidth = Globals.smallDrawerWidth

const useMasterLayoutStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  grow: {
    flexGrow: 1,
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShiftLeft: {
    [Globals.marginLeft]: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    [theme.breakpoints.down("xs")]: {
      width: `calc(100% - ${smallDrawerWidth}px)`,
      [Globals.marginLeft]: smallDrawerWidth,
    },
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarShiftRight: {
    [Globals.marginRight]: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    [theme.breakpoints.down("xs")]: {
      width: `calc(100% - ${smallDrawerWidth}px)`,
      [Globals.marginRight]: smallDrawerWidth,
    },
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButtonLeft: {
    [Globals.marginRight]: 36,
  },
  menuButtonRight: {
    [Globals.marginLeft]: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    [theme.breakpoints.down("xs")]: {
      width: `${smallDrawerWidth}px`,
    },
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    width: theme.spacing(7),
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down("xs")]: {
      width: theme.spacing(6),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  listSubheader: {
    "text-align": "center",
    padding: `0px`,
  },
  listSubheaderClose: {
    visibility: "hidden",
  },
  sideBarIcon: {
    "min-width": theme.spacing(5),
    [theme.breakpoints.down("xs")]: {
      "min-width": theme.spacing(4),
    },
  },
  sideBarNestedItem: {
    paddingLeft: theme.spacing(4),
  },
  sideBarListRoot: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  sideBarActive: {
    color: blue[400],
    fontWeight: "bold",
  },
}))

export default useMasterLayoutStyles
