// Dependencies
import React from "react"
import { useHistory } from "react-router-dom"
// Core Files & Hooks
import { trans } from "core/localization"
import useTable from "tables/hooks/useTable"
import useRowProvider from "tables/hooks/useRowProvider"
import { pageParam } from "core/formik/helpers/standardFormikHelper"
// Shared Components
import Tooltip from "materialui/ToolTip/MasterToolTip"
// Material Ui
import { IconButton } from "@material-ui/core"
import VisibilityIcon from "@material-ui/icons/Visibility"

function TableViewButton() {
  const history = useHistory()
  const { options } = useTable()
  const { record } = useRowProvider()
  const onButtonClick = (e) => {
    if (options.type === "Page") {
      history.push(`/${pageParam(history, 1)}/${record.id}`)
    }
  }
  return (
    <IconButton onClick={onButtonClick} color="primary">
      <Tooltip title={trans("view")}>
        <VisibilityIcon />
      </Tooltip>
    </IconButton>
  )
}

export default TableViewButton
