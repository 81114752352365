// Dependencies
import clsx from "clsx"
import { Field } from "formik"
import React, { useState } from "react"
import PropTypes from "prop-types"
// Core Files
import { trans } from "core/localization/index"
// Material Ui + Styles
import {
  IconButton,
  InputLabel,
  FormControl,
  OutlinedInput,
  FormHelperText,
  InputAdornment,
} from "@material-ui/core"
import useFormStyles from "materialui/Styles"
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import { validateInput, isNotValid } from "materialui/Validation/index"

function Password(props) {
  const classes = useFormStyles()
  const [showPassword, setShowPassword] = useState(false)
  const { label, name, required, variant, ...rest } = props

  let labelWidth = 85
  if (label === "c_password") labelWidth = 150

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }
  return (
    <Field name={name}>
      {({ field, form }) => {
        return (
          <FormControl
            required={required}
            variant={variant}
            className={clsx(classes.passwordOutlined, classes.formControl)}
            error={isNotValid(form, name)}
          >
            <InputLabel htmlFor={name}>{trans(label)}</InputLabel>
            <OutlinedInput
              id={name}
              {...rest}
              {...field}
              labelWidth={labelWidth}
              error={isNotValid(form, name)}
              className={classes.passwordOutlined}
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    aria-label="toggle password visibility"
                  >
                    {!showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText id={name} className={classes.helperTextError}>
              {validateInput(form, name)}
            </FormHelperText>
          </FormControl>
        )
      }}
    </Field>
  )
}

export default Password

Password.propTypes = {
  type: PropTypes.string,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  variant: PropTypes.string,
  label: PropTypes.string.isRequired,
}

Password.defaultProps = {
  required: false,
  fullWidth: true,
  type: "password",
  variant: "outlined",
}
