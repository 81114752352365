// Dependencies
import React from "react"
// Material Ui
import { ButtonGroup } from "@material-ui/core"

function ButtonsFormatter(props) {
  const { column, ...rest } = props
  const { buttons } = column

  return (
    <ButtonGroup>
      {buttons.map((Button, buttonIndex) => (
        <Button column={column} key={buttonIndex} {...rest} />
      ))}
    </ButtonGroup>
  )
}

export default ButtonsFormatter
