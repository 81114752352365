// Dependencies
import clsx from "clsx"
import React from "react"
import PropTypes from "prop-types"
// Material Ui
import { Grid, makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  alignLeft: {
    textAlign: "left",
  },
  alignCenter: {
    textAlign: "center",
  },
  alignRight: {
    textAlign: "right",
  },
}))

function GridItem(props) {
  const classes = useStyles()
  const { xs, sm, children, className, alignItems } = props
  const alingnment =
    alignItems === "left"
      ? classes.alignLeft
      : alignItems === "center"
      ? classes.alignCenter
      : classes.alignRight
  const classesName = clsx(alingnment, className)
  return (
    <Grid item xs={xs} sm={sm} children={children} className={classesName} />
  )
}

export default GridItem

GridItem.propTypes = {
  xs: PropTypes.number,
  sm: PropTypes.number,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  alignItems: PropTypes.oneOf(["left", "center", "right"]),
}

GridItem.defaultProps = {
  xs: 4,
  alignItems: "left",
}
