import cache from "core/cache"
import config from "core/configuration/config"

const inDevelopmentMode = process.env.NODE_ENV === "development"

// export const BASE_API_URL = "http://127.0.0.1:8000/api" // Local Url
export const BASE_API_URL = "/api" // Hosting Url

let initialTheme = "light"
if (!cache.get("themeMode")) {
  cache.set("themeMode", initialTheme)
}

config.set({
  // Services Configurations
  basePath: inDevelopmentMode ? "/" : "/some-directory-after-domain-name", // for production
  // A.K.A Endpoints
  endpoint: {
    baseUrl: BASE_API_URL,
    apiKey: "SP6YHG56IKLO90MNF4TGAQW23FVBG765",
  },
  locales: {
    en: {
      direction: "ltr",
    },
    ar: {
      direction: "rtl",
    },
  },
})
