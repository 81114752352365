// Dependencies
import React from "react"
import Is from "@flk/supportive-is"
import { useHistory } from "react-router-dom"
// Core Files & Hooks
import useFormik from "core/formik/hooks/useFormik"
import FormikControl from "core/formik/FormikControl"
import { pageParam } from "core/formik/helpers/standardFormikHelper"
import CategoryService from "modules/Categories/services/CategoryService"
import ProductDetailsOptions from "modules/Products/components/FormComponents/ProductDetails/ProductDetailItems"
// Shared Components
import SimpleTable from "tables/SimpleTable"
import ImageInput from "shared/components/Image/ImageInput"
import GridItem from "materialui/Grid/GridItem"
import ProductDetailForm from "./ProductDetails/ProductDetailForm"
import GridContainer from "materialui/Grid/GridContainer"
import ProductCard from "modules/Products/components/ProductView/ProductCard"

function BodyProduct({ record }) {
  const history = useHistory()
  const { setFieldValue } = useFormik()
  let recordId = pageParam(history, 2)
  if (!Is.numeric(recordId)) recordId = 0

  const defaultValues = record
    ? record.hasOwnProperty("categories")
      ? record.categories
      : []
    : []

  const initialImages = record
    ? record.hasOwnProperty("product_images")
      ? record.product_images
      : []
    : []

  const [images, setImages] = React.useState(initialImages)

  const handleAddImage = (file) => {
    var reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = function () {
      var base64data = reader.result
      const newImage = {
        image: base64data,
      }
      setImages((images) => {
        images.push(newImage)
        return [...images]
      })
    }
  }

  React.useEffect(() => {
    setFieldValue("product_images", images)
    // eslint-disable-next-line
  }, [images])

  let productDetialsArr = []
  let authorizedKeys = ["position_id", "available_quantity", "expiry_date"]
  record &&
    record.product_details.forEach((element) => {
      let newDetail = {}
      for (const [key] of Object.entries(element)) {
        if (authorizedKeys.includes(key)) {
          if (key === "position_id") {
            const positionObj = {
              id: element["position"].id,
              key: element["position"].position,
            }
            newDetail[key] = positionObj
          } else newDetail[key] = element[key]
        }
      }
      productDetialsArr.push(newDetail)
    })

  return (
    <GridContainer spacing={0}>
      {/* Row 1 */}
      <GridContainer>
        <GridItem>
          <FormikControl required label="code" control="number" name="code" />
        </GridItem>
        <GridItem>
          <FormikControl
            required
            name="barcode"
            label="barcode"
            control="number"
          />
        </GridItem>
        <GridItem>
          <FormikControl
            required
            backendV="name"
            label="categories"
            name="_categories_id"
            control="lazymautocomplete"
            defaultValues={defaultValues}
            request={() => CategoryService.list({ _all: true })}
          />
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12}>
          <FormikControl required name="name" label="name" control="input" />
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={6}>
          <FormikControl
            control="input"
            name="arabic_name"
            label="arabic_name"
          />
        </GridItem>
        <GridItem xs={6}>
          <FormikControl
            control="input"
            name="alternate_name"
            label="alternate_name"
          />
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem>
          <FormikControl required name="price" label="price" control="number" />
        </GridItem>
        <GridItem>
          <FormikControl
            required
            name="weight"
            label="weight"
            control="number"
          />
        </GridItem>
        <GridItem>
          <FormikControl
            control="number"
            name="box_quantity"
            label="box_quantity"
          />
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12}>
          <SimpleTable
            modalTComponent="productDetail"
            FormContent={ProductDetailForm}
            options={ProductDetailsOptions}
            records={record ? productDetialsArr : []}
          />
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12}>
          <FormikControl name="note" label="notes" control="textarea" />
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12}>
          <GridContainer justify="flex-start">
            {images.map((image, i) => (
              <GridItem key={i} xs={2}>
                <ProductCard imageObj={image} index={i} setImages={setImages} />
              </GridItem>
            ))}
            <GridItem xs={2}>
              <ImageInput onImageSelection={handleAddImage} />
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </GridContainer>
  )
}

export default BodyProduct
