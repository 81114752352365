// Dependencies
import React from "react"
// Shared Components
import MasterLayout from "./MasterLayout"

function AdminMasterLayout({ label, children }) {
  return <MasterLayout label={label}>{children}</MasterLayout>
}

export default AdminMasterLayout
