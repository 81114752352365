// Dependencies
import React from "react"
// Shared Components
import LazyTable from "./LazyTable"

function OptionedAdminTable(options) {
  return function (props) {
    const sendRequest = (params) => {
      return options.service.list(params)
    }
    return (
      <LazyTable
        options={options}
        request={sendRequest}
        label={options.title || options.table.heading}
      />
    )
  }
}

export default OptionedAdminTable
