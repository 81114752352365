export function searchRecords(
  e,
  type,
  searchText,
  tableInfoObj,
  setRequest,
  defReqObj
) {
  const { updateTableInfo, pagination, setCurrentPage } = tableInfoObj
  const perPage = pagination.perPage

  if (type === "clear") {
    setRequest(defReqObj)
    setCurrentPage(0)
    updateTableInfo({ perPage, page: 1 }, "cancelSearch")
  }
  if (e.key === "Enter") {
    setRequest(defReqObj)
    setCurrentPage(0)
    updateTableInfo({ _q: searchText, perPage, page: 1 })
  }
}
