// Important Shared Files
import "shared/config"
import "shared/locales/en"
import "shared/locales/ar"

// Core files
import Reactor from "core/reactor"

// grab all service providers from all modules
import AppServiceProviders from "modules/App/serviceProvider"
import UsersServiceProviders from "modules/Users/serviceProvider"
import SyncServiceProviders from "modules/Updates/serviceProvider"
import OrdersServiceProviders from "modules/Orders/serviceProvider"
import ProductsServiceProviders from "modules/Products/serviceProvider"
import EmployeesServiceProviders from "modules/Employees/serviceProvider"
import CustomersServiceProviders from "modules/Customers/serviceProvider"
import CategoriesServiceProviders from "modules/Categories/serviceProvider"

const reactor = new Reactor()

// config()

reactor.registerServiceProviders([
  //service providers list
  AppServiceProviders,
  UsersServiceProviders,
  EmployeesServiceProviders,
  CustomersServiceProviders,
  CategoriesServiceProviders,
  ProductsServiceProviders,
  OrdersServiceProviders,
  SyncServiceProviders,
])

// Start the application
reactor.react()
