// Material Ui
import { styled } from "@material-ui/core"
// Core Files
import Link from "core/Routes/components/Link"

export const Div = styled("div")((props) => {
  // const { theme } = props
  return {
    margin: "50px 0px 50px 0px",
    textAlign: "center",
  }
})

export const ItemLink = styled(Link)({
  color: "#333",
})
