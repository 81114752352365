// Dependencies
import React from "react"
// Material Ui
import CancelIcon from "@material-ui/icons/Cancel"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined"
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded"

const masterFilterCheckboxs = (allowedBtns) => {
  const masterCheckboxs = [
    {
      key: "active",
      checked: false,
      group: "active",
      color: "#18cc3c",
      heading: "active",
      checkedIcon: <CheckCircleIcon />,
      icon: <CheckCircleOutlineRoundedIcon />,
      reqParams: { _active: 1 },
    },
    {
      checked: false,
      group: "active",
      key: "not_active",
      heading: "not_active",
      color: "rgb(213, 21, 21)",
      checkedIcon: <CancelIcon />,
      icon: <CancelOutlinedIcon />,
      reqParams: { _active: 0 },
    },
    {
      key: "new",
      group: "new",
      checked: false,
      heading: "new",
      color: "#18cc3c",
      checkedIcon: <CheckCircleIcon />,
      icon: <CheckCircleOutlineRoundedIcon />,
      reqParams: { _new: 1 },
    },
    {
      group: "new",
      key: "not_new",
      checked: false,
      heading: "not_new",
      color: "rgb(213, 21, 21)",
      checkedIcon: <CancelIcon />,
      icon: <CancelOutlinedIcon />,
      reqParams: { _new: 0 },
    },
  ]
  return masterCheckboxs.filter((btn) => allowedBtns.includes(btn.group))
}

export default masterFilterCheckboxs
