import localization from "core/localization/index"

localization.extend("en", {
  order: "order",
  ready: "Ready",
  bonus: "Bonus",
  total: "Total",
  order_: "Order",
  orders: "Orders",
  oneUnit: "One Unit",
  canceled: "Canceled",
  discount: "Discount",
  quantity: "Quantity",
  not_ready: "Not Ready",
  ordersList: "Orders List",
  orderDetail: "Order Detail",
  quantityInBox: "Quantity In Box",
  piac: "Product is already chosen",
  quantityInUnit: "Quantity In Unit",
  orderDetailsList: "Order Details List",
  orderAdded: "Order added successfully",
  orderUpdated: "Order updated successfully",
})
