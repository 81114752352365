// Dependencies
import React from "react"
//Core Files & Hooks
import useTable from "tables/hooks/useTable"
import { trans } from "core/localization/index"
import asyncForEach from "core/helpers/AsyncForEach"
import useRowProvider from "tables/hooks/useRowProvider"
import ProductDetailsService from "modules/Products/services/ProductDetailsService"
// Shared Components
import Tooltip from "materialui/ToolTip/MasterToolTip"
import MasterConfirm from "layout/Confirm/MasterConfirm"
import {
  AlertMessageDialog,
  SuccessAlertMessage,
} from "layout/Modal/AlertMessage"
// Material Ui + Styles
import { IconButton } from "@material-ui/core"
import ReplyIcon from "@material-ui/icons/Reply"

const alertInitialState = {
  alertType: "error",
  displayAlert: false,
  displaySuccessAlert: false,
  displayConfrimation: false,
  title: trans("confirmation"),
  message: trans("submitOrder"),
  displayConfrimationStock: false,
}
const alertReducer = (state = alertInitialState, action) => {
  switch (action.type) {
    case "UPDATE_ALERT":
      return { ...state, ...action.data }
    default:
      return state
  }
}
const updateAlert = (data) => ({ type: "UPDATE_ALERT", data })

function ToolBarSendAwayButton() {
  const { record = {} } = useRowProvider()
  const { options, tableRecordsObj, tableSelectedRow } = useTable()

  const { selected } = tableSelectedRow
  const { tableRecords } = tableRecordsObj

  const [alert, dispatchAlert] = React.useReducer(
    alertReducer,
    alertInitialState
  )

  let orders = tableRecords.filter((record) => selected.includes(record.id))

  const handleOnConfrim = async () => {
    orders = orders.length > 0 ? orders : [record]
    let flagMessage = 0
    await asyncForEach(orders, async (order, i) => {
      const messages = []
      const { order_details } = order
      await asyncForEach(order_details, async (item, i) => {
        const { product } = item
        const { product_details } = product
        const consumed_quantity = Number(item.quantity)
        const box_quantity = Number(product.box_quantity)
        const consumed_boxs = parseInt(consumed_quantity / box_quantity)
        const consumed_units = consumed_quantity % box_quantity
        const percentage_consumed_unit = Number(
          consumed_units / box_quantity
        ).toFixed(2)
        const total_consumed =
          Number(consumed_boxs) + Number(percentage_consumed_unit)

        let cAvailableQty = 0
        let flagConsumedCorrectly = 0
        const sortedByExpiredDate = product_details.sort((a, b) => {
          return new Date(a.expiry_date) - new Date(b.expiry_date)
        })

        // Submit Order
        await asyncForEach(sortedByExpiredDate, async (e, i) => {
          cAvailableQty += Number(e.available_quantity)
          // flagConsumedCorrectly used like a break statement, in order to prevent entering again
          if (
            cAvailableQty > Number(total_consumed) &&
            !flagConsumedCorrectly
          ) {
            flagConsumedCorrectly = 1
            const result = Number(cAvailableQty) - Number(total_consumed)
            ProductDetailsService.update(product_details[i].id, {
              available_quantity: result,
            }).catch((err) => displayError(err))
          } else if (!flagConsumedCorrectly) {
            // Consume old date (Product Details) - Remove By id
            ProductDetailsService.delete(product_details[i].id).catch((err) =>
              displayError(err)
            )
          }
        })

        if (!flagConsumedCorrectly && product_details.length) {
          // All Stock is consumed for this product
          messages.push(`${trans("consumeAll", product.name)}`)
        } else if (!flagConsumedCorrectly && !product_details.length) {
          // No stock available
          messages.push(
            `${product.name} ${trans("emptyStock")} ${trans(
              "productIsntExist"
            )}`
          )
          // Here we have to think how to remove the product, but if you think deeply
          // order before came to this point, will be prepared by the emplyoees so how
          // it could be prepared and the product is not exist, so you will know about this
          // before it happened by employee when make a sign on the order if all is ok or not.
        }
      })
      if (messages.length) {
        flagMessage = 1
        dispatchAlert(
          updateAlert({
            alertType: "info",
            message: messages,
            displayAlert: true,
            title: trans("notes"),
          })
        )
      }
      // Update Order status to submited
      delete order["order_details"]
      options.service.update(order.id, {
        id: order.id,
        code: order.code,
        status: "submitted",
        total_price: order.total_price,
      })
    })
    setTimeout(() => {
      if (!flagMessage)
        dispatchAlert(
          updateAlert({
            message: [],
            alertType: "success",
            displaySuccessAlert: true,
            title: trans("submittedSuccess"),
          })
        )
    }, 400)
  }

  const displayError = (err) =>
    dispatchAlert(
      updateAlert({
        displayAlert: true,
        alertType: "error",
        title: trans("error"),
        message: err.response.data,
      })
    )

  const handleOnConfrimStock = () => {}

  const displayAlert = (status) =>
    dispatchAlert(updateAlert({ displayAlert: status }))

  const displaySuccessAlert = (status) =>
    dispatchAlert(updateAlert({ displaySuccessAlert: status }))

  const displayConfirm = (status) =>
    dispatchAlert(updateAlert({ displayConfrimation: status }))

  const displayConfirmStorck = (status) =>
    dispatchAlert(updateAlert({ displayConfrimationStock: status }))

  return (
    <>
      <MasterConfirm
        title={alert.title}
        message={alert.message}
        onConfirm={handleOnConfrim}
        open={alert.displayConfrimation}
        onClose={() => displayConfirm(false)}
      />
      <MasterConfirm
        title={alert.title}
        message={alert.message}
        onConfirm={handleOnConfrimStock}
        open={alert.displayConfrimationStock}
        onClose={() => displayConfirmStorck(false)}
      />
      <SuccessAlertMessage
        title={alert.title}
        type={alert.alertType}
        messages={alert.message}
        open={alert.displaySuccessAlert}
        onClose={() => displaySuccessAlert(false)}
      />
      <AlertMessageDialog
        title={alert.title}
        type={alert.alertType}
        messages={alert.message}
        open={alert.displayAlert}
        onClose={() => displayAlert(false)}
      />
      <IconButton color="primary" onClick={() => displayConfirm(true)}>
        <Tooltip title={trans("sendAway")}>
          <ReplyIcon />
        </Tooltip>
      </IconButton>
    </>
  )
}

export default ToolBarSendAwayButton
