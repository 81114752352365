// Core Files
import { setMetaData } from "core/configuration/metaData"

function Helmet({ title, description }) {
  title && setMetaData("title", title)
  description && setMetaData("description", description)
  return ""
}

export default Helmet
