// Dependencies
import React from "react"
import PropTypes from "prop-types"
// Core Files
import { trans } from "core/localization/index"
// Shared Components
import CheckBoxButtonNF from "./CheckBoxButtonNF"
// Material Ui + Custom Style
import {
  FormLabel,
  FormGroup,
  FormControl,
  FormControlLabel,
} from "@material-ui/core"
import useFormStyles from "materialui/Styles"

function CheckBoxGroup(props) {
  const classes = useFormStyles()
  const { label, row, required, options, setOptions } = props

  const handleOnChange = (e, heading) => {
    setOptions((elements) => {
      elements.forEach((element, i) => {
        if (element.heading === heading) {
          elements[i].checked = e.target.checked
          // unCheck Group field
          elements.forEach((subElem, sI) => {
            if (
              subElem.group === element.group &&
              subElem.heading !== element.heading
            )
              elements[sI].checked = false
          })
        }
      })
      return [...elements]
    })
  }

  const DisplayCheckBoxButtons = () => {
    return options.map((option) => {
      let { key, request, group, reqParams, ...rest } = option
      return (
        <FormControlLabel
          key={key}
          label={trans(key)}
          control={
            <CheckBoxButtonNF key={key} onChange={handleOnChange} {...rest} />
          }
        />
      )
    })
  }
  return (
    <FormControl
      required={required}
      component="fieldset"
      className={classes.formControl}
    >
      <FormLabel component="legend">{trans(label)}</FormLabel>
      <FormGroup row={row}>
        <DisplayCheckBoxButtons />
      </FormGroup>
    </FormControl>
  )
}

export default CheckBoxGroup

CheckBoxGroup.propTypes = {
  row: PropTypes.bool,
  required: PropTypes.bool,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  setOptions: PropTypes.func.isRequired,
}

CheckBoxGroup.defaultProps = {
  row: false,
  required: false,
}
