// Dependencies
import React from "react"
// Shared Components & Hooks
import { trans } from "core/localization"
import Checkbox from "materialui/CheckBox"
import useTable from "tables/hooks/useTable"
// Material Ui & Styles
import {
  TableRow,
  TableCell,
  TableSortLabel,
  TableHead as MaterialTableHead,
} from "@material-ui/core"
import useFormStyles from "materialui/Styles"

function TableHead() {
  const classes = useFormStyles()
  const {
    tableOrderByObj,
    tableRecordsObj,
    tableColumnsObj,
    tableSelectedRow,
  } = useTable()
  const [rowCount, setRowCount] = React.useState(0)
  const [numSelected, setNumSelected] = React.useState(0)

  const { tableRecords } = tableRecordsObj
  const { tableColumns } = tableColumnsObj
  const { selected, setSelected } = tableSelectedRow
  const { orderBy, setOrderBy, order, setOrder } = tableOrderByObj

  React.useEffect(() => {
    selected && setNumSelected(selected.length)
    tableRecords && setRowCount(tableRecords.length)
  }, [selected, tableRecords])

  const handleOnClick = (currentOrderBy) => {
    const isAsc = orderBy === currentOrderBy && order === "asc"
    setOrderBy(currentOrderBy)
    setOrder(isAsc ? "desc" : "asc")
  }

  const handleSelectAll = (checked) => {
    if (checked) {
      const newSelecteds = tableRecords.map((n) => n.id)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const filteredColumns = tableColumns.filter((column) => !column.filter)
  const columns = filteredColumns.map((column) => {
    return column.heading !== "CheckBox" ? (
      <TableCell padding="none" key={column.heading} className={classes.bold}>
        <TableSortLabel
          onClick={() => handleOnClick(column.key)}
          direction={orderBy === column.key ? order : "asc"}
          active={column.key === orderBy && column.key !== "actions"}
        >
          {trans(column.heading)}
          {orderBy === column.key ? (
            <span className={classes.visuallyHidden}>
              {order === "desc" ? "sorted descending" : "sorted ascending"}
            </span>
          ) : null}
        </TableSortLabel>
      </TableCell>
    ) : (
      <TableCell key={column.heading} padding="checkbox">
        <Checkbox
          onChange={handleSelectAll}
          checked={rowCount > 0 && numSelected === rowCount}
          indeterminate={numSelected > 0 && numSelected < rowCount}
        />
      </TableCell>
    )
  })

  return (
    <MaterialTableHead>
      <TableRow>{columns}</TableRow>
    </MaterialTableHead>
  )
}

export default TableHead
