// Dependencies
import clsx from "clsx"
import React from "react"
// Shared Components
import ProgressBar from "materialui/ProgressBar"
// Material Ui
import { Backdrop as MaterialBackdrop, makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  lightBackdrop: {
    color: "#333",
    backgroundColor: "rgba(255,255,255,0.5)",
  },
}))

export default function Backdrop(props) {
  const classes = useStyles()
  const { open, onClick, className, position = "absolute" } = props
  const backdropClass = clsx(classes.backdrop, className)

  return (
    <div>
      <MaterialBackdrop
        open={open}
        onClick={onClick}
        style={{ position }}
        className={backdropClass}
      >
        <ProgressBar control="circular" />
      </MaterialBackdrop>
    </div>
  )
}

export function LightBackdrop({ className, ...props }) {
  const classes = useStyles()
  const backdropClass = clsx(classes.lightBackdrop, className)
  return <Backdrop {...props} className={backdropClass} />
}
