// Dependencies
import React, { useState } from "react"

// Core files
import cache from "core/cache"
import Globals from "core/globals"

// Material Ui + Styles
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles"
import {
  // cyan,
  // lime,
  // teal,
  // indigo,
  // blue,
  // blueGrey,
  // deepOrange,
  grey,
  lightBlue,
} from "@material-ui/core/colors"

const lightLeftTheme = createMuiTheme({
  direction: "ltr",
  palette: {
    type: "light",
    primary: {
      main: lightBlue[900],
    },
    secondary: {
      main: "#f5a100",
    },
  },
})
const lightRightTheme = createMuiTheme({
  direction: "rtl",
  palette: {
    type: "light",
    primary: {
      main: lightBlue[900],
    },
    secondary: {
      main: "#f5a100",
    },
  },
})

const darkLeftTheme = createMuiTheme({
  direction: "ltr",
  palette: {
    type: "dark",
    primary: { main: grey[400] },
    // secondary: { main: blueGrey[900] },
  },
})
const darkRightTheme = createMuiTheme({
  direction: "rtl",
  palette: {
    type: "dark",
    primary: { main: grey[400] },
    // secondary: { main: blueGrey[900] },
  },
})

const WithTheme = (props) => {
  const { children, darkMode, direction } = props
  let defaultTheme
  if (darkMode) {
    if (direction === "ltr") defaultTheme = darkLeftTheme
    else defaultTheme = darkRightTheme
  } else {
    if (direction === "ltr") defaultTheme = lightLeftTheme
    else defaultTheme = lightRightTheme
  }
  return <ThemeProvider theme={defaultTheme}>{children}</ThemeProvider>
}

export const withTheme = (WrappedComponent) => {
  return (props) => {
    let initialTheme = cache.get("themeMode") === "dark" ? true : false
    const [darkMode, setDarkMode] = useState(initialTheme)
    const [directionMode, setDirectionMode] = useState(Globals.direction)
    const handleDirectionWithTheme = (direction) => {
      setDirectionMode(direction)
    }
    return (
      <WithTheme darkMode={darkMode} direction={directionMode}>
        <WrappedComponent
          {...props}
          darkMode={darkMode}
          setDarkMode={setDarkMode}
          setDirectionMode={handleDirectionWithTheme}
        />
      </WithTheme>
    )
  }
}
