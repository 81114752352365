// Dependencies
import React from "react"
// Core Files
import { trans } from "core/localization"
// Shared Components
import {
  RedBadge,
  BlueBadge,
  GreenBadge,
  BlackBadge,
  OrangeBadge,
} from "materialui/Badge/MasterBadge"
function StatusFormatter(props) {
  const { column } = props
  const status = column.status
  if (column.value === status[0])
    return <OrangeBadge children={trans(column.value)} />
  if (column.value === status[1])
    return <GreenBadge children={trans(column.value)} />
  if (column.value === status[2])
    return <RedBadge children={trans(column.value)} />
  if (column.value === status[3])
    return <BlueBadge children={trans(column.value)} />
  return <BlackBadge children={trans(column.value)} />
}

export default StatusFormatter
