// Core Files
import TabPage from "core/TabPage"
import Product from "./components/index"
import Gardian from "core/middlewares/gardian"
import Position from "./components/Positions/index"

export default function (route) {
  route("/products", Product, [Gardian])
  route("/positions", Position, [Gardian])
  route("/products/:id", TabPage, [Gardian])
}
