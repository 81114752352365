import localization from "core/localization/index"

localization.extend("en", {
  filterList: "Filter Columns",
  moreOptions: "More Options",
  table: {
    labelRowsPerPage: "Show",
    displayedRows: " Display %d-%d of %d",
  },
})
