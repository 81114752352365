// Dependencies
import Is from "@flk/supportive-is"
// Core Files
import { trans } from "core/localization"

/**
 * bring page options dynamically by url
 *
 * @param {string} history
 * @returns {object}
 */
export function loadOptions(history) {
  let component = pageParam(history, 1)
  let pageSource = upperCaseFirst(component)
  let singlePageSource = pageSource.substring(0, pageSource.length - 1)
  let options = require(`modules/${pageSource}/components/${singlePageSource}Items.js`)
  return options.default
}

/**
 *
 * @param {string} value
 * @param {string}
 */
export function upperCaseFirst(value) {
  return value.charAt(0).toUpperCase() + value.slice(1)
}

/**
 * detect page type if it's Add or Edit
 *
 * @param {string} url
 * @returns {string|number}
 */
export function pageParam(history, num) {
  return history.location.pathname.split("/")[num]
}

/**
 *
 * @param {string} history
 * @returns {string}
 */
export function pageAction(history) {
  let action = pageParam(history, 2)
  return action === "create" ? "create" : Is.numeric(action) ? "edit" : null
}

/**
 * get page title
 *
 * @param {object} options
 * @param {string} action
 * @returns {string}
 */
export function pageInfo(options, action) {
  let pageTitleFirst = action === "edit" ? "editItem" : "addItem"
  return trans(pageTitleFirst, trans(options.formOptions.modalLable))
}

/**
 * get default value of record for lazy auto complete
 *
 * @param {object} record
 * @param {string} backendV
 * @returns {string}
 */
export function getDefautlValue(record, backendV) {
  if (!Is.undefined(record) && record.hasOwnProperty(backendV)) {
    if (record[backendV] === null || record[backendV].length === 0) return null
    else {
      if (Is.array(record[backendV])) return record[backendV][0]
      return record[backendV]
    }
  } else return null
}

/**
 * Check if string is an integer
 *
 * @param {string} number
 * @param {boolean}
 */
export function isPhoneNumber(number) {
  return /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g.test(number)
}

/**
 * Get Current Date in mm/dd/yyyy format
 *
 * @return {Date}
 */
export function getDatePickerFormatte(value = new Date()) {
  let d = new Date(value),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear()

  if (month.length < 2) month = "0" + month
  if (day.length < 2) day = "0" + day
  const date = [year, month, day].join("-")
  return date
}

export function getDatePickerFormatteRTL(value = new Date()) {
  let d = new Date(value),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear()

  if (month.length < 2) month = "0" + month
  if (day.length < 2) day = "0" + day
  const date = [day, month, year].join("-")
  return date
}

/**
 * Process the values and check if there are special properties that need to handle it before sending the request
 * i.e: if object contains a property start with underscore (_), that mean we have to delete this symbol and replace
 * the value of object to just and string id
 *
 * @param {object} values
 * @returns {object}
 */
export function processValues(values) {
  for (const [key] of Object.entries(values)) {
    if (key[0] === "_") {
      if (Array.isArray(values[key])) {
        values[key.substring(1, key.length)] = values[key].map(
          (item) => item.id
        )
      } else if (Is.object(values[key])) {
        values[key.substring(1, key.length)] = values[key].id
      } else values[key.substring(1, key.length)] = values[key]
      delete values[key]
    }
  }
  return values
}

/**
 * Filter object from null values, empty values or custom values
 *
 * @param {object} values
 * @returns {object}
 */
export function filterObject(values) {
  Object.keys(values).forEach((key) => {
    if (key === "action") delete values[key]
    if (values[key] === "") values[key] = null
  })
  return values
}
