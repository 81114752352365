// Dependencies
import React from "react"
import PropTypes from "prop-types"
import { Random } from "reinforcements"
// Material Ui
import { Checkbox } from "@material-ui/core"

function CheckBoxButtonFormik(props) {
  let { id, size, disabled, color, field, value, ...rest } = props
  return (
    <Checkbox
      id={id}
      {...rest}
      {...field}
      size={size}
      color={color}
      value={value}
      disabled={disabled}
      checked={field.value.includes(value)}
    />
  )
}

export default CheckBoxButtonFormik

CheckBoxButtonFormik.propTypes = {
  id: PropTypes.string,
  icon: PropTypes.node,
  size: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  checkedIcon: PropTypes.node,
  value: PropTypes.string.isRequired,
  field: PropTypes.object.isRequired,
}

CheckBoxButtonFormik.defaultProps = {
  size: "medium", // small / medium
  id: Random.id(),
  disabled: false,
  color: "secondary", // default / primary / secondary (this is the default for the Radio)
}
