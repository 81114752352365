// Dependencies
import { Obj } from "reinforcements"
import React, { useState } from "react"
// Core Files
import "./locales/ar"
import "./locales/en"
import { trans } from "core/localization/index"
// Shared Components
import TableBody from "./TableComponents/TableBody"
import TableHead from "./TableComponents/TableHead"
import TablePagination from "./TableComponents/TablePagination"
import { LightBackdrop } from "materialui/BackDrop/BackDrop"
import EnhancedToolBar from "tables/TableComponents/TableToolBars/EnhancedToolBar"
// Material Ui + Styles
import TableProvider from "./providers/TableProvider"
import useMasterTableStyles from "./CSS/MasterTableStyle"
import { Paper, Table, TableContainer } from "@material-ui/core"

export default function MasterTable(props) {
  let { options, records, pagination, isLoading, onChange } = props
  const classes = useMasterTableStyles()
  const [order, setOrder] = useState("asc")
  const [selected, setSelected] = React.useState([])
  const [currentTableInfo, setTableInfo] = useState({})
  const [currentPage, setCurrentPage] = React.useState(0)
  const [tableRecords, setTableRecords] = useState(records)
  const [orderBy, setOrderBy] = useState(options.table.orderBy)
  const [tableColumns, setTableColumns] = useState(options.table.columns)

  const tableSelectedRow = { selected, setSelected }
  const tableRecordsObj = { tableRecords, setTableRecords }
  const tableColumnsObj = { tableColumns, setTableColumns }
  const tableOrderByObj = { order, setOrder, orderBy, setOrderBy }

  React.useEffect(() => {
    setTableRecords(records)
  }, [records])

  React.useEffect(() => {
    pagination && setCurrentPage(pagination.current_page - 1)
  }, [pagination])

  const updateTableInfo = (newInfo, type = "normal") => {
    let newTableInfo = newInfo
    if (type === "normal") newTableInfo = Obj.merge(currentTableInfo, newInfo)
    setTableInfo(newTableInfo)
    onChange(newTableInfo)
  }
  const setPageNumber = (pageNumber) => {
    updateTableInfo({ page: pageNumber })
  }
  const setItemsPerPage = (itemPerPage) => {
    updateTableInfo({ itemPerPage, page: 1 })
  }

  const tableInfoObj = {
    pagination,
    currentPage,
    setPageNumber,
    setCurrentPage,
    setItemsPerPage,
    updateTableInfo,
  }

  const tableOptions = {
    options,
    tableInfoObj,
    tableRecordsObj,
    tableOrderByObj,
    tableColumnsObj,
    tableSelectedRow,
  }

  return (
    <TableProvider.Provider value={tableOptions}>
      <Paper className={classes.paper}>
        <EnhancedToolBar label={trans(options.table.heading)} />
        <LightBackdrop open={isLoading} />
        <TableContainer className={classes.container}>
          <Table stickyHeader className={classes.table}>
            <TableHead />
            <TableBody isLoading={isLoading} />
          </Table>
        </TableContainer>
        {pagination && <TablePagination />}
      </Paper>
    </TableProvider.Provider>
  )
}
