// Dependencies
import React from "react"
import { Field } from "formik"
import PropTypes from "prop-types"
import { Random } from "reinforcements"
// Core Files
import { trans } from "core/localization"
import useRequest from "core/hooks/useRequest"
import { isNotValid, validateInput } from "materialui/Validation"
// Shared Componentes
import ProgressBar from "materialui/ProgressBar"
// Material Ui
import useFormStyles from "materialui/Styles"
import TextField from "@material-ui/core/TextField"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import { Checkbox, FormControl } from "@material-ui/core"
import MaterialAutocomplete from "@material-ui/lab/Autocomplete"
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"

const checkedIcon = <CheckBoxIcon fontSize="small" />
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />

function LazyMAutoCompleteFormik(props) {
  let {
    name,
    label,
    options,
    request,
    required,
    backendV,
    defaultValues,
    defaultOption,
    ...rest
  } = props

  const classes = useFormStyles()
  const [response] = useRequest(() => request())
  const [loading, setLoading] = React.useState(true)
  const [items, setItems] = React.useState(options || [])
  const [checkedValues, setCheckedValues] = React.useState([])
  defaultValues = defaultValues.map((item) => item.id)

  const mapResponse = (response) => {
    return response.data.map((record) => ({
      id: record.id,
      key: record[backendV],
    }))
  }

  if (response && loading) {
    setLoading(false)
    setItems(mapResponse(response))
  }

  React.useEffect(() => {
    setCheckedValues(
      items.filter((item) => {
        return defaultValues.includes(item.id)
      })
    )
    // eslint-disable-next-line
  }, [items])

  return (
    <Field name={name}>
      {({ field, form }) => {
        return (
          <FormControl
            error={isNotValid(form, name)}
            className={classes.formControl}
          >
            <MaterialAutocomplete
              {...rest}
              options={items}
              loading={loading}
              value={checkedValues}
              getOptionLabel={(option) => option.key}
              // value={values}
              // getOptionLabel={(option) => getOptionLabel(option)}
              // getOptionSelected={(option, value) => getSelected(option, value)}
              onChange={(e, value) => {
                setCheckedValues(value)
                form.setFieldValue(name, value)
              }}
              renderOption={(option, { selected }) => {
                return (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checked={selected}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                    />
                    {option.key || option.name}
                  </React.Fragment>
                )
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...field}
                    {...params}
                    variant="outlined"
                    required={required}
                    label={trans(label)}
                    error={isNotValid(form, name)}
                    helperText={validateInput(form, name)}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading ? <ProgressBar control="circular" /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )
              }}
            />
          </FormControl>
        )
      }}
    </Field>
  )
}

export default LazyMAutoCompleteFormik

LazyMAutoCompleteFormik.propTypes = {
  id: PropTypes.string,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  limitTags: PropTypes.number,
  label: PropTypes.string.isRequired,
  request: PropTypes.func.isRequired,
  disableCloseOnSelect: PropTypes.bool,
}

LazyMAutoCompleteFormik.defaultProps = {
  options: [],
  limitTags: 3,
  multiple: true,
  required: false,
  id: Random.id(),
  disabled: false,
  fullWidth: true,
  disableCloseOnSelect: true,
}
