import localization from "core/localization/index"

localization.extend("en", {
  root: "Root",
  value: "Value",
  category: "category",
  category_: "Category",
  tree_lvl: "Tree Level",
  categories: "Categoryies",
  category_parent: "Parent",
  category_lvl: "Category Level",
  products_count: "Products Count",
  categoriesList: "Categories List",
  category_value: "Categories Value",
  categoryAdded: "Category added successfully",
  categoryUpdated: "Category updated successfully",
})
