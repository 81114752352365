// Shared Components
import ProductDetailForm from "./ProductDetailForm"
import DateFormatter from "tables/Formatters/DateFormatter"
import NumberFormatter from "tables/Formatters/NumberFormatter"
import ButtonsFormatter from "tables/Formatters/ButtonsFormatter"
import Edit from "tables/EditableTableComponents/TableActions/Edit"
import Delete from "tables/EditableTableComponents/TableActions/Delete"
import SetUpProductDetailForm from "modules/Products/helpers/SetUpProductDetailForm"

export default {
  type: "Modal",
  title: "productDetails",
  formOptions: {
    form: ProductDetailForm,
    modalLable: "productDetail",
    deleteKey: "position_id.key",
    formikLable: "productDetail_",
    setUpForm: SetUpProductDetailForm,
  },
  table: {
    orderBy: "expiry_date",
    heading: "productDetailsList",
    initialObj: { position: "", available_quantity: "", expiry_date: "" },
    columns: [
      {
        key: "actions",
        heading: "actions",
        buttons: [Delete, Edit],
        formatter: ButtonsFormatter,
      },
      {
        type: "select",
        key: "position_id",
        heading: "positions",
      },
      {
        tpye: "number",
        key: "available_quantity",
        formatter: NumberFormatter,
        heading: "available_quantity",
      },
      {
        type: "date",
        key: "expiry_date",
        heading: "expiry_date",
        formatter: DateFormatter,
      },
    ],
  },
}
