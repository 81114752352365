// Dependencies
import * as Yup from "yup"
import Is from "@flk/supportive-is"

export default class SetUpCategoryForm {
  constructor(record) {
    let active
    if (Is.undefined(record.active)) active = true
    else if (Is.boolean(record.active)) active = record.active
    else if (Is.string(record.active))
      active = Number(record.active) === 1 ? true : false
    else active = record.active === 1 ? true : false

    this.initialValues = {
      active,
      id: record.id,
      name: record.name || "",
      code: record.code || "",
      value: record.value || "10",
      _parent: record.parent || "0",
      arabic_name: record.arabic_name || "",
      alternate_name: record.alternate_name || "",
    }

    this.validationSchema = Yup.object({
      active: Yup.boolean(),
      arabic_name: Yup.string(),
      alternate_name: Yup.string(),
      _parent: Yup.string().nullable(),
      value: Yup.number().min(1).max(100),
      name: Yup.string().required("Required"),
      code: Yup.string().required("Required"),
    })
  }

  getInitialValues() {
    return this.initialValues
  }
  getValidationSchema() {
    return this.validationSchema
  }
}
