import localization from "core/localization/index"

localization.extend("en", {
  data: "Data",
  sync: "sync",
  sync_: "Sync",
  sync__: "Sync",
  action: "Action",
  _sync: "Sync records",
  syncList: "Sync List",
  target_table: "Target Table",
  salespersons_count: "Salespersons Count",
})
