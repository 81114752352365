// Dependencies
import React from "react"
import * as XLSX from "xlsx"
import PropTypes from "prop-types"
// Core Files & Hooks
import { trans } from "core/localization"
import useTable from "tables/hooks/useTable"
import asyncForEach from "core/helpers/AsyncForEach"
import { getElementRes } from "tables/helpers/FileHelpers"
// Shared Components
import MasterConfirm from "layout/Confirm/MasterConfirm"
import {
  AlertMessageDialog,
  SuccessAlertMessage,
  WaitingAlertMessage,
} from "layout/Modal/AlertMessage"
import HiddenInputFile from "shared/components/File/HiddenInputFile"
import Globals from "core/globals"

function ImportFile({ confirming, setConfirm }) {
  const { options } = useTable()
  const fileInputRef = React.useRef()
  const fileOrder = options.fileOptions.order
  const confirmationMessage = [
    trans("import_file_message"),
    trans("import_file_message_c1"),
    trans("import_file_message_c2"),
    `(${fileOrder.join(", ")})`,
  ]

  const [alerting, setAlert] = React.useState(false)
  const [alertTitle, setAlertTitle] = React.useState("")
  const [alertMessage, setAlertMessage] = React.useState([])

  const [successAlert, setSuccessAlert] = React.useState(false)
  const [successMessage, setSuccessMessage] = React.useState([])

  const [waitingAlert, setWaitingAlert] = React.useState(false)
  const [waitingAlertValue, setWaitingAlertValue] = React.useState(0)

  const handleOnConfirm = () => fileInputRef.current.click()

  const onFileSelection = (e) => {
    const selectedFile = e.target.files[0]
    const reader = new FileReader()
    reader.onload = async (evt) => {
      // evt = on_file_select event
      /* Parse data */
      const bstr = evt.target.result
      const wb = XLSX.read(bstr, { type: "binary", cellDates: true })
      /* Get first worksheet */
      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 })
      /* Update state */

      const keys = data[0]
      data.shift()
      const enhancedData = []
      const dividedBy = Globals.requestArrayLength
      const numberOfArrays = Math.ceil(data.length / dividedBy)
      for (let i = 0; i < numberOfArrays; i++) {
        let from = i * dividedBy,
          to = from + dividedBy
        if (to < dividedBy) to = data.length
        const subArr = data.slice(from, to)
        enhancedData.push({ items: subArr })
      }
      const request = options.service
      const increasingValue = 100 / enhancedData.length
      if (JSON.stringify(keys) === JSON.stringify(fileOrder)) {
        setAlertTitle("")
        setWaitingAlert(true)
        setWaitingAlertValue(0)
        let errors = []
        let numFailure = 0
        await asyncForEach(enhancedData, async (values, i) => {
          const res = await getElementRes(
            keys,
            values,
            request,
            increasingValue,
            setWaitingAlertValue
          )
          if (res.hasOwnProperty("errors")) {
            errors.push({ title: res.message, messages: res.errors })
            numFailure += 1
          }

          // Catch Res
          // setWaitingAlert(false)
          //   setAlert(true)
          //   setAlertMessage([
          //     trans("import_file_message_c3_error"),
          //     err.response.errors,
          //   ])
        })

        if (errors.length > 0) {
          setWaitingAlert(false)
          setAlert(true)
          setAlertTitle(`${trans("num_of_errors")}: [${numFailure}]`)
          setAlertMessage(errors)
        } else {
          setTimeout(() => {
            setWaitingAlert(false)
            setSuccessAlert(true)
            setSuccessMessage(trans("num_of_succes", data.length - numFailure))
          }, 500)
        }

        // Promise.all(
        //   data.map((values) =>
        //     getElementRes(
        //       keys,
        //       values,
        //       request,
        //       increasingValue,
        //       setWaitingAlertValue
        //     )
        //   )
        // )
        //   .then((responseArr) => {
        //     let errors = []
        //     let numFailure = 0
        //     responseArr.forEach((res) => {
        //       if (res.hasOwnProperty("errors")) {
        //         errors.push({ title: res.message, messages: res.errors })
        //         numFailure += 1
        //       }
        //     })

        //     if (errors.length > 0) {
        //       setWaitingAlert(false)
        //       setAlert(true)
        //       setAlertTitle(`${trans("num_of_errors")}: [${numFailure}]`)
        //       setAlertMessage(errors)
        //     } else {
        //       setTimeout(() => {
        //         setWaitingAlert(false)
        //         setSuccessAlert(true)
        //         setSuccessMessage(
        //           trans("num_of_succes", responseArr.length - numFailure)
        //         )
        //       }, 500)
        //     }
        //   })
        //   .catch((err) => {
        // setWaitingAlert(false)
        // setAlert(true)
        // setAlertMessage([
        //   trans("import_file_message_c3_error"),
        //   err.response.errors,
        // ])
        //   })
      } else {
        // Think to make it generic from one place and make a function which accept two paramerter
        // one for setting the alert open/not open, and the second for setting the message
        setAlert(true)
        setAlertMessage([trans("import_file_message_c2_error")])
      }
    }
    reader.readAsBinaryString(selectedFile)
  }
  return (
    <>
      <HiddenInputFile
        accept=".xlsx"
        ref={fileInputRef}
        name="import_data"
        onChange={onFileSelection}
      />

      <MasterConfirm
        open={confirming}
        onConfirm={handleOnConfirm}
        title={trans("confirmation")}
        message={confirmationMessage}
        onClose={() => setConfirm(false)}
      />

      <WaitingAlertMessage
        open={waitingAlert}
        value={waitingAlertValue}
        onClose={() => setWaitingAlert(false)}
      />

      <SuccessAlertMessage
        open={successAlert}
        messages={successMessage}
        onClose={() => setSuccessAlert(false)}
      />

      <AlertMessageDialog
        open={alerting}
        title={alertTitle}
        messages={alertMessage}
        onClose={() => setAlert(false)}
      />
    </>
  )
}

export default ImportFile

ImportFile.propTypes = {
  confirming: PropTypes.bool.isRequired,
  setConfirm: PropTypes.func.isRequired,
}
