// Material Ui
import { makeStyles, lighten } from "@material-ui/core"

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    paddingLeft: theme.spacing(0.1),
    paddingRight: theme.spacing(0.1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(1),
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(1),
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
    fontWeight: "bold",
  },
  listIcon: {
    paddingTop: "2px",
    paddingRight: theme.spacing(1),
  },
  paper:
    theme.palette.type === "light"
      ? { backgroundColor: "#F0F0F0F0" }
      : { backgroundColor: "#484848" },
  grow: {
    flexGrow: 1,
  },
  sticky: {
    top: 0,
    width: 100,
    position: "fixed",
  },
}))

export default useToolbarStyles
