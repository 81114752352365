// Dependencies
import React from "react"
// Core Files
import { trans } from "core/localization/index"
// Shared Components
import ProgressBar from "materialui/ProgressBar"
// Material Ui + Styles
import {
  AppBar,
  Button,
  Toolbar,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core"
import useFormStyles from "materialui/Styles"
import CloseIcon from "@material-ui/icons/Close"

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}))

function FormModalTitle(props) {
  const classes = useStyles()
  const classes2 = useFormStyles()
  const { title, onClose, onSubmit, type } = props

  return (
    <AppBar className={classes.appBar}>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          onClick={onClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
        <Button
          autoFocus
          color="inherit"
          onClick={onSubmit}
          disabled={type === "waiting"}
        >
          {type === "waiting" ? (
            <ProgressBar className={classes2.progressBar} control="circular" />
          ) : (
            trans(type)
          )}
        </Button>
      </Toolbar>
    </AppBar>
  )
}

export default FormModalTitle
