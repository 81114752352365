// Core Files
import modulesList from "./modulesList"
import config from "../configuration/config"

const localeCodes = config.get("locales")
/**
 * Get first segment of the given location data
 *
 * @param {object} location
 * @returns {string}
 */
export function firstSegmentOfRoute(location) {
  let [firstSegment, secondSegment] = location.pathname
    .replace(/\//, "")
    .split("/")

  let segment = firstSegment
  // if first segment is localCode, then take the second segment, if there is no second segment return empty string
  if (localeCodes[firstSegment]) segment = secondSegment || ""
  return "/" + segment
}

/**
 * Check if firstSegment of route exist in the array of modules
 *
 * @param {string} firstSegment
 * @returns {bool}
 */
export function isPartOfLazyModules(firstSegment) {
  return modulesList[firstSegment]
}
