// Dependencies
import React from "react"
// Shared Components
import ColoredIcon from "shared/components/Icon/ColoredIcon"
// Material Ui
import CheckIcon from "@material-ui/icons/Check"
import ClearIcon from "@material-ui/icons/Clear"

function BooleanFormatter(props) {
  const { column } = props
  const trueIcon = <ColoredIcon icon={CheckIcon} color="green" />
  const falseIcon = <ColoredIcon icon={ClearIcon} color="red" />
  return Number(column.value) ? trueIcon : falseIcon
}

export default BooleanFormatter
