import LoginService from "./LoginService"
import LogoutService from "./LogoutService"

export function login(data) {
  return LoginService.create(data)
}

export function logout() {
  return LogoutService.logout()
}
