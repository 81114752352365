// Dependencies
import React from "react"
import { Obj } from "reinforcements"
// Core Files
import { trans } from "core/localization"
// Shared Components
import MasterConfirm from "layout/Confirm/MasterConfirm"
// Material Ui
import { IconButton, Tooltip } from "@material-ui/core"
import DeleteForeverIcon from "@material-ui/icons/DeleteForever"

export default function EditableTableDeleteButton(props) {
  const { record, recordIndex, setTableRecords, deleteKey } = props
  const [confirming, setConfirm] = React.useState(false)

  const handleOnConfrim = () => {
    setTableRecords((records) => {
      records.splice(recordIndex, 1)
      return [...records]
    })
  }
  return (
    <>
      <IconButton onClick={() => setConfirm(true)}>
        <Tooltip title={trans("remove")}>
          <DeleteForeverIcon />
        </Tooltip>
      </IconButton>

      <MasterConfirm
        open={confirming}
        onConfirm={handleOnConfrim}
        title={trans("confirmation")}
        onClose={() => setConfirm(false)}
        message={trans("deleteText", Obj.get(record, deleteKey))}
      />
    </>
  )
}
