// Dependencies
import React from "react"
// Core Files & Hooks
import useTable from "tables/hooks/useTable"
// Shared Components
import Input from "materialui/Input"
import EditProduct from "./EditProduct"
import ToolBarDeleteButton from "./Delete"
import ToolBarPrinterButton from "./Printer"
import ToolBarSendAwayButton from "./sendAway"
import TableAddButton from "tables/TableComponents/TableActions/Add"
import TableMenuButton from "tables/TableComponents/TableActions/Menu"
import TableOptionsButton from "tables/TableComponents/TableActions/Options"
// import ToolBarEmailButton from "./Email"
// import ToolBarDownloadButton from "./Download"
// Material Ui + Styles
import useFormStyles from "materialui/Styles"
import { Typography } from "@material-ui/core"

export function SubContent(props) {
  const { options } = useTable()
  const { classes, label } = props

  return (
    <>
      <Typography className={classes.title} component="div" noWrap>
        {label}
      </Typography>
      {/* <ToolBarEmailButton /> */}
      {/* <ToolBarDownloadButton /> */}
      {options.title === "products" && <EditProduct />}
      {options.title === "orders" && <ToolBarSendAwayButton />}
      {options.printerOption && <ToolBarPrinterButton />}
      <div style={{ marginRight: 60 }}></div>
      <ToolBarDeleteButton />
    </>
  )
}

export const MainContent = (props) => {
  const { options } = useTable()
  const classes2 = useFormStyles()
  const { classes, label, searchText, setSearchText, handleKeyPress } = props

  return (
    <>
      <TableMenuButton />
      <Typography variant="h6" className={classes.title} component="div" noWrap>
        {label}
      </Typography>
      <Input
        endIcon
        startIcon
        value={searchText}
        variant="standard"
        label={["search", "....."]}
        setSearchText={setSearchText}
        className={classes2.marginRight}
        onChange={(e) => setSearchText(e.target.value)}
        onKeyPress={(e, type = "keyPress") => handleKeyPress(e, type)}
      />
      <TableAddButton />
      {options.advancedOptions && <TableOptionsButton />}
    </>
  )
}
