import localization from "core/localization/index"

localization.extend("ar", {
  appName: "شركة الامام",
  add: "أضف",
  save: "حفظ",
  edit: "عدّل",
  remove: "إمحِ",
  addItem: "أضف %s جديد",
  editItem: "عدّل %s",
  removeItem: "إمحِ %s",
  change: "تغير %s",
  direction: "الإتجاه",
  themeMode: "لون الخلفية",
  notifications: "الإشعارات",
  logout: "تسجيل الخروج",
  success: "نجاح",
  successfully: "بنجاح",
  refresh: "تحديث",
  deleteText: "إحذر سوف يتم حذف %s",
  confirmation: "هل أنت متأكد؟",
  notes: "ملاحظات",
  gender: "الجنس",
  country: "البلد",
  city: "القرية",
  birthDate: "تاريخ الولادة",
  "New User": "مستخدم جديد",
  loading: "قيد التحميل",
  search: "بحث%s",
  selected: "المختار",
  id: "#",
  name: "ألأسم",
  actions: "العمليات",
  information: "بيانات",
  password: "كلمة المرور",
  email: "البريد الإلكتروني",
  email_: "البريد الإلكتروني",
  c_password: "تأكيد كلمة المرور",
  image: "ألصورة",
  picture: "ألصورة",
  roles: "ألصلاحيات",
  display_name: "ألإسم ألمستخدم",
  description: "ألوصف",
  updated_at: "عُدّل ب",
  created_at: "أُنشئ ب",
  recordUpdated: "تم تعديل %s بنجاح",
  recordAdded: "تم إضافة %s جديد بنجاح",
  active: "فعال",
  notactive: "غير فعال",
  arabic_name: "ألأسم بالعربي",
  fax: "فاكس",
  phone: "رقم الجوال",
  phone2: "رقم جوال آخر",
  contact_info: "معلومات للتواصل",
  personal_info: "معلومات شخصية",
  others_info: "معلومات أخرى",
  code: "ألرمز ألخاص",
  details: "البيانات",
  active_not_active: "فعال/غير فعال",
  not_active: "غير فعال",
  status: "ألحالة",
  alternate_name: "اسم بديل",
  import_data: "إستيراد بيانات",
  import_images: "إستيراد ألصور",
  barcode: "ألباركود",
  price: "ألسعر",
  weight: "ألوزن",
  new: "جديد",
  not_new: "غير جديد",
  error: "خطأ",
  warning: "تحذير",
  import_file_message:
    "ألملف الذي سيتم إختياره يجب أن يتوافق مع الشروط التالية:",
  import_file_message_c1: "1. صيغة ألملف يجب أن تكون من فئة .xlsx.",
  import_file_message_c2:
    "2. رأس الجدول يجيب ان يتوافق مع المعطيات التالية في نفس الترتيب.",
  import_file_message_c2_error: "عدم تطابق الجدول مع الشرط المذكور سابقاً",
  import_file_message_c3_error: "حدث خلل أثناء إدخال ألبيانات",
  import_image_message:
    "ألصور التي سيتم إختيارها يجب أن تتوافق مع ألشروط ألتالية:",
  import_image_message_c1:
    "1. صيغة ألملف يجب أن تكون من ألفئات ألتالية: (png,jpg,jpeg).",
  import_image_message_c2:
    "2. إسم ألصورة يجب أن يكون مطابق للرمز ألخاص في ألصنف.",
  import_image_message_c3:
    "3. لكي يتم تخزين أكثر من صورة لنفس ألصنف, يجب ألإكمال على تسمية ألصورة كالتالي:",
  import_image_message_c4:
    "ألرمز ألخاص في ألصنف ثم _ ثم البدء من رقم واحد. مثال: 1_1000 , 2_1000.",
  import_image_message_c5:
    "4. يمكن أختيار جميع الصور من مكان واحد أو القيام بتحميل كل جزء خاص لوحده أو إختيار أكثر من حافظة للصور.",
  import_image_message_c2_error: "عدم تطابق ألصورة مع الشروط ألمذكورة سابقاً",
  import_image_message_c3_error: "حدث خلل أثناء تحميل ألصور",
  num_of_errors: "عدد ألأخطاء",
  num_of_succes: "تم إدخال %s سجل جديد",
  print: "طباعة",
  printingStatus: "ألتأكد من حالة ألطباعة",
  printingConfrimation: "هل تمت عملية ألطباعة بنجاح؟",
  filter: "بحث متقدم",
  from_to: "من - إلى",
  date: "تاريخ",
  date_from_to: "%s %s %s",
  sendAway: "ترحيل",
  emptyStock: "هذا المخزون غير متوفر حاليا",
  confrimBuyAllStock: "هل تريد خصم كل المخزون ؟",
  consumeAll: "تم نفاذ المخزون من الصنف %s",
  productIsntExist: "سوف يتم حذف الصنف من الطلبية",
  submitOrder: "سوف يتم ترحيل الطلبيات التي تم اخيارها",
  submitted: "مرحل",
  submittedSuccess: "تم ترحيل ألطلبيات بنجاح",
  connect: "ربط",
  bisan: "بيسان",
  current: "الحالي",
  enable: "تفعيل",
  disable: "إلغاء التفعيل",
  productWillBeEdited: "سوف يتم تعديل الاصناف المختارة.",
  confirm: "تاكيد",
  cancel: "إلغاء",
  successfullyUpdated: "تم التعديل بنجاح",
})
