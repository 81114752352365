// Dependencies
import React from "react"
// Core Files & Hooks
import { trans } from "core/localization"
import useFormik from "core/formik/hooks/useFormik"
import useMainPage from "core/formik/hooks/useMainPage"
import FormikControl from "core/formik/FormikControl"
import { pageInfo } from "core/formik/helpers/standardFormikHelper"
// Shared Components
import Globals from "core/globals"
import GridItem from "materialui/Grid/GridItem"
import GridContainer from "materialui/Grid/GridContainer"
// Material Ui
import { Typography } from "@material-ui/core"
import useFormStyles from "materialui/Styles"

export default function HeadProduct({ options, action }) {
  const classes = useFormStyles()
  const pageTitleFull = pageInfo(options, action)
  const { mainPageState } = useMainPage()
  const { isSubmitting, handleSubmit, setFieldValue } = useFormik()

  const handleOnSubmit = () => {
    let { tableRecords } = mainPageState
    let enhancedTableRecords = []
    tableRecords.forEach((element) => {
      let newEnhancedObj = {}
      for (const [key] of Object.entries(element)) {
        if (key !== "columnList") {
          if (key === "position_id") newEnhancedObj[key] = element[key].id
          else newEnhancedObj[key] = element[key]
        }
      }
      enhancedTableRecords.push(newEnhancedObj)
    })
    setFieldValue("product_details", enhancedTableRecords)
    handleSubmit()
  }

  return (
    <GridContainer spacing={0}>
      <GridItem xs={8}>
        <Typography noWrap variant="h6" component="div">
          {pageTitleFull}
        </Typography>
      </GridItem>
      <GridItem xs={2} alignItems="right">
        <FormikControl
          name="new"
          label="new"
          control="switchbutton"
          className={classes.switchMarginRight}
        />
        <FormikControl
          name="active"
          label="active"
          control="switchbutton"
          className={
            Globals.direction === "rtl" ? classes.switchMarginRight : null
          }
        />
      </GridItem>
      <GridItem xs={2} alignItems="right">
        <FormikControl
          control="button"
          name="buttonlogin"
          disabled={isSubmitting}
          onClick={handleOnSubmit}
          label={isSubmitting ? "waiting" : trans("save")}
        />
      </GridItem>
    </GridContainer>
  )
}
