// Dependencies
import React from "react"
// Shared Components
import ImagePlaceHolder from "./ImagePlaceHolder.png"
import FileInput from "shared/components/File/FileInput"
// Material Ui
import { styled } from "@material-ui/core"
import { grey } from "@material-ui/core/colors"
import { TextButton } from "materialui/Button/Button"

const Image = styled("img")(({ theme }) => ({
  width: theme.spacing(17),
  height: theme.spacing(17),
}))

const ImageButton = styled(TextButton)({
  background: grey[200],
})

const acceptedExtensions = ["jpg", "jpeg", "png", "gif", "webp", "bmp", "ico"]

export default function ImageInput(props) {
  const { onImageSelection } = props
  // const [selectedImage, setSelectedImage] = React.useState(ImagePlaceHolder)

  const ButtonComponent = (props) => (
    <ImageButton {...props}>
      <Image src={ImagePlaceHolder} />
    </ImageButton>
  )

  const onFileSelection = (e) => {
    // setSelectedImage(URL.createObjectURL(e.target.files[0]))
    onImageSelection(e.target.files[0])
  }

  return (
    <FileInput
      onChange={onFileSelection}
      accept={acceptedExtensions}
      ButtonComponent={ButtonComponent}
      {...props}
    />
  )
}
