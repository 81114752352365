// Dependencies
import React from "react"
import { useHistory } from "react-router-dom"
//Core Files & Hooks
import useTable from "tables/hooks/useTable"
import { trans } from "core/localization/index"
import { pageParam } from "core/formik/helpers/standardFormikHelper"
// Shared Components
import Tooltip from "materialui/ToolTip/MasterToolTip"
import TableForm from "tables/TableComponents/TableForm/TableForm"
// Material Ui + Styles
import useFormStyles from "materialui/Styles"
import { IconButton } from "@material-ui/core"
import AddCircleIcon from "@material-ui/icons/AddCircle"

export default function TableAddButton() {
  const history = useHistory()
  const { options } = useTable()
  const classes = useFormStyles()
  const [formIsDisplayed, openForm] = React.useState(false)
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const isSubmittingObj = { isSubmitting, setIsSubmitting }

  const handleClick = (e) => {
    if (options.type === "Page") {
      history.push(`/${pageParam(history, 1)}/create`)
    } else openForm(true)
  }

  return (
    <>
      <IconButton
        color="primary"
        onClick={handleClick}
        className={classes.toolBarPadding}
      >
        <Tooltip title={trans("add")}>
          <AddCircleIcon fontSize="large" />
        </Tooltip>
      </IconButton>

      <TableForm
        record={{}}
        action="add"
        open={formIsDisplayed}
        service={options.service}
        formOptions={options.formOptions}
        isSubmittingObj={isSubmittingObj}
        onConfirm={(e) => openForm(false)}
      />
    </>
  )
}
