// Core Files
import RestfullEndpoint from "core/RestfulApi/RestfulApiEndpoint"

export class EmployeeService extends RestfullEndpoint {
  route = "/employees"

  /**
   * Fetch records from endpoint api
   *
   * @param   {object} params
   * @returns {Promise}
   */
  salespersons(params) {
    return this.endpoint.get(`${this.route}/salespersons`, {
      params,
    })
  }

  /**
   * Fetch records from endpoint api
   *
   * @param   {object} params
   * @returns {Promise}
   */
  getList(params) {
    return this.endpoint.get(`${this.route}/getList`, {
      params,
    })
  }
}

export default new EmployeeService()
