// Dependencies
import React from "react"
import PropTypes from "prop-types"
// Core Files
import { trans } from "core/localization"
// Shared Components
import StaticButton from "materialui/Button/Button"
import ImportFile from "tables/TableComponents/FileComponents"
import { ButtonText } from "modules/Orders/components/PrintPreviewOrders/OrdersToPrint"

function ImportDataButton({ heading, Icon }) {
  const [confirming, setConfirm] = React.useState(false)
  return (
    <>
      <StaticButton onClick={() => setConfirm(true)}>
        <ButtonText>{trans(heading)}</ButtonText>
        {Icon && <Icon />}
      </StaticButton>
      <ImportFile confirming={confirming} setConfirm={setConfirm} />
    </>
  )
}

ImportDataButton.propTypes = {
  Icon: PropTypes.object,
  heading: PropTypes.string.isRequired,
}

export default ImportDataButton
