// Dependencies
import { Obj } from "reinforcements"
import { vsprintf } from "sprintf-js"
// Core Files
import Globals from "core/globals"
import Is from "@flk/supportive-is"

/**
 * All keywords for all locale codes
 */
let keywordsList = {}

/**
 * Default local code
 */
let localeCode = Globals.localeCode

/**
 * Refresh the local code from the Globals
 */
export function refreshLocalCode() {
  localeCode = Globals.localeCode
}

/**
 * Add keywords
 *
 * @param {object} keywords
 * @returns {void}
 */
export function extend(localeCode, keywords) {
  keywordsList[localeCode] = Obj.merge(keywordsList[localeCode], keywords)
}

/**
 * Translate the given keyword in current locale code
 *
 * @param {string} key
 * @return {any}
 */
export function trans(key, ...args) {
  refreshLocalCode()
  return translateFrom(localeCode, key, ...args)
}

/**
 * Translate the given keyword for the given locale code
 * Please note this method accepts dot notation syntax
 *
 * @param {string} key
 * @return {any}
 */
export function translateFrom(localeCode, keyword, ...args) {
  let translation = Obj.get(keywordsList, `${localeCode}.${keyword}`)
  if (Is.empty(args)) args = " "
  return vsprintf(translation, args) || keyword
}
