export default [
  {
    entry: ["/updates"],
    module: "Updates", // the value name of this key will be the same as module directory which is also (users)
  },
  {
    entry: ["/orders"],
    module: "Orders", // the value name of this key will be the same as module directory which is also (users)
  },
  {
    entry: ["/products", "/positions"],
    module: "Products", // the value name of this key will be the same as module directory which is also (users)
  },
  {
    entry: ["/categories"],
    module: "Categories", // the value name of this key will be the same as module directory which is also (users)
  },
  {
    entry: ["/employees", "/job_titles"],
    module: "Employees", // the value name of this key will be the same as module directory which is also (users)
  },
  {
    entry: ["/customers"],
    module: "Customers", // the value name of this key will be the same as module directory which is also (users)
  },
  {
    entry: ["/users", "/login", "/roles"],
    module: "Users", // the value name of this key will be the same as module directory which is also (users)
  },
  {
    entry: ["/"], //this should be the last one because all the about match the first char of it
    module: "App",
  },
]
