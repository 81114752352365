// Core Files
import MainPageForm from "core/formik/MainPageForm"
import SetUpProductForm from "modules/Products/helpers/SetUpProductForm"
// Shared Components
import {
  createdAt,
  updatedAt,
} from "tables/TableComponents/TableDefaultItems/TimeStamps"
import {
  FilterOptionComponent,
  ImportOptionComponent,
} from "tables/TableComponents/TableActions/Helpers/OptionsContent"
// import ImageFormatter from "tables/Formatters/ImageFormatter"
import NumberFormatter from "tables/Formatters/NumberFormatter"
import BooleanFormatter from "tables/Formatters/BooleanFormatter"
import ProductService from "modules/Products/services/ProductService"
import tableActive from "tables/TableComponents/TableDefaultItems/Active"
import ProductImageService from "modules/Products/services/ProductImageService"
import masterTableActions from "tables/TableComponents/TableActions/MasterActions"
import tableCheckBoxAll from "tables/TableComponents/TableDefaultItems/CheckBoxAll"
import masterFilterCheckboxs from "tables/TableComponents/TableActions/MasterCheckboxs"
import masterImportButtons from "tables/TableComponents/TableActions/MasterImportButtons"

export default {
  type: "Page", // 'Modal' Or 'Page' for adding & editing
  title: "products",
  service: ProductService,
  imageService: ProductImageService,
  tabOptions: {
    tabs: [{ label: "details", value: MainPageForm }],
  },
  formOptions: {
    modalLable: "product",
    formikLable: "product_",
    setUpForm: SetUpProductForm,
  },
  table: {
    orderBy: "id",
    heading: "productsList",
    columns: [
      tableCheckBoxAll,
      // { key: "id", heading: "#", filter: false },
      // {
      //   filter: false,
      //   theme: "avatar",
      //   firstValue: true,
      //   heading: "picture",
      //   key: "product_images",
      //   formatter: ImageFormatter,
      // },
      { key: "name", heading: "name", allowfilter: false },
      { key: "code", heading: "code", allowfilter: true },
      { key: "barcode", heading: "barcode", filter: true },
      { key: "arabic_name", heading: "arabic_name", filter: true },
      { key: "alternate_name", heading: "alternate_name", filter: true },
      {
        key: "price",
        heading: "price",
        filter: false,
        formatter: NumberFormatter,
      },
      {
        key: "weight",
        heading: "weight",
        filter: true,
        formatter: NumberFormatter,
      },
      { key: "box_quantity", heading: "box_quantity", filter: true },
      {
        filter: true,
        key: "categories",
        arrAttribute: "name",
        heading: "categories",
      },
      {
        key: "new",
        filter: false,
        heading: "new",
        formatter: BooleanFormatter,
      },
      { key: "note", heading: "notes", filter: true },
      tableActive,
      createdAt,
      updatedAt,
      masterTableActions(["delete", "edit"]),
    ],
  },
  advancedOptions: [
    {
      component: FilterOptionComponent,
      configuration: {
        checkboxs: masterFilterCheckboxs(["active", "new"]),
        dateFromTo: {
          key: "expiry_date",
          props: { minDate: null, maxDate: null },
          values: { empty: true, startDate: "", endDate: "" },
          inputLabel: ["date_from_to", "expiry_date_", "product_", "from_to"],
        },
      },
    },
    {
      component: ImportOptionComponent,
      configuration: {
        buttons: masterImportButtons(["import_data", "import_images"]),
      },
    },
  ],
  fileOptions: {
    order: [
      "code",
      "name",
      "display_name",
      "arabic_name",
      "alternate_name",
      "barcode",
      "price",
      "weight",
      "box_quantity",
      "new",
      "note",
      "active",
      "categories_code",
      "quantity1",
      "position1",
      "expiry_date1",
      "quantity2",
      "position2",
      "expiry_date2",
    ],
  },
}
