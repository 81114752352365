// Dependencies
import React from "react"
import PropTypes from "prop-types"
// Core Files & Hooks
import { trans } from "core/localization"
import useTable from "tables/hooks/useTable"
import { getImageRes } from "tables/helpers/FileHelpers"
// Shared Components
import MasterConfirm from "layout/Confirm/MasterConfirm"
import {
  AlertMessageDialog,
  SuccessAlertMessage,
  WaitingAlertMessage,
} from "layout/Modal/AlertMessage"
import HiddenInputFile from "shared/components/File/HiddenInputFile"

function ImportImages({ confirming, setConfirm }) {
  const { options } = useTable()
  const ImageInputRef = React.useRef()
  const confirmationMessage = [
    trans("import_image_message"),
    trans("import_image_message_c1"),
    trans("import_image_message_c2"),
    trans("import_image_message_c3"),
    trans("import_image_message_c4"),
    // trans("import_image_message_c5"),
  ]

  const [alerting, setAlert] = React.useState(false)
  const [alertTitle, setAlertTitle] = React.useState("")
  const [alertMessage, setAlertMessage] = React.useState([])

  const [successAlert, setSuccessAlert] = React.useState(false)
  const [successMessage, setSuccessMessage] = React.useState([])

  const [waitingAlert, setWaitingAlert] = React.useState(false)
  const [waitingAlertValue, setWaitingAlertValue] = React.useState(0)

  const handleOnConfirm = () => ImageInputRef.current.click()

  const onImagesSelection = (e) => {
    setWaitingAlert(true)
    const request = options.imageService
    const files = Array.from(e.target.files)
    const increasingValue = 100 / files.length
    Promise.all(
      files.map((img) =>
        getImageRes(request, img, setWaitingAlertValue, increasingValue)
      )
    )
      .then((responseArr) => {
        let errors = []
        let numFailure = 0
        responseArr.forEach((res) => {
          if (res.hasOwnProperty("errors")) {
            errors.push({ title: res.message, messages: res.errors })
            numFailure += 1
          }
        })

        if (errors.length > 0) {
          setWaitingAlert(false)
          setAlert(true)
          setAlertTitle(`${trans("num_of_errors")}: [${numFailure}]`)
          setAlertMessage(errors)
        } else {
          setTimeout(() => {
            setWaitingAlert(false)
            setSuccessAlert(true)
            setSuccessMessage(
              trans("num_of_succes", responseArr.length - numFailure)
            )
          }, 500)
        }
      })
      .catch((err) => {
        setWaitingAlert(false)
        setAlert(true)
        setAlertMessage([
          trans("import_image_message_c3_error"),
          err.response.errors,
        ])
      })
  }

  const acceptedExtensions = ["jpg", "jpeg", "png", "gif", "webp", "bmp", "ico"]

  return (
    <>
      <HiddenInputFile
        multiple
        ref={ImageInputRef}
        name="import_images"
        onChange={onImagesSelection}
        accept={acceptedExtensions.map((ex) => `.${ex}`).join(",")}
      />

      <MasterConfirm
        open={confirming}
        onConfirm={handleOnConfirm}
        title={trans("confirmation")}
        message={confirmationMessage}
        onClose={() => setConfirm(false)}
      />

      <WaitingAlertMessage
        open={waitingAlert}
        value={waitingAlertValue}
        onClose={() => setWaitingAlert(false)}
      />

      <SuccessAlertMessage
        open={successAlert}
        messages={successMessage}
        onClose={() => setSuccessAlert(false)}
      />

      <AlertMessageDialog
        open={alerting}
        title={alertTitle}
        messages={alertMessage}
        onClose={() => setAlert(false)}
      />
    </>
  )
}

export default ImportImages

ImportImages.propTypes = {
  confirming: PropTypes.bool.isRequired,
  setConfirm: PropTypes.func.isRequired,
}
