// Dependencies
import React from "react"
// Material Ui
import { styled } from "@material-ui/core"

const InputFile = React.forwardRef((props, ref) => {
  return <input type="file" ref={ref} {...props} />
})

const HiddenInputFile = styled(InputFile)({
  display: "none",
})

export default HiddenInputFile
