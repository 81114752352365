// Dependencies
import * as Yup from "yup"

export default class SetUpOrderForm {
  constructor(record) {
    this.initialValues = {
      bonus: record.bonus || "",
      discount: record.discount || "",
      product_id: record.product || "",
      quantity: record.quantity || "1",
    }

    this.validationSchema = Yup.object({
      bonus: Yup.number().min(0),
      quantity: Yup.number().min(0),
      discount: Yup.number().min(0).max(100),
      product_id: Yup.string().required("Required"),
    })
  }

  getInitialValues() {
    return this.initialValues
  }
  getValidationSchema() {
    return this.validationSchema
  }
}
