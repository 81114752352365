// Dependencies
import React from "react"
import ReactDOM from "react-dom"
// Core Files
import Routes from "./routesHandler"
import { addRouter } from "./routesList"
export {
  refresh,
  navigateTo,
  switchLang,
  currentRoute,
} from "./Navigation/navigator"

// import setCurrentLocale from "./updateCurrentLocalization"
// setCurrentLocale()

/**
 * Scan the entire routes list
 *
 * @returns {void}
 */
function scan() {
  // Initiate navigator
  // initiateNavigator()
  ReactDOM.render(<Routes />, document.getElementById("root"))
}

// Each router service provider can access the addRouter method down
export default {
  scan,
  provider: {
    name: "route", // the name that will will be used from the reactor object in any module service provider,
    call: addRouter,
  },
}
