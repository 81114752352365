// Dependencies
import React from "react"
import rtrim from "reinforcements/src/utilities/str/rtrim"
//Core Files & Hooks
import useTable from "tables/hooks/useTable"
import { trans } from "core/localization/index"
// Shared Components
import Tooltip from "materialui/ToolTip/MasterToolTip"
import MasterConfirm from "layout/Confirm/MasterConfirm"
// Material Ui + Styles
import { IconButton } from "@material-ui/core"
import DeleteForeverIcon from "@material-ui/icons/DeleteForever"

function ToolBarDeleteButton() {
  const { options, tableRecordsObj, tableSelectedRow } = useTable()
  const [confirming, setConfirm] = React.useState(false)

  const { selected, setSelected } = tableSelectedRow
  let { tableRecords, setTableRecords } = tableRecordsObj

  let deleteMessage = ""
  tableRecords.map((record) => {
    if (selected.includes(record.id))
      deleteMessage += `${record.name || record.id}, `
    return selected.includes(record.id)
  })
  deleteMessage = rtrim(deleteMessage, ", ")

  const handleOnConfrim = () => {
    // Delete Selected Records
    setTableRecords((records) => {
      const newRecords = records.filter(
        (record) => !selected.includes(record.id)
      )
      return [...newRecords]
    })
    // Delete from backend using Restfull api
    options.service.deleteList({ ids: selected })
    setSelected([])
  }
  return (
    <>
      <MasterConfirm
        open={confirming}
        onConfirm={handleOnConfrim}
        onClose={() => setConfirm(false)}
        title={trans("confirmation")}
        message={trans("deleteText", deleteMessage)}
      />

      <IconButton onClick={() => setConfirm(true)} color="primary">
        <Tooltip title={`${trans("remove")} ${trans("selected")}`}>
          <DeleteForeverIcon />
        </Tooltip>
      </IconButton>
    </>
  )
}

export default ToolBarDeleteButton
