// Dependencies
import * as Yup from "yup"

export default class SetUpProductDetailForm {
  constructor(record) {
    this.initialValues = {
      position_id: record.position || "",
      expiry_date: record.expiry_date || "",
      available_quantity: record.available_quantity || "",
    }

    this.validationSchema = Yup.object({
      position_id: Yup.string().required("Required"),
      expiry_date: Yup.string().required("Required"),
      available_quantity: Yup.string().required("Required"),
    })
  }

  getInitialValues() {
    return this.initialValues
  }
  getValidationSchema() {
    return this.validationSchema
  }
}
