// Dependencies
import React from "react"
import PropTypes from "prop-types"
import { Random } from "reinforcements"
// Core Files
import cache from "core/cache"
// Material Ui
import { Popover as MUPopover } from "@material-ui/core"

function Popover({ anchorEl, content, ...rest }) {
  const themeMode = cache.get("themeMode")
  return (
    <MUPopover anchorEl={anchorEl} open={Boolean(anchorEl)} {...rest}>
      <div
        style={{
          padding: "16px",
          backgroundColor: themeMode === "light" ? "#f0f0f0" : "#303030",
        }}
      >
        {content}
      </div>
    </MUPopover>
  )
}

Popover.propTypes = {
  id: PropTypes.string,
  anchorOrigin: PropTypes.object,
  transformOrigin: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
}

Popover.defaultProps = {
  id: Random.id(),
  anchorOrigin: { vertical: "bottom", horizontal: "right" },
  transformOrigin: { vertical: "top", horizontal: "left" },
}

export default Popover
