// Dependencies
import React from "react"
import { Field } from "formik"
import PropTypes from "prop-types"
// Core Files
import { trans } from "core/localization/index"
import { validateInput, isNotValid } from "../Validation/index"
// Shared Components
import RadioButton from "./RadioButton"
// Material Ui + Custom Style
import useFormStyles from "../Styles"
import {
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  FormHelperText,
} from "@material-ui/core"

function RadioGroupButtons(props) {
  const classes = useFormStyles()
  const { label, name, value, row, required, options, ...rest } = props

  const displayRadioButtons = (field) => {
    return options.map((option) => {
      let { key, value, ...rest } = option
      return (
        <FormControlLabel
          key={key}
          id={value}
          {...field}
          label={key}
          value={value}
          control={<RadioButton {...rest} />}
          checked={field.value === value}
        />
      )
    })
  }

  return (
    <Field name={name} {...rest}>
      {({ field, form }) => {
        return (
          <FormControl
            required={required}
            component="fieldset"
            error={isNotValid(form, name)}
            className={classes.formControl}
          >
            <FormLabel component="legend">{trans(label)}</FormLabel>
            <RadioGroup value={value} row={row}>
              {displayRadioButtons(field)}
            </RadioGroup>
            <FormHelperText>{validateInput(form, name)}</FormHelperText>
          </FormControl>
        )
      }}
    </Field>
  )
}

export default RadioGroupButtons

RadioGroupButtons.propTypes = {
  row: PropTypes.bool,
  value: PropTypes.string,
  required: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
}

RadioGroupButtons.defaultProps = {
  value: "",
  row: false,
  required: false,
}
