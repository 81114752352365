// Core Files
import TabPage from "core/TabPage"
import Employee from "./components/index"
import Gardian from "core/middlewares/gardian"
import JobTitles from "./components/JobTitles"

export default function (route) {
  route("/employees", Employee, [Gardian])
  route("/job_titles", JobTitles, [Gardian])
  route("/employees/:id", TabPage, [Gardian])
}
