// Dependencies
import * as Yup from "yup"
import Is from "@flk/supportive-is"

export default class SetUpUserForm {
  constructor(record, action) {
    let active, _role_id
    if (Is.undefined(record.active)) active = true
    else if (Is.boolean(record.active)) active = record.active
    else active = record.active === 1 ? true : false

    if (Is.undefined(record.roles) || Is.undefined(record.roles[0]))
      _role_id = ""
    else _role_id = record.roles[0]

    this.initialValues = {
      active,
      action,
      _role_id,
      password: "",
      id: record.id,
      c_password: "",
      name: record.name || "",
      email: record.email || "",
    }

    this.validationSchema = Yup.object({
      action: Yup.string(),
      active: Yup.boolean(),
      name: Yup.string().required("Required"),
      email: Yup.string().email("Invalid Email Format").required("Required"),
      password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .when("action", {
          is: (value) => value === "add",
          then: Yup.string().required("Required"),
        }),
      c_password: Yup.string()
        .oneOf([Yup.ref("password"), ""], "Passwords must match")
        .when("action", {
          is: (value) => value === "add",
          then: Yup.string().required("Required"),
        }),
      _role_id: Yup.string().required("Required").nullable(),
    })
  }

  getInitialValues() {
    return this.initialValues
  }
  getValidationSchema() {
    return this.validationSchema
  }
}
