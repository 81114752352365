// Dependencies
import React from "react"
import { useHistory } from "react-router-dom"
// Core Files & Hooks
import TabProvider from "layout/providers/TabProvider"
import { loadOptions } from "core/formik/helpers/standardFormikHelper"
// Shared Components
import MasterLayout from "layout/MasterLayout"
import ScrolledTabs from "layout/Tab/ScrolledTabs"

function TabPage() {
  const options = loadOptions(useHistory())
  const tabs = options.tabOptions.tabs
  return (
    <MasterLayout label={options.title} pageTab>
      <TabProvider.Provider value={options}>
        <ScrolledTabs tabs={tabs} />
      </TabProvider.Provider>
    </MasterLayout>
  )
}

export default TabPage
