// Dependencies
import React from "react"
import PropTypes from "prop-types"
// Shared Components
import GridItem from "./GridItem"
import GridContainer from "./GridContainer"
import Typography from "materialui/Typography"
// Material Ui
import { Divider } from "@material-ui/core"
import useFormStyles from "materialui/Styles"

function GridDivider({ label }) {
  const classes = useFormStyles()
  return (
    <>
      <GridContainer>
        <GridItem xs={12}>
          <Typography label={label} className={classes.alignLeft} />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <Divider />
        </GridItem>
      </GridContainer>
    </>
  )
}

export default GridDivider

GridDivider.propTypes = {
  label: PropTypes.string.isRequired,
}
