// Dependencies
import ltrim from "reinforcements/src/utilities/str/ltrim"
// Core Files
// import Globals from "../../globals"
import history from "../routerHistory"
import {
  localeCodes,
  updateCurrentLocaleCode,
  getCurrentLocaleCode,
} from "core/localization/locales"

let currentFullRoute, currentRouteWithoutLocaleCode

/**
 * Set the current route and the current route without locale code
 *
 * @param {string} route
 * @returns {void}
 */
export function updateCurrentRoute(route) {
  // console.log(route)
  currentFullRoute = route
  //   remove any possible locale code
  let regex = new RegExp(`^/(${localeCodes.join("|")})`)
  currentRouteWithoutLocaleCode = currentFullRoute.replace(regex, function (
    matched,
    localeCode
  ) {
    updateCurrentLocaleCode(localeCode)
    return ""
  })
  // console.log(currentFullRoute, currentRouteWithoutLocaleCode)
}

/**
 * Navigate to the given path
 *
 * @param {string} path
 */
export function navigateTo(path) {
  history.push(path)
}

/**
 * Get current route
 *
 * @returns {string}
 */
export function currentRoute(history) {
  return history.location.pathname
}

/**
 * Get current route without locale code
 *
 * @returns {string}
 */
export function routeOnly(history) {
  return ltrim(currentRoute(history), "/" + getCurrentLocaleCode())
}

/**
 * Force reload current route content
 */
export function refresh(history) {
  history.push(currentRoute(history))
}

/**
 * Navigate to current location and switch language
 *
 * @param {string} localeCode
 */
export function switchLang(localeCode) {}

/**
 * Initialize Navigator
 */
export default function initiateNavigator() {
  // Here we should declate the listener for history
  // but i face a problem while declareing it
  // so i defined it inside the Renderer.js
  // console.log("initiateNavigator", history.location.pathname)
  updateCurrentRoute(history.location.pathname || "/")
}

/**
 * Check if current  route has a locale code
 * By comparing the currentFullRoute with currentRouteWithoutLocaleCode
 *
 * @returns {boolean}
 */
export function hasInitialLocaleCode() {
  // console.log(
  //   "hasInitialLocaleCode",
  //   currentFullRoute,
  //   currentRouteWithoutLocaleCode
  // )
  return currentFullRoute !== currentRouteWithoutLocaleCode
}
