// Dependencies
import { firstBy } from "thenby"
import { Obj } from "reinforcements"
// Core Files
import tableItems from "modules/Orders/components/PrintPreviewOrders/PrintItems"

export function getSortedOrder(order) {
  let filteredRecords = []
  const records = order.order_details
  const sortOrder = tableItems.sortOrder
  const firstSort = sortOrder[0].key || ""
  const secordSort = sortOrder[1].key || ""
  const thirdSort = sortOrder[2].key || ""
  const allowProperties = tableItems.table.allowProperties
  records.forEach((element) => {
    let filteredObj = {}
    allowProperties.forEach((allowProp) => {
      let value = {}
      const { key, name } = allowProp
      if (key.includes("[0]") || key.includes("[length-1]")) {
        const keyCode = key.includes("[0]") ? "[0]" : "[length-1]"
        const eArr = Obj.get(element, key.substring(0, key.indexOf(keyCode)))
        const targetProp = key.substring(key.indexOf("].") + 2, key.length)
        const targetIndex = keyCode === "[0]" ? 0 : eArr.length - 1
        if (element[key.split(".")[0]] !== null) {
          value = Obj.get(eArr[targetIndex], targetProp)
        }
      } else value = Obj.get(element, key)
      filteredObj[name] = value
    })
    filteredRecords.push(filteredObj)
  })

  return filteredRecords.sort(
    firstBy(firstSort).thenBy(secordSort, "desc").thenBy(thirdSort)
  )
}

export function calculateOrderHeight(order) {
  const headerHeight = 48
  let tableHeight = 0
  order.order_details.forEach((element) => {
    if (element.note !== null && element.note.length > 32) tableHeight += 46.4
    else tableHeight += 24
  })
  const footerHeight = 58
  const marginOrder = 55
  return headerHeight + tableHeight + 25 + footerHeight + marginOrder
}
