// Dependencies
import React from "react"
import { Field } from "formik"
import PropTypes from "prop-types"
import Is from "@flk/supportive-is"
import { Random } from "reinforcements"
// Core Files
import useOnce from "core/hooks/useOnce"
import { trans } from "core/localization"
// Shared Components
import { isNotValid, validateInput } from "materialui/Validation"
// Material Ui & Styles
import useFormStyles from "materialui/Styles"
import { FormControl } from "@material-ui/core"
import TextField from "@material-ui/core/TextField"
import MaterialAutocomplete from "@material-ui/lab/Autocomplete"

function AutoComplete(props) {
  const classes = useFormStyles()
  let {
    name,
    label,
    options,
    required,
    defaultValues,
    setFieldValue,
    ...rest
  } = props
  const [value, setValue] = React.useState(defaultValues)

  const getOptionLabel = (option) => {
    if (Is.object(option)) return option.key || option.name
    return option
  }

  const getSelected = (option, value) => {
    if (Is.object(value)) return option.key === value.key
    return option === value
  }

  useOnce(() => {
    setFieldValue(name, defaultValues)
  })

  return (
    <Field name={name}>
      {({ field, form }) => {
        return (
          <FormControl
            error={isNotValid(form, name)}
            className={classes.formControl}
          >
            <MaterialAutocomplete
              {...rest}
              options={options}
              value={value}
              getOptionLabel={(option) => getOptionLabel(option)}
              getOptionSelected={(option, value) => getSelected(option, value)}
              onChange={(e, value) => {
                setValue(value)
                form.setFieldValue(name, value)
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...field}
                    {...params}
                    variant="outlined"
                    required={required}
                    label={trans(label)}
                    error={isNotValid(form, name)}
                    helperText={validateInput(form, name)}
                  />
                )
              }}
            />
          </FormControl>
        )
      }}
    </Field>
  )
}

export default AutoComplete

AutoComplete.propTypes = {
  id: PropTypes.string,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  limitTags: PropTypes.number,
  setFieldValue: PropTypes.func,
  label: PropTypes.string.isRequired,
  disableCloseOnSelect: PropTypes.bool,
  defaultValues: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
}

AutoComplete.defaultProps = {
  options: [],
  limitTags: 3,
  id: Random.id(),
  disabled: false,
  multiple: false,
  fullWidth: true,
  required: false,
  defaultValues: "",
  disableCloseOnSelect: false,
  setFieldValue: (name, value) => {},
}
