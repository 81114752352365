// Dependencies
import React from "react"
// Shared Components
import { ReactComponent as RolesSVG } from "shared/components/Icon/Roles/roles.svg"
// Material Ui
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
  gActiveTag: {
    "& g": {
      fill: "#42a5f5",
    },
    width: "26px",
    height: "26px",
    fontSize: "1.5rem",
  },
  gDefaultTag: {
    "& g": {
      fill: "rgba(0, 0, 0, 0.54)",
    },
    width: "26px",
    height: "26px",
    fontSize: "1.5rem",
  },
})

function CustomIcon(props) {
  const classes = useStyles()
  return <RolesSVG className={classes.gDefaultTag} />
}

export default CustomIcon
