// Dependencies
import { Obj } from "reinforcements"
// Core Files
import Globals from "core/globals"
import config from "core/configuration/config"
import history from "core/Routes/routerHistory"

/**
 * List of locale code object
 *
 * @const {object}
 */
const localeCodeList = config.get("locales")

/**
 * List of locale code only in an array
 *
 * @const {array}
 */
export const localeCodes = Object.keys(localeCodeList)

/**
 * Current locale code that will be changed later for locale change in the router
 *
 * @var {string}
 */
let currentLocaleCode = document.documentElement.lang

/**
 * get direction of the given locale code
 *
 * @param {string} localCode
 * @returns {string}
 */
export function directionOf(localeCode) {
  return Obj.get(localeCodeList, localeCode + ".direction")
}

/**
 * Get current locale code
 *
 * @returns {string}
 */
export function getCurrentLocaleCode() {
  return currentLocaleCode
}

/**
 * Set current locale code at the beginning of the application
 */
export function initiateLocaleCode() {
  // /en/users => /users
  // First remove the first slash from the URL, then split the pathName by /
  // then get the first segment of the created array
  let [localeCode] = history.location.pathname.replace(/\//, "").split("/")

  if (localeCodes.includes(localeCode)) {
    updateCurrentLocaleCode(localeCode)
  }
}

/**
 * Update current locale code
 *
 * @param {string} localeCode
 * @returns {void}
 */
export function updateCurrentLocaleCode(localeCode) {
  //   console.log("updateCurrentLocaleCode", localeCode)
  if (localeCode === currentLocaleCode) return
  if (localeCode) Globals.localeCode = localeCode
  document.documentElement.dir = directionOf(localeCode)
  document.documentElement.lang = localeCode
  currentLocaleCode = localeCode
}
