// Shared Files
import modules from "shared/modules/modules"

// List of all modules
const modulesList = {}

// Spread all entries into object
for (let moduleInfo of modules) {
  moduleInfo.load = () =>
    import(`modules/${moduleInfo.module}/serviceProvider.js`)
  // loop over the entry array
  for (let entryRoute of moduleInfo.entry) {
    modulesList[entryRoute] = moduleInfo
  }
}

export default modulesList
