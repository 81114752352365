// Core Files
import PositionForm from "./PositionForm"
import SetUpPositionForm from "modules/Products/helpers/SetUpPositionForm"
// Shared Components
import DateFormatter from "tables/Formatters/DateFormatter"
import PositionService from "modules/Products/services/PositionService"
import masterTableActions from "tables/TableComponents/TableActions/MasterActions"
import tableCheckBoxAll from "tables/TableComponents/TableDefaultItems/CheckBoxAll"

export default {
  type: "Modal",
  title: "positions",
  service: PositionService,
  formOptions: {
    form: PositionForm,
    modalLable: "position",
    formikLable: "position_",
    setUpForm: SetUpPositionForm,
  },
  table: {
    orderBy: "id",
    heading: "positionsList",
    columns: [
      tableCheckBoxAll,
      { key: "id", heading: "#", filter: false },
      { key: "position", heading: "position", allowfilter: false },
      { key: "importance", heading: "importance", allowfilter: false },
      {
        filter: false,
        key: "created_at",
        heading: "created_at",
        formatter: DateFormatter,
      },
      {
        filter: false,
        key: "updated_at",
        heading: "updated_at",
        formatter: DateFormatter,
      },
      masterTableActions(["delete", "edit"]),
    ],
  },
}
