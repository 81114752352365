// Dependencies
import React from "react"
import PropTypes from "prop-types"
import { Random } from "reinforcements"
// Core Files
import { trans } from "core/localization"
// Material Ui + Styles
import {
  MenuItem,
  MenuList,
  Typography,
  Menu as MUIMenu,
} from "@material-ui/core"
import Checkbox from "materialui/CheckBox"

function Menu(props) {
  const { options, anchorEl, onChange, checkbox, ...rest } = props

  const handleChange = (selected) => {
    onChange && onChange(selected)
  }

  return (
    <MUIMenu {...rest} anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)}>
      <MenuList>
        {options.map((option, index) => (
          <MenuItem key={index} onClick={(e) => handleChange(option)}>
            {checkbox && <Checkbox checked={!option.filter} />}
            <Typography variant="inherit">{trans(option.heading)}</Typography>
          </MenuItem>
        ))}
      </MenuList>
    </MUIMenu>
  )
}

export default Menu

Menu.propTypes = {
  id: PropTypes.string,
  onClose: PropTypes.func,
  checkbox: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired,
}

Menu.defaultProps = {
  checkbox: false,
  id: Random.id(),
}
