// Dependencies
import React from "react"
import { Field } from "formik"
import PropTypes from "prop-types"
// Core Files
import { trans } from "core/localization"
// Material Ui
import Switch from "@material-ui/core/Switch"
import FormControlLabel from "@material-ui/core/FormControlLabel"

export default function SwitchButton(props) {
  const { label, name, onChange, color, readOnly, className, ...rest } = props

  const switchInput = (field) => (
    <Switch
      {...rest}
      {...field}
      color={color}
      checked={field.value}
      className={className}
    />
  )

  return (
    <Field name={name}>
      {({ field }) => {
        return (
          <FormControlLabel
            id={name}
            label={trans(label)}
            control={switchInput(field)}
          />
        )
      }}
    </Field>
  )
}

SwitchButton.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
  checked: PropTypes.bool,
  label: PropTypes.string,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  defaultChecked: PropTypes.bool,
}

SwitchButton.defaultProps = {
  label: "",
  checked: false,
  readOnly: false,
  color: "primary",
  onChange: () => {},
}
