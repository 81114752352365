// Core Files
import Globals from "core/globals"

export default function getFormattedDate(value, padding = true, slash = false) {
  const divider = slash ? "/" : "-"
  const date = new Date(value)
  const ye = new Intl.DateTimeFormat(Globals.localeCode, {
    year: "numeric",
  }).format(date)
  const mo = new Intl.DateTimeFormat(Globals.localeCode, {
    month: "numeric",
  }).format(date)
  const da = new Intl.DateTimeFormat(Globals.localeCode, {
    day: "2-digit",
  }).format(date)

  return padding
    ? `${da} ${divider} ${mo} ${divider} ${ye}`
    : `${da}${divider}${mo}${divider}${ye}`
}

export function arabicToEnglishNumbers(str) {
  const arabicNumbers = [
    /٠/g,
    /١/g,
    /٢/g,
    /٣/g,
    /٤/g,
    /٥/g,
    /٦/g,
    /٧/g,
    /٨/g,
    /٩/g,
  ]
  if (typeof str === "string") {
    for (var i = 0; i < 10; i++) {
      str = str.replace(arabicNumbers[i], i)
    }
  }
  return str
}
