// Core Files
import BooleanFormatter from "tables/Formatters/BooleanFormatter"

const tableActive = {
  filter: false,
  key: "active",
  heading: "active",
  formatter: BooleanFormatter,
}

export default tableActive
