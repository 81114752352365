// Core Files
import MainPageForm from "core/formik/MainPageForm"
import SetUpCustomerForm from "modules/Customers/helpers/SetUpCustomerForm"
// Shared Components
import {
  createdAt,
  updatedAt,
} from "tables/TableComponents/TableDefaultItems/TimeStamps"
import {
  FilterOptionComponent,
  ImportOptionComponent,
} from "tables/TableComponents/TableActions/Helpers/OptionsContent"
import BisanCustomerForm from "core/services/Bisan/BisanCustomerForm"
import EmailFormatter from "tables/Formatters/EmailFormatter"
import NumberFormatter from "tables/Formatters/NumberFormatter"
import BooleanFormatter from "tables/Formatters/BooleanFormatter"
import CustomerService from "modules/Customers/services/CustomerService"
import masterTableActions from "tables/TableComponents/TableActions/MasterActions"
import tableCheckBoxAll from "tables/TableComponents/TableDefaultItems/CheckBoxAll"
import masterFilterCheckboxs from "tables/TableComponents/TableActions/MasterCheckboxs"
import masterImportButtons from "tables/TableComponents/TableActions/MasterImportButtons"

export default {
  type: "Page", // 'Modal' Or 'Page' for adding & editing
  title: "customers",
  service: CustomerService,
  tabOptions: {
    tabs: [
      { label: "details", value: MainPageForm },
      { label: "bisan", value: BisanCustomerForm },
    ],
  },
  formOptions: {
    modalLable: "customer",
    formikLable: "customer_",
    setUpForm: SetUpCustomerForm,
  },
  table: {
    orderBy: "id",
    heading: "customersList",
    columns: [
      tableCheckBoxAll,
      // { key: "id", heading: "#", filter: false },
      { key: "code", heading: "code", allowfilter: false },
      { key: "name", heading: "name", allowfilter: false },
      {
        filter: false,
        key: "salespersons",
        arrAttribute: "name",
        heading: "salesperson",
        // formatter: Link,
      },
      {
        key: "email",
        filter: true,
        heading: "email",
        formatter: EmailFormatter,
      },
      {
        filter: false,
        key: "active",
        heading: "active",
        formatter: BooleanFormatter,
      },
      { filter: true, key: "last_visit", heading: "last_visit" },
      { filter: true, key: "last_order_date", heading: "last_order_date" },
      { filter: true, key: "last_payment_date", heading: "last_payment_date" },
      { filter: true, key: "phone", heading: "phone" },
      { filter: true, key: "fax", heading: "fax" },
      { filter: true, key: "country", heading: "country" },
      { filter: true, key: "city", heading: "city" },
      {
        filter: false,
        key: "balance",
        heading: "balance",
        formatter: NumberFormatter,
      },
      {
        filter: true,
        key: "target",
        heading: "target",
        formatter: NumberFormatter,
      },
      {
        filter: true,
        key: "credit_limit",
        heading: "credit_limit",
        formatter: NumberFormatter,
      },
      {
        filter: true,
        key: "post_dated_checks_total",
        heading: "post_dated_checks_total",
      },
      {
        filter: true,
        key: "note",
        heading: "notes",
      },
      createdAt,
      updatedAt,
      masterTableActions(["delete", "edit"]),
    ],
  },
  advancedOptions: [
    {
      component: FilterOptionComponent,
      configuration: { checkboxs: masterFilterCheckboxs(["active"]) },
    },
    {
      component: ImportOptionComponent,
      configuration: { buttons: masterImportButtons(["import_data"]) },
    },
  ],
  fileOptions: {
    order: [
      "name",
      "alternate_name",
      "code",
      "phone",
      "email",
      "fax",
      "building_num",
      "street",
      "city",
      "country",
      "note",
      "balance",
      "target",
      "credit_limit",
      "post_dated_checks_total",
      "active",
      "allow_once",
      "salesperson_name",
    ],
  },
}
