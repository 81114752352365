// Routes
import routes from "./routes"

// Localization
import "modules/Updates/locales/en"
import "modules/Updates/locales/ar"

export default function (reactor) {
  routes(reactor.route)
}
