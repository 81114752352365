// Dependencies
import React from "react"
import { useHistory } from "react-router-dom"
//Core Files & Hooks
import useTable from "tables/hooks/useTable"
import { trans } from "core/localization/index"
import useRowProvider from "tables/hooks/useRowProvider"
import { pageParam } from "core/formik/helpers/standardFormikHelper"
// Shared Components
import Tooltip from "materialui/ToolTip/MasterToolTip"
import TableForm from "tables/TableComponents/TableForm/TableForm"
// Material Ui + Styles
import Edit from "@material-ui/icons/Edit"
import { IconButton } from "@material-ui/core"

export default function TableEditButton() {
  let action = "edit"
  const history = useHistory()
  const [formIsDisplayed, openForm] = React.useState(false)
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const { options } = useTable()
  const { record, rowIndex } = useRowProvider()
  const isSubmittingObj = { isSubmitting, setIsSubmitting }

  const handleClick = (e) => {
    if (options.type === "Page") {
      history.push(`/${pageParam(history, 1)}/${record.id}`)
    } else openForm(true)
  }

  return (
    <>
      <IconButton onClick={handleClick} color="primary">
        <Tooltip title={trans(action)}>
          <Edit />
        </Tooltip>
      </IconButton>

      <TableForm
        action={action}
        record={record}
        recordIndex={rowIndex}
        open={formIsDisplayed}
        service={options.service}
        formOptions={options.formOptions}
        isSubmittingObj={isSubmittingObj}
        onConfirm={() => openForm(false)}
      />
    </>
  )
}
