// Dependencies
import React from "react"
import { Field } from "formik"
import PropTypes from "prop-types"
// Core Files
import { trans } from "core/localization/index"
import useFormik from "core/formik/hooks/useFormik"
// Material Ui + Custom Style
import useFormStyles from "materialui/Styles"
import { TextField, FormControl } from "@material-ui/core"
import { isNotValid, validateInput } from "materialui/Validation/index"

function InputFormik(props) {
  const classes = useFormStyles()
  const { values = {} } = useFormik()
  const { label, name, ...rest } = props
  const [value, setValue] = React.useState(values[name] || "")

  return (
    <Field name={name} style={{ width: "100%" }}>
      {({ field, form }) => {
        return (
          <FormControl
            error={isNotValid(form, name)}
            className={classes.formControl}
          >
            <TextField
              id={name}
              {...rest}
              {...field}
              value={value}
              label={trans(label)}
              error={isNotValid(form, name)}
              helperText={validateInput(form, name)}
              onChange={(e) => setValue(e.target.value)}
              onBlur={() => form.setFieldValue(name, value)}
            />
          </FormControl>
        )
      }}
    </Field>
  )
}

export default InputFormik

InputFormik.propTypes = {
  type: PropTypes.string,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  variant: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

InputFormik.defaultProps = {
  type: "text",
  required: false,
  fullWidth: true,
  variant: "outlined",
  onFocus: (e) => e.target.select(),
}
