// Dependencies
import clsx from "clsx"
import React from "react"
import PropTypes from "prop-types"
import Is from "@flk/supportive-is"
import { Random } from "reinforcements"
// Core Files
import InputAdornment from "./InputAdornment"
import { trans } from "core/localization/index"
// Material Ui + Custom Style
import { TextField } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import SearchIcon from "@material-ui/icons/Search"
import useInputStyles from "materialui/Styles/inputStyle"

function Input(props) {
  const classes = useInputStyles()
  let {
    label,
    className,
    startIcon,
    endIcon,
    placeholder,
    setSearchText,
    ...rest
  } = props
  const InputClass = clsx(classes.root, classes.adornmentRight, className)

  let firstSegLabel = ""
  if (Is.array(label)) {
    firstSegLabel = trans(label[0])
    label.shift()
    label = label.map((l) => trans(l))
  } else {
    firstSegLabel = trans(label)
    label = []
  }

  if (Is.boolean(endIcon)) endIcon = <CloseIcon />
  if (Is.boolean(startIcon)) startIcon = <SearchIcon />

  const displayIcons = () => {
    return {
      startAdornment: startIcon && <InputAdornment icon={startIcon} />,
      endAdornment: endIcon && (
        <InputAdornment
          iconButton
          position="end"
          icon={endIcon}
          disabled={!rest.value ? true : false}
          onClick={(e) => {
            setSearchText("")
            rest.onKeyPress(e, "clear")
          }}
        />
      ),
    }
  }
  return (
    <TextField
      {...rest}
      className={InputClass}
      InputProps={displayIcons()}
      label={placeholder ? "" : `${firstSegLabel} ${label.join(" ")}`}
      placeholder={placeholder ? `${firstSegLabel} ${label.join(" ")}` : null}
    />
  )
}

export default Input

Input.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  variant: PropTypes.string,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  endIcon: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  startIcon: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
}

Input.defaultProps = {
  type: "text",
  id: Random.id(),
  required: false,
  placeholder: true,
  variant: "outlined",
  onFocus: (e) => e.target.select(),
}
