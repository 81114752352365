// Dependencies
import * as Yup from "yup"

export default class SetUpOrderForm {
  constructor(record) {
    this.initialValues = {
      id: record.id,
      code: record.code || "",
      date: record.date || "",
      note: record.note || "",
      status: record.status || "",
      total_price: record.total_price || "",
      _customer_id: record.customer_id || "",
      _employee_id: record.employee_id || "",
      order_discount: record.order_discount || "0",
    }

    this.validationSchema = Yup.object({
      note: Yup.string(),
      total_price: Yup.number(),
      date: Yup.date().nullable(),
      code: Yup.string().required("Required"),
      order_discount: Yup.number().min(0).max(100),
      status: Yup.string().required("Required").nullable(),
      _customer_id: Yup.string().required("Required").nullable(),
      _employee_id: Yup.string().required("Required").nullable(),
    })
  }

  getInitialValues() {
    return this.initialValues
  }
  getValidationSchema() {
    return this.validationSchema
  }
}
