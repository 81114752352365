// Core Files
import RestfullEndpoint from "core/RestfulApi/RestfulApiEndpoint"

export class OrderService extends RestfullEndpoint {
  route = "/orders"

  /**
   * Update records using endpoint api
   *
   * @param   {object} params
   * @returns {Promise}
   */
  updateList(params) {
    return this.endpoint.put(`${this.route}/updateList`, params)
  }

  /**
   * Fetch records from endpoint api
   *
   * @param   {object} params
   * @returns {Promise}
   */
  getList(params) {
    return this.endpoint.get(`${this.route}/getList`, {
      params,
    })
  }
}

export default new OrderService()
