import { blue } from "@material-ui/core/colors"

const { makeStyles } = require("@material-ui/core")
const useFormStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    width: "100%",
    margin: "17px 0px 10px 0px",
  },
  passwordOutlined: {
    marginBottom: "0px",
  },
  helperTextError: {
    margin: "2px 14px 8px 20px",
  },
  button: {
    height: "42px",
    margin: theme.spacing(3, 0, 2),
  },
  progressBar: {
    color: "#9e9e9e",
  },
  chip: {
    margin: 2,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  bold: {
    fontWeight: "bold",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  dateTextField: {
    width: 289.6,
    // marginLeft: theme.spacing(2),
    // marginRight: theme.spacing(1),
  },
  alignRight: {
    textAlign: "right",
  },
  alignLeft: {
    textAlign: "left",
  },
  alignCenter: {
    textAlign: "center",
  },
  marginLeftRight: {
    marginLeft: "15px",
    marginRight: "15px",
  },
  title: {
    flex: "1 1 100%",
    fontWeight: "bold",
  },
  paddingTop: {
    paddingTop: "10px",
  },
  zeroPadding: {
    padding: "0px",
  },
  toolBarPadding: {
    padding: "5px",
  },
  marginRight: {
    marginRight: "20px",
  },
  switchMarginRight: {
    marginRight: "11px",
  },
  clickableText: {
    color: blue[300],
    fontWeight: "bold",
  },
  simpleTablePaper: {
    backgroundColor: "#dfdfdfdf",
  },
}))

export default useFormStyles
