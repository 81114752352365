// Dependencies
import * as Yup from "yup"
import { Formik, Form } from "formik"
import React, { useState } from "react"
import { useHistory } from "react-router-dom"
// Core Files & Hooks
import user from "user"
import useOnce from "core/hooks/useOnce"
import { trans } from "core/localization/index"
import FormikControl from "core/formik/FormikControl"
import { setMetaData } from "core/configuration/metaData"
// Services
import { login } from "modules/Users/services/auth"
// Shared
import { withRTL } from "shared/themes/withRTL"
import Copyright from "shared/components/Copyright"
// Material UI + Styles
import {
  Box,
  Avatar,
  Container,
  Typography,
  CssBaseline,
} from "@material-ui/core"
import useLoginStyles from "./LoginStyle"
import AlertMessage from "shared/components/Alert"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"

function LoginForm() {
  const history = useHistory()
  const classesLogin = useLoginStyles()
  const [errorState, setErrorState] = useState("")

  const initialValues = {
    email: "",
    password: "",
  }

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid Email Format").required("Required"),
    password: Yup.string().required("Required"),
  })

  const onSubmit = (values, { setSubmitting }) => {
    //Clear Error onSubmit
    setErrorState("")
    login(values)
      .then((response) => {
        if (response && response.data) {
          user.logUserIn(response.data)
          history.push("/") // home page
        }
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setSubmitting(false)
          setErrorState(err.response.data)
        }
      })
  }

  // ComponentWillMount behavior (means run the function before rendering)
  // Middleware will do the job instead of check it manually
  // useBeforeFirstRender(() => {
  //   // Execute before render
  //   if (user.isLoggedIn()) {
  //     // redirect to the home page
  //     // navigateTo("/users", history)
  //     // history.push("/users")
  //   }
  // })

  useOnce(() => {
    setMetaData("title", "Login")
    setMetaData("description", "Some Login Description")
  })

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classesLogin.paper}>
        <Avatar className={classesLogin.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {trans("login")}
        </Typography>
        <Formik
          onSubmit={onSubmit}
          className={classesLogin.form}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {(formik) => (
            <Form>
              <FormikControl
                required
                name="email"
                type="email"
                label="email"
                control="input"
              />
              <FormikControl
                required
                name="password"
                label="password"
                control="password"
              />
              <FormikControl
                fullWidth
                type="submit"
                control="button"
                name="buttonlogin"
                label={formik.isSubmitting ? "waiting" : "login"}
                disabled={!formik.isValid || formik.isSubmitting}
              />
            </Form>
          )}
        </Formik>

        {errorState !== "" && (
          <AlertMessage type="error" label={`User is ${errorState.error}`} />
        )}
      </div>
      <Box mt={7}>
        <Copyright />
      </Box>
    </Container>
  )
}

// Props-Types for css ClassName
// className: : PropTypes.oneOfType([
//   PropTypes.string,
//   PropTypes.object,
// ]),

// FormikControl.propTypes = {
//   color: PropTypes.string,
//   variant: PropTypes.string,
//   fullWidth: PropTypes.bool,
//   isDisabled: PropTypes.bool,
//   isRequired: PropTypes.bool,
//   name: PropTypes.string.isRequired,
//   type: PropTypes.string.isRequired,
//   label: PropTypes.string.isRequired,
//   control: PropTypes.string.isRequired,
// }

// FormikControl.defaultProps = {
//   fullWidth: true,
//   isRequired: true,
// }

export default withRTL(LoginForm)
