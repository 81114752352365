export const handleRowClick = (id, tableSelectedRow) => {
  const { selected, setSelected } = tableSelectedRow
  const selectedIndex = selected.indexOf(id)
  let newSelected = []
  if (selectedIndex === -1) {
    newSelected = newSelected.concat(selected, id)
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(selected.slice(1))
  } else if (selectedIndex === selected.length - 1) {
    newSelected = newSelected.concat(selected.slice(0, -1))
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(
      selected.slice(0, selectedIndex),
      selected.slice(selectedIndex + 1)
    )
  }
  setSelected(newSelected)
}

export const isSelected = (name, selected) => selected.indexOf(name) !== -1
