// Dependencies
import React from "react"
import PropTypes from "prop-types"
// Shared Components
import { DateRangePicker } from "materialui-daterange-picker"

function DateRange({ open, closeModal, onChange, ...rest }) {
  const handleOnChange = (range) => {
    onChange(range)
  }

  return (
    <DateRangePicker
      open={open}
      toggle={closeModal}
      onChange={handleOnChange}
      {...rest}
    />
  )
}

DateRange.propTypes = {
  onChange: PropTypes.func,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  open: PropTypes.bool.isRequired,
  initialDateRange: PropTypes.object,
  closeOnClickOutside: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
}

DateRange.defaultProps = {
  closeOnClickOutside: true,
}

export default DateRange
