import cache from "core/cache"

class User {
  constructor() {
    this.userData = cache.get("user")
  }

  /**
   * Check if user logged in
   *
   * @return {boolean}
   */
  isLoggedIn() {
    return this.userData !== null
  }

  /**
   * Log the user in
   * It will store the data in the storage engine i.e Local Storage
   * But will not make any ajax request
   *
   * @param {objec} userData
   * @returns {void}
   */
  logUserIn(userData) {
    this.userData = userData
    cache.set("user", userData)
  }

  logUserOut() {
    this.userData = null
    cache.remove("user")
  }

  /**
   * Get user access token
   *
   * @returns {string}
   */
  getAccessToken() {
    return this.userData.success.token
  }
}

export default new User()
