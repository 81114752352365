// Shared Components
import ImportDataButton from "./Helpers/ImportData"
import ImportImagesButton from "./Helpers/ImportImages"
// Material Ui
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined"
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined"

const masterImportButtons = (allowedBtns) => {
  const masterButtons = [
    {
      key: "import_data",
      heading: "import_data",
      component: ImportDataButton,
      icon: InsertDriveFileOutlinedIcon,
    },
    {
      key: "import_images",
      icon: ImageOutlinedIcon,
      heading: "import_images",
      component: ImportImagesButton,
    },
  ]
  return masterButtons.filter((btn) => allowedBtns.includes(btn.key))
}

export default masterImportButtons
