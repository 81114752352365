// Dependencies
import React from "react"
import PropTypes from "prop-types"
// import Is from "@flk/supportive-is"
// Core Files
import { trans } from "core/localization"
// Material Ui
import { Tab, Box, Tabs, Container, Paper } from "@material-ui/core"
import useMasterContentStyles from "layout/Contents/MasterContentStyle"

function TabPanel(props) {
  const classes = useMasterContentStyles()
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container className={classes.tabContainer}>
          <Box p={2}>{children}</Box>
        </Container>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  }
}

export default function ScrolledTabs(props) {
  const { tabs, startPage, ...rest } = props
  const [value, setValue] = React.useState(startPage || 0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <>
      <Tabs value={value} onChange={handleChange} {...rest}>
        {tabs.map((option, index) => {
          let label = trans(option.label)
          return <Tab key={index} label={label} {...a11yProps(index)} />
        })}
      </Tabs>
      {tabs.map((option, index) => {
        return (
          <TabPanel key={index} value={value} index={index}>
            <Paper elevation={5}>
              <option.value />
            </Paper>
          </TabPanel>
        )
      })}
    </>
  )
}

ScrolledTabs.propTypes = {
  variant: PropTypes.string,
  startPage: PropTypes.number,
  textColor: PropTypes.string,
  scrollButtons: PropTypes.string,
  indicatorColor: PropTypes.string,
  tabs: PropTypes.array.isRequired,
}

ScrolledTabs.defaultProps = {
  textColor: "primary",
  variant: "scrollable",
  scrollButtons: "auto",
  indicatorColor: "secondary",
}
