import localization from "core/localization/index"

localization.extend("ar", {
  filterList: "عرض ألأعمدة",
  moreOptions: "خيارات اخرى",
  table: {
    labelRowsPerPage: "عرض",
    displayedRows: "عرض %d-%d من %d",
  },
})
