// Dependencies
import clsx from "clsx"
import React from "react"
// Material Ui
import Alert from "@material-ui/lab/Alert"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
}))

function AlertMessage(props) {
  const classes = useStyles()
  const { type, label, className, ...rest } = props
  const classNameAler = clsx(classes.root, className)
  return (
    <Alert className={classNameAler} severity={type} {...rest}>
      {label}
    </Alert>
  )
}

export default AlertMessage
