// Core Files
import TableEditButton from "./Edit"
import TableDeleteButton from "./Delete"
import TableViewButton from "./View"
import ButtonsFormatter from "tables/Formatters/ButtonsFormatter"
import ToolBarPrinterButton from "tables/TableComponents/TableToolBars/Printer"

const masterTableActions = (allowedBtns) => {
  const allButtons = [
    { key: "view", comp: TableViewButton },
    { key: "print", comp: ToolBarPrinterButton },
    { key: "edit", comp: TableEditButton },
    { key: "delete", comp: TableDeleteButton },
  ]
  const arrAllowedBtns = []
  allButtons.forEach((btn) => {
    if (allowedBtns.includes(btn.key)) arrAllowedBtns.push(btn.comp)
  })
  return {
    key: "actions",
    heading: "actions",
    allowfilter: false,
    formatter: ButtonsFormatter,
    buttons: arrAllowedBtns,
  }
}

export default masterTableActions
