// Dependencies
import React from "react"
import PropTypes from "prop-types"
import Is from "@flk/supportive-is"
import { Random } from "reinforcements"
// Shared Component
import {
  FileButtonText,
  FileInputWrapper,
  FileButtonWrapper,
} from "./FileInputHelper"
import HiddenInputFile from "./HiddenInputFile"
import { PrimaryButton } from "materialui/Button/Button"
// Material Ui
import { FormControl, FormLabel } from "@material-ui/core"
import CloudUploadIcon from "@material-ui/icons/CloudUpload"

function FileInput(props) {
  const fileInputRef = React.useRef()
  let {
    id,
    name,
    label,
    accept,
    onChange,
    buttonText,
    buttonIcon,
    ButtonComponent,
  } = props
  const [fielSelected, setFileSelected] = React.useState(buttonText)
  const buttonStyle = { height: "42px" }

  const openFileDialog = (e) => {
    fileInputRef.current.click()
  }

  const onFileSelection = (e) => {
    const selectedFile = e.target.files[0].name
    setFileSelected(selectedFile)
    onChange(e)
  }

  if (Is.array(accept)) {
    accept = accept.map((extension) => "." + extension).join(",")
  }

  return (
    <FileInputWrapper>
      <FormControl>
        <FormLabel htmlFor="fileinput">{label}</FormLabel>
        <FileButtonWrapper>
          <ButtonComponent
            id={id}
            name="fileinput"
            onClick={openFileDialog}
            classNameStyle={buttonStyle}
          >
            {buttonIcon}
            <FileButtonText>{fielSelected}</FileButtonText>
          </ButtonComponent>
        </FileButtonWrapper>
        <HiddenInputFile
          name={name}
          accept={accept}
          ref={fileInputRef}
          onChange={onFileSelection}
        />
      </FormControl>
    </FileInputWrapper>
  )
}

export default FileInput

FileInput.propTypes = {
  accept: PropTypes.any,
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  ButtonComponent: PropTypes.any,
  buttonIcon: PropTypes.node.isRequired,
  buttonText: PropTypes.string.isRequired,
}

FileInput.defaultProps = {
  id: Random.id(),
  onChange: () => {},
  buttonText: "Select File",
  ButtonComponent: PrimaryButton,
  buttonIcon: <CloudUploadIcon />,
}
