// Core Files
import MainPageForm from "core/formik/MainPageForm"
import SetUpCategoryForm from "modules/Categories/helpers/SetUpCategoryForm"
// Shared Components
import {
  createdAt,
  updatedAt,
} from "tables/TableComponents/TableDefaultItems/TimeStamps"
import {
  FilterOptionComponent,
  ImportOptionComponent,
} from "tables/TableComponents/TableActions/Helpers/OptionsContent"
import CategoryService from "modules/Categories/services/CategoryService"
import tableActive from "tables/TableComponents/TableDefaultItems/Active"
import NothingExistFormatter from "tables/Formatters/NothingExistFormatter"
import masterTableActions from "tables/TableComponents/TableActions/MasterActions"
import tableCheckBoxAll from "tables/TableComponents/TableDefaultItems/CheckBoxAll"
import masterFilterCheckboxs from "tables/TableComponents/TableActions/MasterCheckboxs"
import masterImportButtons from "tables/TableComponents/TableActions/MasterImportButtons"

export default {
  type: "Page", // 'Modal' Or 'Page' for adding & editing
  title: "categories",
  service: CategoryService,
  tabOptions: {
    tabs: [{ label: "details", value: MainPageForm }],
  },
  formOptions: {
    modalLable: "category",
    formikLable: "category_",
    setUpForm: SetUpCategoryForm,
  },
  table: {
    orderBy: "id",
    heading: "categoriesList",
    columns: [
      tableCheckBoxAll,
      { key: "id", heading: "#", filter: false },
      { key: "code", heading: "code", allowfilter: false },
      { key: "name", heading: "name", allowfilter: false },
      { key: "arabic_name", heading: "arabic_name", filter: true },
      { key: "alternate_name", heading: "alternate_name", filter: true },
      {
        filter: false,
        key: "parent",
        nothingKey: "root",
        arrAttribute: "name",
        heading: "category_parent",
        formatter: NothingExistFormatter,
      },
      { filter: true, key: "value", heading: "value" },
      {
        center: true,
        filter: false,
        key: "products_count",
        heading: "products_count",
      },
      tableActive,
      createdAt,
      updatedAt,
      masterTableActions(["delete", "edit"]),
    ],
  },
  advancedOptions: [
    {
      component: FilterOptionComponent,
      configuration: { checkboxs: masterFilterCheckboxs(["active"]) },
    },
    {
      component: ImportOptionComponent,
      configuration: { buttons: masterImportButtons(["import_data"]) },
    },
  ],
  fileOptions: {
    order: [
      "code",
      "name",
      "arabic_name",
      "alternate_name",
      "category_code",
      "active",
      "value",
      "type",
    ],
  },
}
