// Dependncies
import clsx from "clsx"
import React from "react"
import PropTypes from "prop-types"
// Core Files
import { trans } from "core/localization"
// Material Ui
import { Typography as MTypography } from "@material-ui/core"

function Typography({ label, className, ...rest }) {
  const classes = clsx(className)
  return (
    <MTypography className={classes} {...rest}>
      {trans(label)}
    </MTypography>
  )
}

export default Typography

Typography.propTypes = {
  noWrap: PropTypes.bool,
  variant: PropTypes.string,
  component: PropTypes.string,
  label: PropTypes.string.isRequired,
}

Typography.defaultProps = {
  noWrap: true,
  variant: "h6",
  component: "div",
}
