// Dependencies
import React from "react"
// Core Files
import FormikControl from "core/formik/FormikControl"
import EmployeeService from "modules/Employees/services/EmployeeService"
import { getDefautlValue } from "core/formik/helpers/standardFormikHelper"
// Shared Components
import GridItem from "materialui/Grid/GridItem"
import GridDivider from "materialui/Grid/GridDivider"
import GridContainer from "materialui/Grid/GridContainer"

function BodyCustomer({ record }) {
  let defaultValue = getDefautlValue(record, "salespersons")

  return (
    <GridContainer spacing={0}>
      {/* Row 1 */}
      <GridDivider label="personal_info" />
      <GridContainer>
        <GridItem xs={3}>
          <FormikControl required label="code" control="number" name="code" />
        </GridItem>
        <GridItem xs={9}>
          <FormikControl required name="name" label="name" control="input" />
        </GridItem>
      </GridContainer>

      {/* Row 2 */}
      <GridDivider label="contact_info" />
      <GridContainer>
        <GridItem xs={8}>
          <FormikControl
            name="email"
            type="email"
            label="email_"
            control="input"
          />
        </GridItem>
        <GridItem>
          <FormikControl
            required
            backendV="name"
            label="salesperson"
            name="_salesperson_id"
            control="lazyautocomplete"
            defaultValue={defaultValue}
            request={() => EmployeeService.salespersons()}
          />
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={3}>
          <FormikControl name="country" label="country" control="input" />
        </GridItem>
        <GridItem xs={3}>
          <FormikControl name="city" label="city" control="input" />
        </GridItem>
        <GridItem xs={3}>
          <FormikControl name="phone" label="phone" control="number" />
        </GridItem>
        <GridItem xs={3}>
          <FormikControl name="fax" label="phone2" control="number" />
        </GridItem>
      </GridContainer>

      {/* Row 3 */}
      <GridDivider label="others_info" />
      <GridContainer>
        <GridItem xs={3}>
          <FormikControl name="balance" label="balance" control="number" />
        </GridItem>
        <GridItem xs={3}>
          <FormikControl name="target" label="target" control="number" />
        </GridItem>
        <GridItem xs={3}>
          <FormikControl
            control="number"
            name="credit_limit"
            label="credit_limit"
          />
        </GridItem>
        <GridItem xs={3}>
          <FormikControl
            control="number"
            name="post_dated_checks_total"
            label="post_dated_checks_total"
          />
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem>
          <FormikControl control="date" name="last_visit" label="last_visit" />
        </GridItem>
        <GridItem>
          <FormikControl
            control="date"
            name="last_payment_date"
            label="last_payment_date"
          />
        </GridItem>
        <GridItem>
          <FormikControl
            control="date"
            name="last_order_date"
            label="last_order_date"
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <FormikControl name="note" label="notes" control="textarea" />
        </GridItem>
      </GridContainer>
    </GridContainer>
  )
}

export default BodyCustomer
