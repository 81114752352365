import localization from "core/localization/index"

localization.extend("ar", {
  sync_: "تزامن",
  _sync: "ألتزامن",
  action: "ألعملية",
  data: "ألبيانات",
  sync__: "ألتزامن",
  sync: "بيانات تزامن",
  target_table: "ألجدول",
  syncList: "قائمة ألتزامن",
  salespersons_count: "عدد ألمندوبين",
})
