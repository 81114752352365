// Dependencies
import React from "react"
// Core Files & Hooks
import { trans } from "core/localization"
import useFormik from "core/formik/hooks/useFormik"
import FormikControl from "core/formik/FormikControl"
import { pageInfo } from "core/formik/helpers/standardFormikHelper"
// Shared Components
import GridItem from "materialui/Grid/GridItem"
import GridContainer from "materialui/Grid/GridContainer"
// Material Ui
import { Typography } from "@material-ui/core"

export default function HeadEmployee({ options, action }) {
  const { isSubmitting } = useFormik()
  const pageTitleFull = pageInfo(options, action)

  return (
    <GridContainer spacing={0}>
      <GridItem xs={8}>
        <Typography noWrap variant="h6" component="div">
          {pageTitleFull}
        </Typography>
      </GridItem>
      <GridItem xs={2} alignItems="right">
        <FormikControl name="active" label="active" control="switchbutton" />
      </GridItem>
      <GridItem xs={2} alignItems="right">
        <FormikControl
          type="submit"
          control="button"
          name="buttonlogin"
          disabled={isSubmitting}
          label={isSubmitting ? "waiting" : trans("save")}
        />
      </GridItem>
    </GridContainer>
  )
}
