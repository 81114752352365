// Dependencies
import React from "react"
// Core Files
import { trans } from "core/localization"
// Shared Components
import { GreenBadge } from "materialui/Badge/MasterBadge"

function NothingExistFormatter(props) {
  const { column } = props
  if (column.value === null)
    return <GreenBadge children={trans(column.nothingKey)} />
  return column.value
}

export default NothingExistFormatter
