// Dependencies
import PropTypes from "prop-types"
import Is from "@flk/supportive-is"
import { Obj } from "reinforcements"
import React, { useState } from "react"
// Core Files
import { trans } from "core/localization"
import useMainPage from "core/formik/hooks/useMainPage"
// Shared Components
import FormModal from "layout/Modal/FormModal"
import { TextButton } from "materialui/Button/Button"
// Material Ui
import {
  Paper,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  TableContainer,
} from "@material-ui/core"
import useFormStyles from "materialui/Styles"

export default function SimpleTable(props) {
  const classes = useFormStyles()
  const formikRef = React.useRef(null)
  const [open, setOpen] = useState(false)
  const [actionForm, setActionForm] = useState("add")
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { options, records, FormContent, modalTComponent } = props

  const tableColumns = options.table.columns
  const [currentRecord, setCurrentRecord] = useState({})
  const [tableRecords, setTableRecords] = useState(records)
  const [alreadyExistRecords, setExistRecords] = useState([])
  let modalTitle = actionForm === "edit" ? "editItem" : "addItem"

  const { mainPageState, setMainPageState } = useMainPage()

  React.useEffect(() => {
    setMainPageState({ ...mainPageState, tableRecords })
    setExistRecords(
      tableRecords.map((record) => Obj.get(record, options.formOptions.exclude))
    )
    // eslint-disable-next-line
  }, [tableRecords])

  const THead = (props) => {
    const action = (
      <TableCell className={classes.bold}>
        <TextButton onClick={handleOpenForm} className={classes.clickableText}>
          {trans("add")}
        </TextButton>
      </TableCell>
    )

    return (
      <TableHead>
        <TableRow>
          {tableColumns.map((column, index) => {
            return (
              <React.Fragment key={index}>
                {column.heading === "actions" ? (
                  action
                ) : (
                  <TableCell className={classes.bold}>
                    {trans(column.heading)}
                  </TableCell>
                )}
              </React.Fragment>
            )
          })}
        </TableRow>
      </TableHead>
    )
  }

  let total = 0
  const TBody = (props) => {
    return (
      <TableBody>
        {tableRecords.map((record, rIndex) => {
          record.columnList = Obj.clone(tableColumns)
          return (
            <TableRow key={rIndex}>
              {record.columnList.map((column, cIndex) => {
                column.value = Obj.get(
                  column,
                  "value",
                  Obj.get(record, column.key)
                )

                if (column.heading === "total") total += column.value
                if (column.value === null) column.value = ""
                if (Is.object(column.value)) column.value = column.value["key"]

                const columnValue = column.formatter ? (
                  <column.formatter
                    column={column}
                    record={record}
                    recordIndex={rIndex}
                    handleOpenForm={handleOpenForm}
                    setTableRecords={setTableRecords}
                    deleteKey={options.formOptions.deleteKey}
                  />
                ) : (
                  column.value
                )

                column.cell = <TableCell key={cIndex}>{columnValue}</TableCell>
                return column.cell
              })}
            </TableRow>
          )
        })}
        {total > 0 ? (
          <TableRow>
            <TableCell colSpan={4} />
            <TableCell colSpan={1}>{trans("total")}</TableCell>
            <TableCell>{Number(total).toLocaleString()}</TableCell>
          </TableRow>
        ) : null}
      </TableBody>
    )
  }

  const handleOpenForm = (e, type = "add", record = {}, recordIndex = 0) => {
    setOpen(true)
    setActionForm(type)
    setIsSubmitting(false)
    setCurrentRecord({ record, index: recordIndex })
  }

  const handleOnConfirm = () => {
    setOpen(false)
  }

  const handleOnSubmit = () => {
    if (formikRef.current) {
      formikRef.current.handleSubmit() // this will trigger onSubmit inside the correct (target) form
    }
  }

  return (
    <>
      <TableContainer component={Paper} className={classes.simpleTablePaper}>
        <Table>
          <THead />
          <TBody />
        </Table>
      </TableContainer>

      <FormModal
        open={open}
        onClose={handleOnConfirm}
        onSubmit={handleOnSubmit}
        type={isSubmitting ? "waiting" : "save"}
        title={trans(modalTitle, trans(modalTComponent))}
      >
        <FormContent
          setOpen={setOpen}
          action={actionForm}
          reference={formikRef}
          recordObj={currentRecord}
          setIsSubmitting={setIsSubmitting}
          setTableRecords={setTableRecords}
          alreadyExistRecords={alreadyExistRecords}
        />
      </FormModal>
    </>
  )
}

SimpleTable.propTypes = {
  records: PropTypes.array.isRequired,
  options: PropTypes.object.isRequired,
  FormContent: PropTypes.any.isRequired,
  modalTComponent: PropTypes.string.isRequired,
}
