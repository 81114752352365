// Dependencies
import * as Yup from "yup"

export default class SetUpJobTitleForm {
  constructor(record) {
    this.initialValues = {
      id: record.id,
      name: record.name || "",
      arabic_name: record.arabic_name || "",
    }

    this.validationSchema = Yup.object({
      name: Yup.string().required("Required"),
      arabic_name: Yup.string().required("Required"),
    })
  }

  getInitialValues() {
    return this.initialValues
  }
  getValidationSchema() {
    return this.validationSchema
  }
}
