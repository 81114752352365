// Dependencies
import { Obj } from "reinforcements"
import React, { Component } from "react"
// Core Files
import { trans } from "core/localization"
import { getCurrentLocaleCode } from "core/localization/locales"
import { calculateOrderHeight, getSortedOrder } from "./helpers/SortHelpers"
import { getDatePickerFormatteRTL } from "core/formik/helpers/standardFormikHelper"
// Shared Components
import GridItem from "materialui/Grid/GridItem"
import GridContainer from "materialui/Grid/GridContainer"
// Material Ui
import {
  Paper,
  Table,
  TableRow,
  TableHead,
  TableBody,
  Typography,
  TableContainer,
} from "@material-ui/core"
import {
  StyledTableRow,
  StyledTableCell,
  OrderFooterWrapper,
  OrderHeaderWrapper,
} from "./styles"

class PreviewOrder extends Component {
  render() {
    let addMargin = 0
    let emptyChar = " "
    const pageHight = 1122
    const initialMargin = 60
    // let totalOrderHeight = 0
    // let isFirstOrderInPage = true
    return (
      <div>
        {this.props.orders.map((order, oIndex) => {
          let orderHeight = calculateOrderHeight(order)
          // if (orderHeight > pageHight)
          //   addMargin =
          //     (orderHeight - pageHight) * Math.ceil(orderHeight / pageHight)
          // else
          //   addMargin =
          //     (pageHight - orderHeight) * Math.ceil(pageHight / orderHeight)
          addMargin = pageHight + orderHeight
          if (oIndex === this.props.orders.length - 1) addMargin = 0
          // if (!isFirstOrderInPage) {
          //   console.log(orderHeight)
          //   addMargin = pageHight - orderHeight
          // } else {
          //   addMargin = initialMargin
          //   isFirstOrderInPage = false
          // }
          // totalOrderHeight += orderHeight
          // if (!isFirstOrderInPage && totalOrderHeight > pageHight) {
          //   addMargin = pageHight - (totalOrderHeight - orderHeight) + 140
          //   totalOrderHeight = orderHeight % pageHight
          //   isFirstOrderInPage = true
          // } else {
          //   addMargin = 0
          //   isFirstOrderInPage = false
          // }
          //   console.log(orderHeight, totalOrderHeight, pageHight, addMargin)
          let sortedRecords = getSortedOrder(order)
          sortedRecords = sortedRecords.map((item) => {
            const quantityInBox = parseInt(
              Number(item.quantity) / Number(item.box_quantity)
            ).toLocaleString()
            const quantityInUnit = parseInt(
              Number(item.quantity) % Number(item.box_quantity)
            ).toLocaleString()
            item.quantityInBox = quantityInBox
            item.quantityInUnit = quantityInUnit
            return item
          })

          return (
            <Paper
              key={oIndex}
              elevation={0}
              style={{
                width: "100%",
                position: "relative",
                marginBottom: `${addMargin + initialMargin}px`,
                // marginBottom: "55px",
                // marginTop: `${addMargin}px`,
              }}
            >
              {/* Order Header */}
              <OrderHeaderWrapper>
                <GridContainer>
                  <GridItem xs={2} alignItems="center">
                    <div></div>
                  </GridItem>
                  <GridItem xs={8} alignItems="center">
                    <Typography component="div" variant="h5">
                      {order.customer && order.customer.name}
                    </Typography>
                  </GridItem>
                  <GridItem xs={2} alignItems="left">
                    <Typography component="div" variant="h6">
                      {getDatePickerFormatteRTL()}
                    </Typography>
                  </GridItem>
                </GridContainer>
              </OrderHeaderWrapper>
              {/* Order Content */}
              <TableContainer>
                <Table style={{ minWidth: 650 }}>
                  {/* Table Header */}
                  <TableHead>
                    <TableRow>
                      {this.props.columns.map((column, cIndex) => (
                        <StyledTableCell
                          key={cIndex}
                          padding="none"
                          width={column.width}
                          align={
                            getCurrentLocaleCode() === "en" ? "left" : "right"
                          }
                        >
                          {trans(column.heading)}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  {/* Table Body */}
                  <TableBody>
                    {sortedRecords.map((record, rIndex) => {
                      let newPositionStyle = {
                        borderBottomWidth: 1,
                        borderBottomColor: "black",
                        borderBottomStyle: "solid",
                      }

                      if (rIndex + 1 <= sortedRecords.length - 1) {
                        if (
                          record.importance !==
                          sortedRecords[rIndex + 1].importance
                        )
                          newPositionStyle = {
                            borderBottomWidth: 5,
                            borderBottomColor: "gray",
                            borderBottomStyle: "solid",
                          }
                      }

                      record.columnList = Obj.clone(this.props.columns)

                      return (
                        <StyledTableRow key={rIndex} style={newPositionStyle}>
                          {record.columnList.map((column, cIndex) => {
                            column.value = Obj.get(
                              column,
                              "value",
                              Obj.get(record, column.key)
                            )
                            let columnValue = column.value
                            if (column.key === "quantityInBox") {
                              if (Number(record.quantityInBox)) {
                                const _qInBox = parseInt(record.box_quantity)
                                if (_qInBox === 1)
                                  column.value = record.quantityInBox
                                else
                                  column.value = `
                                  ${parseInt(record.box_quantity)} 
                                  / 
                                  ${record.quantityInBox}`

                                columnValue = column.value
                              } else {
                                column.value = emptyChar
                                columnValue = column.value
                              }
                            } else if (column.key === "quantityInUnit") {
                              if (!Number(record.quantityInUnit)) {
                                column.value = emptyChar
                                columnValue = column.value
                              }
                            } else if (column.key === "bonus") {
                              if (!Number(record.bonus)) {
                                column.value = emptyChar
                                columnValue = column.value
                              } else {
                                if (Number(record.box_quantity) === 1) {
                                  column.value = `
                                  ${parseInt(record.bonus)} ${trans("oneUnit")}`
                                } else {
                                  const countBox = parseInt(
                                    Number(record.bonus) /
                                      Number(record.box_quantity)
                                  ).toLocaleString()
                                  const countUnits = parseInt(
                                    Number(record.bonus) %
                                      Number(record.box_quantity)
                                  ).toLocaleString()
                                  let bonusResult
                                  if (Number(countBox) && Number(countUnits))
                                    bonusResult = `${Number(
                                      record.box_quantity
                                    )} / ${countBox} + ${countUnits} ${trans(
                                      "oneUnit"
                                    )}`
                                  else if (Number(countBox))
                                    bonusResult = `${Number(
                                      record.box_quantity
                                    )} / ${countBox}`
                                  else if (Number(countUnits))
                                    bonusResult = `${countUnits} ${trans(
                                      "oneUnit"
                                    )}`
                                  column.value = bonusResult
                                }

                                columnValue = column.value
                              }
                            } else {
                              if (column.formatter)
                                columnValue = (
                                  <column.formatter column={column} />
                                )
                            }

                            column.cell = (
                              <StyledTableCell
                                key={cIndex}
                                align="center"
                                padding="none"
                                style={{ direction: "rtl" }} // Mix Arabic char with English & Numbers
                              >
                                {columnValue}
                              </StyledTableCell>
                            )
                            return column.cell
                          })}
                        </StyledTableRow>
                      )
                    })}
                  </TableBody>
                  {/* {OrderContentBody} */}
                </Table>
              </TableContainer>
              {/* Order Footer */}
              <OrderFooterWrapper>
                <GridContainer>
                  <GridItem xs={3}>
                    {`${trans("salesperson_")}: 
                        ${order.employee.name}`}
                  </GridItem>
                  <GridItem xs={9} alignItems="right">
                    {order.note ? (
                      <Typography component="div" variant="h6">
                        {`${trans("notes")}: ${order.note || ""}`}
                      </Typography>
                    ) : (
                      <span></span>
                    )}
                  </GridItem>
                </GridContainer>
              </OrderFooterWrapper>
            </Paper>
          )
        })}
      </div>
    )
  }
}
export default PreviewOrder

/* <div className="container" style={{margin: "0", padding: "0"}}>
   <div style={{height: "90vh", padding: 5vh}}>
      page 1 content
   </div>
   <div style={{height: "90vh", padding: 5vh}}>
       page 2 content
    </div>
</div> 
width:794px; height:1122px
*/
