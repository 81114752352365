import { Obj } from "reinforcements"

// Store all configurations in this constant
let configurationsList = {}

const config = {
  /**
   * Set the given key with the given value in our configuration data
   *
   * @param {string|object} key
   * @param {any} value
   */
  set(key, value) {
    // case one one argument only is passed and is object
    if (arguments.length === 1) {
      let data = key
      configurationsList = Obj.merge(configurationsList, data)
    } else {
      configurationsList[key] = value
    }
  },

  /**
   * Get the value from the given key
   * If not exists, return the default value
   *
   * @param {string} key
   * @param {any} $default
   * @return {any}
   *
   */
  get(key, $default = null) {
    return Obj.get(configurationsList, key, $default)
  },
  /**
   * Get all configurations
   */
  list() {
    return configurationsList
  },
}
export default {
  config,
  provider: {
    name: "config",
    call: config,
  },
}
