// Core Files
import TabPage from "core/TabPage"
import Order from "./components/index"
import Gardian from "core/middlewares/gardian"
import OrdersToPrint from "./components/PrintPreviewOrders/OrdersToPrint"

export default function (route) {
  route("/orders", Order, [Gardian])
  route("/orders/:id", TabPage, [Gardian])
  route("/ordersPrintPreview", OrdersToPrint, [Gardian])
}
