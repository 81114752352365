// Dependencies
import React from "react"
import PropTypes from "prop-types"
// Shared Components
import useInputStyles from "materialui/Styles/inputStyle"
// Material Ui
import { IconButton, InputAdornment as MUIAdornment } from "@material-ui/core"

function InputAdornment(props) {
  const classes = useInputStyles()
  const { position, iconButton, icon, onClick, disabled } = props

  const adornment = (
    <MUIAdornment className={classes.startAdornmentIcon} postion={position}>
      {icon}
    </MUIAdornment>
  )
  const adornmentButton = (
    <MUIAdornment postion={position}>
      <IconButton onClick={onClick} disabled={disabled}>
        {icon}
      </IconButton>
    </MUIAdornment>
  )
  return iconButton ? adornmentButton : adornment
}

export default InputAdornment

InputAdornment.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  position: PropTypes.string,
  iconButton: PropTypes.bool,
  icon: PropTypes.node.isRequired,
}

InputAdornment.defaultPropt = {
  onClick: () => {},
  position: "start",
  iconButton: false,
}
