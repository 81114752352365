// Material Ui - Styles
import { grey } from "@material-ui/core/colors"
import {
  styled,
  TableRow,
  TableCell,
  makeStyles,
  withStyles,
} from "@material-ui/core"

export const OrderHeaderWrapper = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}))
export const OrderFooterWrapper = styled("div")(({ theme }) => ({
  fontWeight: "bold",
  marginTop: theme.spacing(3),
}))

export const StyledTableCell = withStyles((theme) => ({
  root: {
    "&:last-child": {
      paddingRight: theme.spacing(1.5),
    },
  },
  head: {
    fontWeight: "bold",
    border: "1px solid black",
    backgroundColor: grey[300],
    paddingRight: theme.spacing(1.5),
    // color: theme.palette.common.white,
  },
  body: {
    fontSize: 17,
    paddingTop: 4,
    paddingBottom: 4,
    border: "1px solid black",
  },
}))(TableCell)

export const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      // backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow)

export const usePreviewStyles = makeStyles((theme) => ({
  table: { minWidth: 650 },
  paper: {
    width: "100%",
    position: "relative",
    marginBottom: theme.spacing(2),
  },
}))
