import localization from "core/localization/index"

localization.extend("ar", {
  customer_: "زبون",
  balance: "ألحساب",
  customer__: "ألزبون",
  customers: "الزبائن",
  last_visit: "آخر زيارة",
  customer: "بيانات زبون",
  credit_limit: "ألحد ألأقصى",
  last_order_date: "آخر طلبية",
  last_payment_date: "آخر دفعة",
  customersList: "قائمة ألزبائن",
  customerAdded: "تم إضافة زبون جديد بنجاح",
  customerUpdated: "تم تعديل الزبون بنجاح",
  bisanCustomerSearch: "بحث عن زبون في بيسان",
  post_dated_checks_total: "ألحد ألأقصى للشكيات",
  bisan_customerWillBeSynced:
    "سوف يتم تحديث معلومات الزبون الحالي مع بيانات بيسان",
})
