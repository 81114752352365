// Dependencies
import clsx from "clsx"
import React from "react"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"
// Core Files
import { trans } from "core/localization"
import { ItemLink } from "materialui/Styles/styled"
import { routeOnly } from "core/Routes/Navigation/navigator"
// Material Ui & Styles
import {
  ListItemIcon,
  ListItemText,
  ListItem as MaterialListItem,
} from "@material-ui/core"
import useMasterLayoutStyles from "shared/styles/MasterLayoutStyles"

function SideBarListItem(props) {
  const classes = useMasterLayoutStyles()
  const { text, icon, route, nested } = props
  const currentRoute = routeOnly(useHistory())
  const active = currentRoute === route

  const className = clsx({
    [classes.sideBarNestedItem]: nested === true,
  })

  const activeText = clsx({
    [classes.sideBarActive]: active === true,
  })

  return (
    <MaterialListItem
      button
      to={route}
      component={ItemLink}
      className={className}
    >
      <ListItemIcon className={clsx(classes.sideBarIcon, activeText)}>
        {icon}
      </ListItemIcon>
      <ListItemText classes={{ root: activeText }} primary={trans(text)} />
    </MaterialListItem>
  )
}

export default SideBarListItem

SideBarListItem.propTypes = {
  nested: PropTypes.bool,
  route: PropTypes.string,
  icon: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
}

SideBarListItem.defaultProps = {
  route: "/",
  nested: false,
}
